import {TIcon} from '@/types/filter.types';
import SendIcon from '@mui/icons-material/Send';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

import DashboardIcon from '@mui/icons-material/Dashboard';
import TimelineIcon from '@mui/icons-material/Timeline';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';

import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import i18n from '@/localization';

export enum NAVIGATION {
  TOP = 'top',
  DASHBOARD_SIDEBAR = 'dashboard-sidebar',
}
export interface IRoute {
  icon?: TIcon;
  label: string;
  path: string;
  navigation?: NAVIGATION;
  subroutes?: IRoute[];
}

export enum ROUTE {
  dashboards = 'dashboards',
  playlists = 'playlists',
  trackers = 'trackers',
  components = 'components',
  login = 'login',
  preferences = 'preferences',
  shots = 'shots',
  players = 'player',
  teams = 'team',
  scouting = 'scouting',
  uploads = 'uploads',
}

export enum SCOUTING_ROUTES {
  search = 'search',
  player = 'player',
  shadow_trackers = 'shadow-trackers',
}

export enum PLAYLIST_ROUTES {
  opponent_automations = 'opponent-automations',
  opponent_automations_detail = 'opponent-automations/:id',
  single_sends = 'single-sends',
}

export enum SHOT_ANALYSIS_ROUTES {
  shots_created = 'shots-created',
  shots_conceded = 'shots-conceded',
}

export type TNavIcons = Partial<
  Record<ROUTE | PLAYLIST_ROUTES | SHOT_ANALYSIS_ROUTES, TIcon>
>;

export const NAV_ICONS: TNavIcons = {
  dashboards: DashboardIcon,
  trackers: TimelineIcon,
  playlists: SubscriptionsIcon,
  'opponent-automations': ScheduleSendIcon,
  'single-sends': SendIcon,
  shots: ModeStandbyIcon,
  'shots-created': ModeStandbyIcon,
  'shots-conceded': HighlightOffIcon,
  scouting: PersonSearchIcon,
};

export const ROUTES: IRoute[] = [
  {
    path: ROUTE.trackers,
    label: i18n.t('team.performance'),
    icon: NAV_ICONS.trackers,
    navigation: NAVIGATION.TOP,
  },
  {
    path: ROUTE.playlists,
    label: i18n.t(`navigation.${ROUTE.playlists}`),
    icon: NAV_ICONS.playlists,
    navigation: NAVIGATION.TOP,
    subroutes: [
      {
        path: PLAYLIST_ROUTES.opponent_automations,
        label: i18n.t(`navigation.${PLAYLIST_ROUTES.opponent_automations}`),
        icon: NAV_ICONS['opponent-automations'],
      },
      {
        path: PLAYLIST_ROUTES.single_sends,
        label: i18n.t(`navigation.${PLAYLIST_ROUTES.single_sends}`),
        icon: NAV_ICONS['single-sends'],
      },
    ],
  },
  {
    path: ROUTE.dashboards,
    label: i18n.t(`navigation.${ROUTE.dashboards}`),
    icon: NAV_ICONS.dashboards,
    navigation: NAVIGATION.TOP,
  },
  {
    path: ROUTE.shots,
    label: i18n.t(`navigation.${ROUTE.shots}`),
    icon: NAV_ICONS.shots,
    navigation: NAVIGATION.TOP,
    subroutes: [
      {
        path: SHOT_ANALYSIS_ROUTES.shots_created,
        label: i18n.t('navigation.shots_created'),
        icon: NAV_ICONS['shots-created'],
      },
      {
        path: SHOT_ANALYSIS_ROUTES.shots_conceded,
        label: i18n.t('navigation.shots_conceded'),
        icon: NAV_ICONS['shots-conceded'],
      },
    ],
  },
  {
    path: ROUTE.scouting,
    label: i18n.t('scouting.title'),
    icon: NAV_ICONS.scouting,
    navigation: NAVIGATION.TOP,
  },
];
