import React from 'react';
import {Route, Routes} from 'react-router-dom';
import TrackersView from '../views/automations/trackers.view';
import TrackerDetailView from '@/views/automations/tracker_detail.view';

export const TrackerRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<TrackersView />} />
      <Route path=":id" element={<TrackerDetailView />} />
    </Routes>
  );
};
