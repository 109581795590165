import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';

import {useTranslation} from 'react-i18next';

import {
  ACTION,
  IActionConfigDetails,
  FIConditionalEventFilter,
  FIEventFilters,
} from '@my-game-plan/types';
import {
  translateEventProperty,
  translateValue,
} from '@/helpers/translation.helper';

import styles from './add-filter-button.module.scss';
import {getGeneralMatchFilterOptions} from '@/helpers/automation/automation-form.helper';
import {useAutomationForm} from '@/context/event-automations/automations-form.context';

interface IAddFilterButtonProps {
  onChange: (field: keyof FIEventFilters) => void;
  action: ACTION;
  possibleDetails: IActionConfigDetails;
  selectedFilters?: FIConditionalEventFilter[];
  onPopupToggle: (hasOpenPopup: boolean) => void;
  isGameStateCondition?: boolean;
}

const MAX_POSSIBLE_VALUES_TO_SHOW = 3;

function AddFilterButton(props: IAddFilterButtonProps) {
  /* Hooks n State */
  const {t} = useTranslation();
  const [_anchorEl, _setAnchorEl] = useState<null | HTMLElement>(null);
  const [_possibleDetails, _setPossibleDetails] =
    useState<IActionConfigDetails>({});
  const _formContext = useAutomationForm();

  useEffect(() => {
    const _detailsToSet = props.possibleDetails;
    if (_formContext.currentAutomationType === 'opponent-automation') {
      delete _detailsToSet['pass.receiving_player._id'];
    }

    _setPossibleDetails(_detailsToSet);
  }, [_formContext.currentAutomationType, props.possibleDetails]);

  /* Handlers */
  function _onItemClick(field: keyof FIEventFilters) {
    props.onChange(field);
    _onClose();
  }

  function _onTriggerClick(event: React.MouseEvent<HTMLButtonElement>) {
    _setAnchorEl(event.currentTarget);
    props.onPopupToggle(true);
  }

  function _onClose(shouldCloseNestedPopup = true) {
    _setAnchorEl(null);
    if (shouldCloseNestedPopup) {
      props.onPopupToggle(false);
    }
  }

  /* Render */
  const _key = t('sentenceForm.details.add');
  const _isMenuOpen = Boolean(_anchorEl);
  return (
    <>
      <Button
        variant="outlined"
        onClick={_onTriggerClick}
        startIcon={<AddIcon />}>
        {_key}
      </Button>
      <Menu
        open={_isMenuOpen}
        anchorEl={_anchorEl}
        onClose={() => _onClose()}
        sx={{zIndex: 30000}}>
        {Object.keys(_possibleDetails).map((key) => {
          const _detailKey = key as keyof FIEventFilters;
          const _value = _possibleDetails[_detailKey];
          if (!_value) return null;

          let _isDisabled = false;
          let _possibleValues: string[] = [];

          if (props.isGameStateCondition) {
            _possibleValues = getGeneralMatchFilterOptions(
              _detailKey,
              _value,
              false,
            ) as string[];
          } else {
            _value.forEach((value) => {
              const _label = translateValue(value, _detailKey, true);
              _possibleValues.push(_label);
            });
          }

          if (props.selectedFilters) {
            const _matchingFieldInSelectedProps = props.selectedFilters.find(
              (selectedFilter) => selectedFilter.property === _detailKey,
            );
            if (_matchingFieldInSelectedProps) {
              _isDisabled = true;
            }
          }

          let _optionsText = '';

          const _slicedValues = [..._possibleValues].slice(
            0,
            MAX_POSSIBLE_VALUES_TO_SHOW,
          );

          _slicedValues.forEach((possibleValue: string, index: number) => {
            if (index < MAX_POSSIBLE_VALUES_TO_SHOW) {
              _optionsText += possibleValue;

              if (index < _slicedValues.length - 1) {
                _optionsText += ', ';
              }
            }
          });

          if (_possibleValues.length > _slicedValues.length) {
            _optionsText += ', ...';
          }

          return (
            <MenuItem
              disabled={_isDisabled}
              key={_detailKey}
              onClick={() => _onItemClick(_detailKey)}
              className={styles.menuItem}>
              {/* <span className={styles.label}>
                {translateProperty(_detailKey)}
              </span>
              <span className={styles.possibleValues}>{_optionsText}</span> */}
              <ListItemText
                primary={translateEventProperty(_detailKey)}
                secondary={
                  <Typography color="secondary" variant="body2">
                    {_optionsText}
                  </Typography>
                }
              />
            </MenuItem>
            //  <MenuItem
            //   disabled={_isDisabled}
            //   key={_detailKey}
            //   onClick={() => _onItemClick(_detailKey)}
            //   // className={styles.menuItem}
            //   />
          );
        })}
      </Menu>
    </>
  );
}

export default AddFilterButton;
