import React from 'react';

import {TDevComponent} from '@/types/dev.types';
import Chip from '@mui/material/Chip';
import {DevSection} from './dev-section';

export const DevSectionChipsView = () => {
  const chips: TDevComponent[] = [
    {
      title: 'Chip',
      variants: [
        {
          title: 'Regular',
          component: <Chip label="Small" size="small" />,
        },
        {
          title: 'Outlined',
          component: <Chip label="Small" size="small" variant="outlined" />,
        },
      ],
    },
  ];

  return (
    <DevSection title="Chip" componentList={chips} showItemTitle={false} />
  );
};
