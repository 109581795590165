import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';

import DateRangeIcon from '@mui/icons-material/DateRange';
import TuneIcon from '@mui/icons-material/Tune';
import Typography from '@mui/material/Typography';

import {useTranslation} from 'react-i18next';

import Checkbox from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';

import TrackerDetailFilterButton, {
  IFilterButtonValueSubSegment,
  LABEL_STYLE,
} from './tracker-detail-filter-button.view';
import BenchmarkFilterPopup from '../benchmark/benchmark-filter/benchmark-filter-popup.view';
import {useAutomationDetail} from '@/context/event-automations/automation-detail.context';
import {useCompetitions} from '@/context/competitions.context';
import {useTeams} from '@/context/team.context';
import {getSelectedPositionOptions} from '@/helpers/automation/automation-form.helper';

import MatchesFilter from './matches-filter/matches-filter.view';

interface IAutomationDetailFilterBarProps {
  displayObjectiveCheckbox?: boolean;
}

function AutomationDetailFilterBar(
  props: IAutomationDetailFilterBarProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const [_matchFilterAnchorEl, _setMatchFilterAnchorEl] =
    useState<HTMLElement | null>(null);

  const [_benchmarkPopupAnchorEl, _setBenchmarkPopupAnchorEl] =
    useState<HTMLElement | null>(null);
  const [_benchmarkFilterLabelValues, _setBenchmarkFilterLabelValues] =
    useState<IFilterButtonValueSubSegment[][]>([]);

  const {t} = useTranslation();

  const _automationDetailContext = useAutomationDetail();
  const _competitionsContext = useCompetitions();

  const _teamsContext = useTeams();

  /* Set benchmark values */
  useEffect(() => {
    const _labelValues: IFilterButtonValueSubSegment[][] = [];

    // League
    let _leagueValue = t('eventAutomations.benchmark.league');

    const _leagues = _competitionsContext.all.filter((competition) =>
      _automationDetailContext.benchmarkInfo?.filters?.competition_id?.includes(
        competition._id,
      ),
    );

    if (
      _leagues.length &&
      _leagues.length === 1 &&
      _leagues[0]._id !== _competitionsContext.domesticCompetition?._id
    ) {
      _leagueValue = _leagues[0].name;
    } else if (_leagues.length > 1) {
      _leagueValue = t('eventAutomations.benchmark.competitionsCount', {
        count: _leagues.length,
      });
    }

    // Average type
    const _averageValue = t(
      `eventAutomations.benchmark.${
        _automationDetailContext.benchmarkInfo?.filters?.calculation ===
        'per_90'
          ? 'average_90'
          : 'average'
      }`,
    );

    _labelValues.push([
      {type: 'value', value: _averageValue},
      {type: 'default', value: t('eventAutomations.benchmark.of')},
    ]);

    // Positions
    const _positions =
      _automationDetailContext.benchmarkInfo?.filters?.position;
    if (_positions?.length) {
      const _positionsValue = getSelectedPositionOptions(
        _positions,
        false,
      ).join(', ');
      _labelValues[0] = _labelValues[0].concat([
        {
          type: 'value',
          value: _positionsValue,
        },
        {
          type: 'default',
          value: t('eventAutomations.benchmark.in'),
        },
      ]);
    }

    // League
    _labelValues[0].push({
      type: 'value',
      value: _leagueValue,
    });

    // Min events

    _setBenchmarkFilterLabelValues(_labelValues);
  }, [
    _automationDetailContext.benchmarkInfo?.filters,
    _automationDetailContext.automation,
    _competitionsContext.all,
    _teamsContext.ownTeam,
  ]);

  /*
   * Handlers
   */
  function _onBenchmarkPopupOpen(event: React.MouseEvent<HTMLElement>) {
    _setBenchmarkPopupAnchorEl(event.currentTarget);
  }
  function _onMatchPopupOpen(event: React.MouseEvent<HTMLElement>) {
    _setMatchFilterAnchorEl(event.currentTarget);
  }

  function _onMatchFilerPopupClose() {
    _setMatchFilterAnchorEl(null);
  }

  function _onBenchmarkPopupClose() {
    _setBenchmarkPopupAnchorEl(null);
  }

  function _onObjectiveCheckboxChange() {
    _automationDetailContext.setIsOnlyShowingObjectiveEvents(
      !_automationDetailContext.isOnlyShowingObjectiveEvents,
    );
  }

  /*
   * Render
   */

  /* "Display data for [PERIOD]" filter */

  const _seasonFilterLabelPrefix = t(
    'eventAutomations.matchFilter.showDataFor',
  );

  const _disabledText = _automationDetailContext.benchmarkDisabledReason
    ? t(
        `eventAutomations.benchmark.disabled.${_automationDetailContext.benchmarkDisabledReason}`,
      )
    : undefined;

  const _selectedMatchesLabelSegments: IFilterButtonValueSubSegment[] = [
    {
      type: 'value',
      value: t('eventAutomations.matchFilter.matchesCount', {
        count: _automationDetailContext.observingMatchIds.length,
      }),
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      sx={{marginLeft: -1}}
      gap={6}
      flex={1}>
      <Box flex={1}>
        {/* SHOW DATA FOR [PERIOD] */}
        <TrackerDetailFilterButton
          buttonPrefix={_seasonFilterLabelPrefix}
          buttonValues={[_selectedMatchesLabelSegments]}
          ButtonIcon={DateRangeIcon}
          onPopupOpen={_onMatchPopupOpen}
          PopupContent={
            <MatchesFilter
              anchorElement={_matchFilterAnchorEl}
              onClose={_onMatchFilerPopupClose}
            />
          }
        />
      </Box>

      {/* Objective Checkbox */}
      {props.displayObjectiveCheckbox && (
        <FormControlLabel
          label={
            <Typography sx={LABEL_STYLE} color="secondary">
              {t('eventAutomations.displayObjectiveOnly')}
            </Typography>
          }
          control={
            <Checkbox
              size="small"
              checked={_automationDetailContext.isOnlyShowingObjectiveEvents}
              onChange={_onObjectiveCheckboxChange}
            />
          }
        />
      )}

      {/* BENCHMARK */}
      <TrackerDetailFilterButton
        buttonPrefix={t('eventAutomations.benchmark.benchmarkAgainst')}
        ButtonIcon={TuneIcon}
        buttonValues={_benchmarkFilterLabelValues}
        onPopupOpen={_onBenchmarkPopupOpen}
        disabledText={_disabledText}
        PopupContent={
          <BenchmarkFilterPopup
            anchorEl={_benchmarkPopupAnchorEl}
            onClose={_onBenchmarkPopupClose}
          />
        }
      />
    </Box>
  );
}

export default AutomationDetailFilterBar;
