import i18n from '@/localization';
import {
  IAutomationsGroup,
  IAutomationsGroupPlayer,
} from '@/types/event-automation-groups.types';
import {
  ENTIRE_TEAM_LABEL,
  FIEventAutomation,
  FIPlayer,
  FIPlayerWithPerformance,
  FITag,
  FITeam,
  POSITIONS_PER_LINE,
  TEventAutomationType,
} from '@my-game-plan/types';
import {capitalize} from 'lodash';
import {roundNumber} from '../general/general.helper';

import {
  IAutomationFilters,
  TFilterOptions,
} from '@/types/event-automations-misc.types';
import {translateAction} from '../translation.helper';
import {IFilterOption} from '@/types/filter.types';
import {TOverviewScreenViewType} from '@/types/screen.types';

/*
 * TRACKERS - Group per position group per player
 */
export function groupAutomationsByPlayerAndPosition(
  data: FIEventAutomation[],
  //   automationType: TEventAutomationType,
  filters: IAutomationFilters,
  team: FITeam,
  players: FIPlayer[],
): IAutomationsGroup[] {
  const _filteredAutomations = _filterAutomations(data, filters);
  const _groups: IAutomationsGroup[] = [];

  // Create a new group for each position line
  Object.keys(POSITIONS_PER_LINE).forEach((positionLine) => {
    _groups.push({
      key: positionLine,
      title: capitalize(i18n.t(`playerPosition.${positionLine}s`)),
      players: [],
    });
  });

  // Create "Entire team" group
  const _entireTeamGroup: IAutomationsGroup = {
    key: ENTIRE_TEAM_LABEL,
    players: [
      {
        _id: team._id,
        name: i18n.t('eventAutomations.entireTeam'),
        display_name: i18n.t('eventAutomations.entireTeam'),
        image_url: team.image_url,
        automations: [],
      },
    ],
  };

  // First, group automations by player
  const _playersWithAutomations: IAutomationsGroupPlayer[] = [];
  _filteredAutomations.forEach((automation) => {
    if (automation.observing_players?.players?.length) {
      automation.observing_players?.players.forEach((playerId) => {
        // Find player position
        const _matchingGroupPlayer = _playersWithAutomations.find(
          (groupPlayer) => groupPlayer._id === playerId,
        );
        const _matchingPlayer = players.find(
          (player) => player._id === playerId,
        );
        if (_matchingGroupPlayer) {
          // If player position is found, add automation to correct group
          _matchingGroupPlayer.automations.push(automation);
        } else if (_matchingPlayer) {
          const _newAutomationsGroupPlayer: IAutomationsGroupPlayer = {
            _id: _matchingPlayer._id,
            display_name: _matchingPlayer.display_name,
            name: _matchingPlayer.name,
            image_url: _matchingPlayer.image_url,
            position: _matchingPlayer.position,
            automations: [automation],
          };
          _playersWithAutomations.push(_newAutomationsGroupPlayer);
        } else {
          // TODO: PLAYER WAS NOT FOUND, ADD TO TRANSFERED PLAYERS
        }
      });
    } else {
      // Add automation to "Entire team" group
      _entireTeamGroup.players?.[0].automations.push(automation);
    }
  });

  // Add all players to the correct position group
  _playersWithAutomations.forEach((player) => {
    // Find position line
    const _matchedPositionLine = Object.entries(POSITIONS_PER_LINE).find(
      ([, lineConfig]) => {
        return (
          player.position && lineConfig.positions.includes(player.position[0])
        );
      },
    );
    if (_matchedPositionLine) {
      const _positionLineToFind = _matchedPositionLine[0];
      const _matchingGroup = _groups.find(
        (group) => group.key === _positionLineToFind,
      );
      if (_matchingGroup) {
        _matchingGroup.players?.push(player);
      }
    }
  });

  // Create "Transfered players" group
  //   const _transferedPlayersGroup: IAutomationsGroup = {
  //     key: 'transferedPlayers',
  //     players: [],
  //   };

  // Add "Entire team" group to list of groups
  if (
    _entireTeamGroup.players?.length &&
    _entireTeamGroup.players[0].automations?.length
  ) {
    _groups.unshift(_entireTeamGroup);
  }

  // Show trackers assigned to deleted players?

  // Calculate benchmarked score for each player of each group
  _groups.forEach((group) => {
    group.players?.forEach((player) => {
      const _benchmarkedAutomations = player.automations.filter(
        (automation) =>
          typeof automation.players?.[0].scores?.benchmarked_score !==
          'undefined',
      );
      const _benchmarkedScore = _benchmarkedAutomations.reduce(
        (sum, automation) =>
          sum + (automation.players?.[0].scores?.benchmarked_score || 0),
        0,
      );
      if (_benchmarkedAutomations.length) {
        player.overall_benchmarked_score = roundNumber(
          _benchmarkedScore / _benchmarkedAutomations.length,
          2,
        );
      }
    });

    group.players = group.players?.sort((a, b) => {
      if (
        typeof a.overall_benchmarked_score === 'undefined' &&
        typeof b.overall_benchmarked_score !== 'undefined'
      ) {
        return 1;
      } else if (
        typeof a.overall_benchmarked_score !== 'undefined' &&
        typeof b.overall_benchmarked_score === 'undefined'
      ) {
        return -1;
      }
      return (
        (b.overall_benchmarked_score || 0) - (a.overall_benchmarked_score || 0)
      );
    });
  });

  return _groups.filter((group) => group.players?.length);
}

/*
 * OPPONENT AUTOMATIONS - Group per tag
 */
export function groupAutomationsByTag(
  data: FIEventAutomation[],
  filters: IAutomationFilters,
  teamTags?: FITag[],
): IAutomationsGroup[] {
  const _filteredAutomations = _filterAutomations(data, filters);
  const _groups: IAutomationsGroup[] = [];

  if (!teamTags?.length) {
    // Return all automations in one group
    _groups.push({
      key: 'all',
      title: '',
      automations: _filteredAutomations,
    });

    return _groups;
  }

  // Create a new group for each tag
  teamTags.forEach((tag) => {
    const _group: IAutomationsGroup = {
      key: tag._id,
      title: capitalize(tag.label),
      automations: _filteredAutomations.filter((automation) =>
        automation.tags?.includes(tag._id),
      ),
      postData: {
        tags: [tag._id],
      },
    };
    _groups.push(_group);
  });

  // Create "No tags" group
  const _automationsWithoutTags = data.filter(
    (automation) => !automation.tags?.length,
  );
  if (_automationsWithoutTags.length) {
    _groups.push({
      key: 'noTags',
      title: i18n.t('eventAutomations.noTags'),
      automations: _automationsWithoutTags,
    });
  }

  return _groups.filter((group) => group.automations?.length);
}

/* Define available "actions", "tags" and "players" filters for all automations */

export function getAutomationFilterOptions<
  T = FIPlayerWithPerformance | FIEventAutomation,
>(
  viewType: TOverviewScreenViewType,
  data: T[],
  automationType: TEventAutomationType,
  players: FIPlayer[],
  tags: FITag[],
): TFilterOptions {
  const _actionsOptions: IFilterOption[] = [
    {
      label: i18n.t('eventAutomations.filter.allActions'),
      value: '',
    },
  ];
  const _tagsOptions: IFilterOption[] = [
    {
      label: i18n.t('eventAutomations.filter.allTags'),
      value: '',
    },
  ];
  const _playersOptions: IFilterOption[] = [
    {
      label: i18n.t('eventAutomations.filter.allPlayers'),
      value: '',
    },
  ];

  data.forEach((entry) => {
    if (viewType === 'table') {
      /* Automations */
      const _automation = entry as unknown as FIEventAutomation;

      /* Actions */
      const _actionInOptionsIndex = _actionsOptions.findIndex(
        (option) => option.value === _automation.action,
      );

      if (_actionInOptionsIndex === -1) {
        _actionsOptions.push({
          value: _automation.action,
          label: capitalize(translateAction(_automation.action, 0)),
          sortLabel: capitalize(translateAction(_automation.action, 0)),
          count: 1,
        });
      } else if (_actionsOptions?.[_actionInOptionsIndex].count) {
        _actionsOptions[_actionInOptionsIndex].count =
          (_actionsOptions[_actionInOptionsIndex].count || 1) + 1;
      }

      /* Tags */
      if (_automation.tags?.length) {
        _automation.tags.forEach((tagId) => {
          const _matchingTag = tags.find((tag) => tag._id === tagId);
          if (_matchingTag) {
            const _tagInOptionsIndex = _tagsOptions.findIndex(
              (option) => option.value === _matchingTag._id,
            );

            if (_tagInOptionsIndex === -1) {
              _tagsOptions.push({
                value: _matchingTag._id,
                label: capitalize(_matchingTag.label),
                sortLabel: capitalize(_matchingTag.label),
                count: 1,
              });
            } else if (_tagsOptions?.[_tagInOptionsIndex].count) {
              _tagsOptions[_tagInOptionsIndex].count =
                (_tagsOptions[_tagInOptionsIndex].count || 1) + 1;
            }
          }
        });
      }

      /* Players */
      if (
        automationType === 'tracker' &&
        _automation.observing_players?.players?.length
      ) {
        _automation.observing_players.players.forEach((playerId) => {
          const _matchingPlayer = players.find(
            (player) => player._id === playerId,
          );
          if (_matchingPlayer) {
            const _playerInOptionsIndex = _playersOptions.findIndex(
              (option) => option.value === _matchingPlayer._id,
            );

            if (_playerInOptionsIndex === -1) {
              _playersOptions.push({
                value: _matchingPlayer._id,
                label: _matchingPlayer.display_name,
                sortLabel: _matchingPlayer.last_name,
                image_url: _matchingPlayer.image_url,
                count: 1,
              });
            } else if (_playersOptions?.[_playerInOptionsIndex].count) {
              _playersOptions[_playerInOptionsIndex].count =
                (_playersOptions[_playerInOptionsIndex].count || 1) + 1;
            }
          }
        });
      } else if (automationType === 'tracker') {
        const _entireTeamOption = _playersOptions.find(
          (option) => option.value === ENTIRE_TEAM_LABEL,
        );
        if (!_entireTeamOption) {
          _playersOptions.push({
            value: ENTIRE_TEAM_LABEL,
            label: i18n.t('eventAutomations.filter.entireTeam'),
            sortLabel: i18n.t('eventAutomations.filter.entireTeam'),
          });
        }
      }
    } else {
      /* Players cards */
      const _player = entry as unknown as FIPlayerWithPerformance;
      _playersOptions.push({
        value:
          _player.display_name === ENTIRE_TEAM_LABEL
            ? ENTIRE_TEAM_LABEL
            : _player._id,
        label:
          _player.display_name === ENTIRE_TEAM_LABEL
            ? i18n.t('eventAutomations.filter.entireTeam')
            : _player.display_name,
        sortLabel:
          _player.display_name === ENTIRE_TEAM_LABEL
            ? i18n.t('eventAutomations.filter.entireTeam')
            : _player.last_name,
        image_url: _player.image_url,
        count: _player.automations_count,
      });

      _player.categories.forEach((category) => {
        const _isAlreadyAdded = _tagsOptions.some(
          (tag) => tag.value === category._id,
        );

        if (category.name && category.automations_count && !_isAlreadyAdded) {
          _tagsOptions.push({
            value: category._id,
            label: category.name,
            sortLabel: category.name,
            // count: category.automations_count,
          });
        }
      });
    }
  });

  const _options: TFilterOptions = {
    actions: _sortOptions(_actionsOptions),
    tags: _sortOptions(_tagsOptions),
    players: _sortOptions(_playersOptions),
  };

  return _options;
}

function _sortOptions(options: IFilterOption[]) {
  return options.sort((a, b) => {
    // Sort "All X" first
    if (!a.value && b.value) {
      return -1;
    } else if (a.value && !b.value) {
      return 1;
    }

    // Sort entire team options after
    if (a.value === ENTIRE_TEAM_LABEL && b.value !== ENTIRE_TEAM_LABEL) {
      return -1;
    } else if (a.value !== ENTIRE_TEAM_LABEL && b.value === ENTIRE_TEAM_LABEL) {
      return 1;
    }

    return a.sortLabel?.localeCompare(b.sortLabel || '') || -1;
  });
}

function _filterAutomations(
  automations: FIEventAutomation[],
  filters: IAutomationFilters,
): FIEventAutomation[] {
  const _filteredAutomations = automations.filter((automation) => {
    let _isMatching = true;

    // Action
    if (
      filters.actions?.length &&
      !filters.actions.includes(automation.action)
    ) {
      _isMatching = false;
    }

    // Tags
    if (
      filters.tags?.length &&
      (!automation.tags?.length ||
        !automation.tags.some((tag) => filters.tags?.includes(tag)))
    ) {
      _isMatching = false;
    }

    // Players

    if (
      filters.players?.length &&
      ((!automation.observing_players?.players?.length &&
        !filters.players.includes(ENTIRE_TEAM_LABEL)) ||
        (automation.observing_players?.players?.length &&
          !automation.observing_players?.players?.some((playerId) =>
            filters.players?.includes(playerId),
          )))
    ) {
      _isMatching = false;
    }

    return _isMatching;
  });

  return _filteredAutomations;
}
