import {
  FIEventAutomation,
  FIEventAutomationPostData,
  FIFormattedAutomation,
} from '@my-game-plan/types';

export function formattedAutomationToPostData(
  eventAutomation: FIEventAutomation | FIFormattedAutomation,
): Partial<FIEventAutomationPostData> {
  const {players, created_by, ..._otherFields} = eventAutomation;

  const _postData: Partial<FIEventAutomationPostData> = {..._otherFields};

  Object.keys(_postData).forEach((key) => {
    const _typedKey = key as keyof FIEventAutomationPostData;
    if (typeof _postData[_typedKey] === 'undefined') {
      delete _postData[_typedKey];
    }
  });

  return _postData;
}
