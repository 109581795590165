import {IFilterOption} from '@/types/filter.types';
import React from 'react';

import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import {
  AUTOMATIONS_FILTERS_CONFIG,
  IAutomationFilters,
  TFilterOptions,
} from '@/types/event-automations-misc.types';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {getLabelLength} from '@/components/dashboard/filter/helper/Filter.helper';

interface IAutomationsDrawerFilterProps {
  filterKey: keyof TFilterOptions;
  options: IFilterOption[];
  selectedOptions: string[];

  isOpened: boolean;
  onAccordionToggle: (filter: keyof TFilterOptions | null) => void;
  onFiltersChange: (filters: IAutomationFilters) => void;
}

function AutomationsDrawerFilter(
  props: IAutomationsDrawerFilterProps,
): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Handlers
   */
  function _onAccordionToggle() {
    const _newFilterValue = props.isOpened ? null : props.filterKey;
    props.onAccordionToggle(_newFilterValue);
  }

  function _onCheckboxToggle(key: keyof TFilterOptions, value: string) {
    let _options = [...props.selectedOptions];

    if (!value) {
      // "Select all" was selected, clear all
      _options = [];
    } else if (_options.includes(value)) {
      // If item is already selected, remove it
      _options = _options.filter((option) => option !== value);
    } else {
      // If item is not selected, add it
      _options.push(value);
    }
    props.onFiltersChange({
      [key]: _options,
    });
  }

  /*
   * Render
   */
  const _Icon = AUTOMATIONS_FILTERS_CONFIG[props.filterKey].icon;
  const _label = AUTOMATIONS_FILTERS_CONFIG[props.filterKey].label;

  const _selectedCountLabel = getLabelLength(
    props.selectedOptions.length,
    props.options.length - 1,
  );
  const _CollapseIcon = props.isOpened
    ? KeyboardArrowUpIcon
    : KeyboardArrowDownIcon;

  let _color: 'secondary' | 'primary' | undefined = 'secondary';
  if (props.isOpened) {
    _color = 'primary';
  } else if (props.selectedOptions.length) {
    _color = undefined;
  }
  return (
    <>
      <ListItemButton onClick={_onAccordionToggle}>
        <ListItemIcon>{<_Icon color={_color} />}</ListItemIcon>
        <ListItemText
          primary={_label}
          secondary={`(${_selectedCountLabel})`}
          primaryTypographyProps={{
            fontWeight: '500',
          }}
          secondaryTypographyProps={{
            fontSize: 12,
          }}
        />
        {<_CollapseIcon color="secondary" />}
      </ListItemButton>
      <Collapse in={props.isOpened} unmountOnExit>
        <List
          //   disablePadding
          dense
          sx={{
            // maxHeight: 320,
            // overflow: 'auto',
            bgcolor: 'background.default',
          }}>
          {props.options.map((option) => {
            let _isSelected = false;
            if (
              (!option.value && !props.selectedOptions.length) ||
              props.selectedOptions.includes(option.value)
            ) {
              _isSelected = true;
            }

            // const _initials = getPlayerInitials(option.label);
            return (
              <ListItem disablePadding key={option.value}>
                <ListItemButton
                  sx={{pl: 4}}
                  onClick={() =>
                    _onCheckboxToggle(props.filterKey, option.value)
                  }>
                  <ListItemIcon sx={{minWidth: 40}}>
                    <Checkbox edge="start" checked={_isSelected} />
                  </ListItemIcon>
                  {option.image_url && (
                    <ListItemAvatar sx={{minWidth: 32}}>
                      <Avatar
                        alt={option.label}
                        src={option.image_url}
                        sx={{height: 24, width: 24, bgcolor: 'secondary.main'}}
                      />
                    </ListItemAvatar>
                  )}
                  <ListItemText
                    primary={option.label}
                    secondary={option.count}
                    sx={{
                      display: 'flex',
                      gap: 2,
                      justifyContent: 'space-between',
                    }}
                    primaryTypographyProps={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </>
  );
}

export default AutomationsDrawerFilter;
