import React from 'react';

import {TDevComponent} from '@/types/dev.types';
import {Avatar} from '@mui/material';
import {DevSection} from './dev-section';

export const DevSectionAvatarsView = () => {
  const avatars: TDevComponent[] = [
    {
      title: 'Name: John Doe',
      variants: [
        {
          title: 'Regular',
          component: (
            <Avatar
              sx={{bgcolor: 'lightgray'}}
              alt="John Doe"
              src="https://cdn5.wyscout.com/photos/players/public/g-278419_100x130.png"
            />
          ),
        },
        {
          title: 'Fallback',
          component: (
            <Avatar
              sx={{bgcolor: 'lightgray'}}
              alt="John Doe"
              src="/broken-image.jpg"
            />
          ),
        },
      ],
    },
  ];

  return (
    <DevSection title="Avatar" componentList={avatars} showItemTitle={true} />
  );
};
