import {
  TRACKER_EVENT_EXAMPLES,
  TRACKER_TRACKING_EXAMPLES,
} from '@/config/trackers.config';
import {
  OPPONENT_AUTOMATION_EXAMPLES,
  OPPONENT_AUTOMATION_TRACKING_EXAMPLES,
} from '@/config/opponent-automation.config';

import {
  getPositionMap,
  getRandomPlayerFromPosition,
} from '@/helpers/player.helper';
import {cloneDeep} from 'lodash';
import {
  FIAutomationPlayerMatch,
  FIAutomationPlayer,
  FIConditionalEventFilter,
  FIConditionalEventFilters,
  FIEventAutomationSubject,
  FIFormattedAutomation,
  FIPlayer,
  FIEventFilters,
  TQueryOperatorType,
  getReferencedPlayersInSubject,
  getReferencedPlayersInDetails,
} from '@my-game-plan/types';

export function getLastMatches(
  array: FIAutomationPlayerMatch[],
  observingMatchIds: string[],
  matchesWithEventsOnly?: boolean,
  playedMatchesOnly?: boolean,
) {
  let _matches = [...array];

  if (playedMatchesOnly) {
    _matches = array.filter((match) => match.did_play);
  }

  if (matchesWithEventsOnly) {
    _matches = _matches.filter((match) => match.total_events);
  }

  return _matches.filter((match) =>
    observingMatchIds.includes(match.match._id),
  );
}

export function formatTrackerExamples(
  _ownPlayers: FIPlayer[],
  isTrackingDataSupported: boolean,
) {
  const _formattedExamples = cloneDeep(TRACKER_EVENT_EXAMPLES);

  if (isTrackingDataSupported) {
    // Add tracking examples to occurrences
    _formattedExamples.push(TRACKER_TRACKING_EXAMPLES);
  }

  // null: Team - 1: keepers - 2: defenders - 3: fullbacks - 4: midfielders - 5: wingers - 6: attackers
  _formattedExamples.forEach(function (section, sectionIndex) {
    section.examples.forEach(function (example, exampleIndex) {
      let randomPlayer: FIPlayer | null = null;
      const _observingPlayers = example.observing_players?.players;
      if (_observingPlayers && _observingPlayers.includes('1')) {
        const goalkeeperMap = getPositionMap('goalkeepers');
        randomPlayer = getRandomPlayerFromPosition(goalkeeperMap, _ownPlayers);
      } else if (_observingPlayers && _observingPlayers.includes('2')) {
        const defenderMap = getPositionMap('defenders');
        randomPlayer = getRandomPlayerFromPosition(defenderMap, _ownPlayers);
      } else if (_observingPlayers && _observingPlayers.includes('3')) {
        const fullbackMap = getPositionMap('fullbacks');
        randomPlayer = getRandomPlayerFromPosition(fullbackMap, _ownPlayers);
      } else if (_observingPlayers && _observingPlayers.includes('4')) {
        const midfieldersMap = getPositionMap('midfielders');
        randomPlayer = getRandomPlayerFromPosition(midfieldersMap, _ownPlayers);
      } else if (_observingPlayers && _observingPlayers.includes('5')) {
        const wingersMap = getPositionMap('wingers');
        randomPlayer = getRandomPlayerFromPosition(wingersMap, _ownPlayers);
      } else if (
        _observingPlayers &&
        (_observingPlayers.includes('6') || _observingPlayers.includes('7'))
      ) {
        const attackerMap = getPositionMap('attackers');
        randomPlayer = getRandomPlayerFromPosition(attackerMap, _ownPlayers);
      } else if (_observingPlayers && _observingPlayers.includes('8')) {
        const midfieldersMap = getPositionMap('midfielders');
        randomPlayer = getRandomPlayerFromPosition(midfieldersMap, _ownPlayers);
      }
      let _value: FIEventAutomationSubject | undefined = undefined;
      if (randomPlayer) {
        _value = {players: [randomPlayer._id]};
      }
      _formattedExamples[sectionIndex].examples[
        exampleIndex
      ].observing_players = _value;
    });
  });
  return _formattedExamples;
}

export function filterQueryParamsToConditionalParams(
  params: FIEventFilters,
  operator?: TQueryOperatorType,
): FIConditionalEventFilters {
  const _conditionalFilterParams: FIConditionalEventFilters = {
    operator: 'and',
    filters: [],
  };

  Object.keys(params).forEach((key) => {
    const _typedKey = key as keyof FIEventFilters;

    if (params[_typedKey]) {
      const _filter: FIConditionalEventFilter = {
        property: _typedKey,
        values: params[_typedKey] || [],
        operator: operator || 'or',
      };

      _conditionalFilterParams.filters.push(_filter);
    }
  });

  return _conditionalFilterParams;
}

export function formatOpponentAutomationsExamples(
  isTrackingDataSupported: boolean,
) {
  const _formattedExamples = cloneDeep(OPPONENT_AUTOMATION_EXAMPLES);

  if (isTrackingDataSupported) {
    _formattedExamples.push(OPPONENT_AUTOMATION_TRACKING_EXAMPLES);
  }

  return _formattedExamples;
}

/*
 * Check if data contains references to players that were transfered
 */

export function getTransferredPlayersForAutomations(
  data: FIFormattedAutomation,
  players: FIPlayer[],
): string[] {
  // Check details
  // for all details, check pass.receiving_player._id
  const _playersInDetails = getReferencedPlayersInDetails(data.details);

  // Check success_details
  // for all success details, check pass.receiving_player._id
  const _playersInSuccessDetails = getReferencedPlayersInDetails(
    data.success_details,
  );

  // Check observing players
  const _observingPlayers = getReferencedPlayersInSubject(
    data.observing_players,
  );

  // Check shared_with_players
  const _sharedWithPlayers = getReferencedPlayersInSubject(
    data.observing_players,
  );

  // For all conditions
  // check observing_players
  // for all details, check pass.receiving_player._id
  const _conditionsPlayers: string[] = [];
  if (data.conditions?.conditions.length) {
    data.conditions?.conditions.forEach((condition) => {
      const _conditionObservingPlayers = getReferencedPlayersInSubject(
        condition.observing_players,
      );

      _conditionsPlayers.push(..._conditionObservingPlayers);

      const _conditionDetails = getReferencedPlayersInDetails(
        condition.details,
      );
      _conditionsPlayers.push(..._conditionDetails);
    });
  }

  const _allReferencedPlayerIDs: string[] = [
    ..._playersInDetails,
    ..._playersInSuccessDetails,
    ..._observingPlayers,
    ..._sharedWithPlayers,
    ..._conditionsPlayers,
  ];

  // Check all referenced players and players that are not on team
  const _playerIDsWithoutMatchingPlayerIds = _allReferencedPlayerIDs.filter(
    (playerID) => !players.some((player) => player._id === playerID),
  );

  return _playerIDsWithoutMatchingPlayerIds;
}

/*
 * Get all players that are referenced in the automation and fill data
 */
export function addPlayersToAutomations(
  automations: FIFormattedAutomation[],
  players: FIPlayer[],
): FIFormattedAutomation[] {
  const _automationsWithPlayers: FIFormattedAutomation[] = [];

  automations.forEach((automation) => {
    if (automation.players?.length) {
      const _players: FIAutomationPlayer[] = automation.players?.map(
        (player) => {
          const _matchingPlayer = players.find((p) => p._id === player._id);

          const _player: FIAutomationPlayer = {
            ...player,
            display_name: _matchingPlayer?.display_name || '',
            name: _matchingPlayer?.name || '',
            image_url: _matchingPlayer?.image_url || '',
            // position: automation.benchmark_filters?.position?.[0],
            history: [],
          };

          return _player;
        },
      );
      _automationsWithPlayers.push({
        ...automation,
        players: _players,
        // created_by: undefined,
      });
    }
  });

  return _automationsWithPlayers;
}
