import {TDevComponent} from '@/types/dev.types';
import React, {useState} from 'react';
import {DevSection} from './dev-section';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';

interface FIPlayer {
  _id: number;
  name: string;
  display_name: string;
  position: string;
}

interface IData {
  _id: number;
  player: FIPlayer;
  total: number;
  on_target: number;
  success: number;
  success_rate: number;
}

interface IRow {
  id: number;
  player: string;
  total: number;
  on_target: number;
  goals: number;
}

const playerData: IData[] = [
  {
    _id: 204480,
    player: {
      _id: 204480,
      name: 'Declan Rice',
      display_name: 'D. Rice',
      position: 'RCM',
    },
    total: 7,
    on_target: 3,
    success: 2,
    success_rate: 0.2857142857142857,
  },
  {
    _id: 84939,
    player: {
      _id: 84939,
      name: 'Danny Ings',
      display_name: 'D. Ings',
      position: 'ST',
    },
    total: 8,
    on_target: 3,
    success: 2,
    success_rate: 0.25,
  },
  {
    _id: 57531,
    player: {
      _id: 57531,
      name: 'Michail Antonio',
      display_name: 'M. Antonio',
      position: 'ST',
    },
    total: 9,
    on_target: 4,
    success: 2,
    success_rate: 0.2222222222222222,
  },
  {
    _id: 172841,
    player: {
      _id: 172841,
      name: 'Saïd Benrahma',
      display_name: 'S. Benrahma',
      position: 'LF',
    },
    total: 27,
    on_target: 11,
    success: 2,
    success_rate: 0.07407407407407407,
  },
  {
    _id: 109533,
    player: {
      _id: 109533,
      name: 'Emerson',
      display_name: 'Emerson',
      position: 'LM',
    },
    total: 2,
    on_target: 1,
    success: 1,
    success_rate: 0.5,
  },
];

const rows: IRow[] = playerData.map((playerDataItem) => {
  return {
    id: playerDataItem._id,
    player: playerDataItem.player.display_name,
    total: playerDataItem.total,
    on_target: playerDataItem.on_target,
    goals: playerDataItem.success,
  };
});

export const DevSectionTablesView = () => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<number[]>([]);

  const handleRowClick = (id: number) => {
    const index = selectedRows.indexOf(id);
    if (index === -1) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows([
        ...selectedRows.slice(0, index),
        ...selectedRows.slice(index + 1),
      ]);
    }
  };

  const handleColumnClick = (id: number) => {
    const index = selectedColumns.indexOf(id);
    if (index === -1) {
      setSelectedColumns([...selectedColumns, id]);
    } else {
      setSelectedColumns([
        ...selectedColumns.slice(0, index),
        ...selectedColumns.slice(index + 1),
      ]);
    }
  };

  const isSelected = (id: number) => selectedRows.indexOf(id) !== -1;
  const isColumnSelected = (id: number) => selectedColumns.indexOf(id) !== -1;

  const tables: TDevComponent[] = [
    {
      title: 'Table',
      variants: [
        {
          title: 'Shots table',
          component: (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    onClick={() => handleColumnClick(0)}
                    style={{
                      backgroundColor: isColumnSelected(0)
                        ? '#ccc'
                        : 'transparent',
                    }}>
                    Player
                  </TableCell>
                  <TableCell
                    onClick={() => handleColumnClick(1)}
                    style={{
                      backgroundColor: isColumnSelected(1)
                        ? '#ccc'
                        : 'transparent',
                    }}>
                    Total
                  </TableCell>
                  <TableCell
                    onClick={() => handleColumnClick(2)}
                    style={{
                      backgroundColor: isColumnSelected(2)
                        ? '#ccc'
                        : 'transparent',
                    }}>
                    On Target
                  </TableCell>
                  <TableCell
                    onClick={() => handleColumnClick(3)}
                    style={{
                      backgroundColor: isColumnSelected(3)
                        ? '#ccc'
                        : 'transparent',
                    }}>
                    Goals
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.id}
                    onClick={() => handleRowClick(row.id)}
                    style={{
                      backgroundColor: isSelected(row.id)
                        ? '#ccc'
                        : 'transparent',
                    }}>
                    <TableCell>{row.player}</TableCell>
                    <TableCell>{row.total}</TableCell>
                    <TableCell>{row.on_target}</TableCell>
                    <TableCell>{row.goals}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ),
        },
      ],
    },
  ];

  return (
    <DevSection title="Tables" componentList={tables} showItemTitle={false} />
  );
};
