import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {PLAYLIST_ROUTES} from '@/config/routes.config';
import OpponentAutomationsView from '@/views/automations/opponent-automations.view';
import SingleSendsView from '@/views/single-sends.view';
import OpponentAutomationDetail from '@/views/automations/opponent-automation_detail.view';

export const PlaylistRoutes = () => {
  return (
    <Routes>
      <Route
        path=""
        element={<Navigate to={PLAYLIST_ROUTES.opponent_automations} replace />}
      />

      <Route
        path={PLAYLIST_ROUTES.opponent_automations}
        element={<OpponentAutomationsView />}
      />
      <Route
        path={PLAYLIST_ROUTES.opponent_automations_detail}
        element={<OpponentAutomationDetail />}
      />
      <Route
        path={PLAYLIST_ROUTES.single_sends}
        element={<SingleSendsView />}
      />
    </Routes>
  );
};
