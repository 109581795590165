import {ICommonSentenceInputProps} from '@/types/sentence-form.types';
import React, {useEffect, useState} from 'react';

import Autocomplete from '@/components/material-customised/autocomplete/autocomplete.view';
import {useCompetitions} from '@/context/competitions.context';
import {ITypedOption} from '@/types/option.types';
import {FIBenchmarkOptions} from '@my-game-plan/types';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useAuth} from '@/context/auth.context';
import {competitionsToTypedOptions} from '@/helpers/competitions.helper';

const DISPLAY_COMPETITIONS_COUNT = 1;
function CompetitionsDropdown(
  props: ICommonSentenceInputProps<number[], FIBenchmarkOptions>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _competitionsContext = useCompetitions();
  const _authContext = useAuth();
  const [_selectedOptions, _setSelectedOptions] = useState<
    ITypedOption<number>[]
  >([]);
  const [_valueText, _setValueText] = useState<string>('');
  const [_competitionDropdownOptions, _setCompetitionDropdownOptions] =
    useState<ITypedOption<number>[]>([]);

  useEffect(() => {
    const _matchingOptions = _competitionDropdownOptions.filter((option) => {
      return props.value?.includes(option.value);
    });

    _setSelectedOptions(_matchingOptions);

    const _sliced = _matchingOptions.slice(0, DISPLAY_COMPETITIONS_COUNT);
    let _newValueText = _sliced.map((option) => option.label).join(', ');
    if (_matchingOptions.length > DISPLAY_COMPETITIONS_COUNT) {
      _newValueText += ` + ${
        _matchingOptions.length - DISPLAY_COMPETITIONS_COUNT
      }`;
    }

    _setValueText(_newValueText);
  }, [props.value, _competitionDropdownOptions]);

  useEffect(() => {
    if (!_authContext.user) {
      return;
    }

    const _generatedOptions = competitionsToTypedOptions(
      _competitionsContext.all,
      _competitionsContext.userCountryCode,
    );
    _setCompetitionDropdownOptions(_generatedOptions);
  }, [_competitionsContext.userCountryCode, _competitionsContext.all]);

  /*
   * Handlers
   */
  function _onChange(
    value: ITypedOption<number> | ITypedOption<number>[] | null,
  ) {
    if (!props.onChange) {
      return;
    }

    if (value && Array.isArray(value)) {
      const _newPlayers = value.map((v) => v.value);

      props.onChange({
        [props.property]: _newPlayers,
      });
    } else if (value) {
      // props.onChange({
      //   [props.property]: value.value,
      // });
    }
  }

  /*
   * Render
   */
  function _groupHeaderKey(text: string): string {
    return capitalize(t(`competitions.${text}`));
  }
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      groupHeader={_groupHeaderKey}
      isInlineInput
      size={props.size}
      options={_competitionDropdownOptions}
      value={_selectedOptions}
      onChange={_onChange}
      tagsSentenceValue={_valueText}
    />
  );
}

export default CompetitionsDropdown;
