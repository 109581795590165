import React, {useEffect, useState} from 'react';

import Stack from '@mui/material/Stack';

import PlayerDetailHeader from '@/components/player-detail/header/player-detail-header.view';
import Screen from '@/components/screen/screen.view';
import {ROUTE} from '@/config/routes.config';
import {
  PlayerDetailProvider,
  usePlayerDetail,
} from '@/context/player-detail.context';
import {useTranslation} from 'react-i18next';

import PlayerDetailPerformance from '@/components/player-detail/player-detail-block/player-detail-performance.view';
import PlayerDetailPhysical from '@/components/player-detail/player-detail-block/player-detail-physical.view';

import {useLocation, useParams} from 'react-router-dom';
import TeamDetailHeader from '@/components/player-detail/header/team-detail-header.view';

import {useAuth} from '@/context/auth.context';
import {ILinkState} from '@/types/routes.types';
import PlayerDetailObjectives from '@/components/player-detail/player-detail-block/player-detail-objectives.view';
import PlayerSpeedDial from '@/components/player-detail/player-speed-dial.view';
import {useAnalytics} from '@/context/analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

function PlayerViewWithoutProvider() {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _location = useLocation();
  const _authContext = useAuth();
  const _playerDetailContext = usePlayerDetail();
  const _analyticsContext = useAnalytics();

  const [_backlinkState, _setBacklinkState] = useState<ILinkState | null>(null);

  /*
   * Side effects
   */
  // Set back link state
  useEffect(() => {
    let _newBacklinkState: ILinkState = {
      label: t('team.performance'),
      route: `/${ROUTE.trackers}`,
    };
    if (_location.state) {
      _newBacklinkState = _location.state as ILinkState;
    } else if (_location.pathname.includes(ROUTE.scouting)) {
      _newBacklinkState = {
        label: t('scouting.title'),
        route: `/${ROUTE.scouting}`,
      };
    }

    _setBacklinkState(_newBacklinkState);
  }, [_location.state, _location.pathname]);

  /*
   * Handlers
   */

  useEffect(() => {
    if (_authContext.user && _playerDetailContext.player) {
      _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_PLAYER_PAGE, {
        team_id: _authContext.user.team,
        player_id: _playerDetailContext.player._id,
      });
    } else if (
      _authContext.user &&
      _playerDetailContext.team &&
      _playerDetailContext.isTeamPage
    ) {
      _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_PLAYER_PAGE, {
        team_id: _authContext.user.team,
      });
    }
  }, [
    _authContext.user,
    _playerDetailContext.team,
    _playerDetailContext.player,
    _playerDetailContext.isTeamPage,
  ]);

  /*
   * Render
   */

  let _HeaderContent = <PlayerDetailHeader />;

  if (_playerDetailContext.isTeamPage) {
    _HeaderContent = <TeamDetailHeader />;
  }

  return (
    <Screen
      loadingState={_playerDetailContext.playerLoadingState}
      HeaderContent={_HeaderContent}
      coloredHeader
      backLink={_backlinkState || undefined}>
      <Stack gap={10}>
        <PlayerDetailPerformance />
        <PlayerDetailPhysical />
        {_playerDetailContext.player?.is_own_player &&
          !_playerDetailContext.isTeamPage && <PlayerDetailObjectives />}
      </Stack>
      {_playerDetailContext.player?.is_own_player && <PlayerSpeedDial />}
    </Screen>
  );
}

function PlayerView() {
  const _params = useParams();
  return (
    <PlayerDetailProvider playerId={_params.id}>
      <PlayerViewWithoutProvider />
    </PlayerDetailProvider>
  );
}
export default PlayerView;
