import {useAuth} from '@/context/auth.context';

import {ITypedOption} from '@/types/option.types';
import React, {useEffect, useState} from 'react';
import Segment from '../..';
import {ICommonSentenceInputProps} from '@/types/sentence-form.types';

import Box from '@mui/material/Box';
import {useTeams} from '@/context/team.context';

import {useMatches} from '@/context/matches.context';
import {getOpponent} from '@/helpers/general/general.helper';
import {uniqBy} from 'lodash';

// interface IObservingDropdownOpponentSelectorProps {

// }
function ObservingDropdownOpponentSelector<T>(
  props: Omit<ICommonSentenceInputProps<T>, 'property' | 'onChange'>,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const [_opponentsOptions, _setOpponentsOptions] = useState<
    ITypedOption<string>[]
  >([]);

  const _matchesContext = useMatches();
  const _teamsContext = useTeams();
  const _authContext = useAuth();

  /* Get upcoming opponents */
  useEffect(() => {
    if (_authContext.user) {
      // const _nextMatches = _matchesContext.ownMatches
      //   .filter(
      //     (match) =>
      //       match.match_status === 'fixture' ||
      //       match.match_status === 'postponed',
      //   )
      //   .sort(
      //     (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf(),
      //   );
      // // .slice(0, 3);
      // let _nextOpponents = _nextMatches.map((match) =>
      //   getOpponent(_authContext.user?.team, match),
      // );
      // _nextOpponents = uniqBy(_nextOpponents, '_id').slice(0, 3);

      const _foundSeasonIds =
        _authContext.user.competition_info.all_competition_seasons.map(
          (competionSeason) => competionSeason.season_id,
        );
      const _latestSeason = Math.max(..._foundSeasonIds);
      const _opponents = _matchesContext.ownMatches
        .filter((match) => match.season_id === _latestSeason)
        .map((match) => getOpponent(_authContext.user?.team, match))
        .filter((opp) => opp);
      const _filteredOpponents = uniqBy(_opponents, '_id').filter((opp) => opp);

      const _options: ITypedOption<string>[] = [];

      _filteredOpponents.forEach((team) => {
        if (!team) {
          return;
        }
        _options.push({
          value: team._id,
          label: team.name,
          name: team.name,
          group: 'all',
        });
      });

      _setOpponentsOptions(_options);
    }
  }, [_authContext.user, _teamsContext.all, _matchesContext.ownMatches]);

  /*
   * Handlers
   */
  function _onChange(teamId: string) {
    if (props.onObservingTeamIdChange) {
      props.onObservingTeamIdChange(teamId);
    }
    //
  }
  /*
   * Render
   */

  if (!_opponentsOptions?.length) return null;

  return (
    <Box ml={1}>
      <Segment.Dropdown
        {...props}
        readonly={false}
        options={_opponentsOptions}
        value={props.observingTeamId}
        onChange={_onChange}
        isBold
      />
    </Box>
  );
}

export default ObservingDropdownOpponentSelector;
