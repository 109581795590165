import React from 'react';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface IArrowProps {
  isExpanded: boolean;
}

export const Arrow = ({isExpanded}: IArrowProps): JSX.Element => {
  if (isExpanded) {
    return <KeyboardArrowUpIcon color="secondary" />;
  }
  return <KeyboardArrowDownIcon color="secondary" />;
};
