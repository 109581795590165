import {ANALYTICS_CONFIG} from '@/config/analytics/events.config';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {useAuth} from './auth.context';
import mixpanel from 'mixpanel-browser';
import {USER_ROLE} from '@my-game-plan/types';

export interface IAnalyticsAPI {
  trackEvent: <T extends keyof ANALYTICS_CONFIG>(
    event: T,
    params?: ANALYTICS_CONFIG[T],
  ) => void;
}

const context = createCustomContext<IAnalyticsAPI>();
export const useAnalytics = createContextHook(context);

const SOURCE = 'coach-app';

function AnalyticsProvider(props: {children: React.ReactNode}): JSX.Element {
  /*
   * Hooks n State
   */
  const _authContext = useAuth();

  /*
   * Handlers
   */
  function _trackEvent<T extends keyof ANALYTICS_CONFIG>(
    event: T,
    params?: ANALYTICS_CONFIG[T],
  ) {
    if (!_authContext.user || _authContext.user.role === USER_ROLE.ADMIN) {
      return;
    }

    // console.log('[MIXPANEL Track Event]', event, params);
    mixpanel.track(event, {...params, source: SOURCE});
  }

  /*
   * Return provider
   */
  return createProvider(context, props, {trackEvent: _trackEvent});
}

export default AnalyticsProvider;
