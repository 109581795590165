import React, {useEffect} from 'react';
import Loader from './components/common/Loader/Loader.view';

import * as Sentry from '@sentry/react';
import './MyGamePlan.scss';
import './styles/styles.scss';
import {initAnalytics} from './config/analytics';
import {AUTH_STATE} from './types/user/user.types';

import {useVideoPlaylist} from './context/video/video-playlist.context';
import {Navigation} from './routes/navigation';
import {useTeams} from '@/context/team.context';
import {useMatches} from '@/context/matches.context';
import {useAuth} from '@/context/auth.context';
import {useZones} from './context/zones.context';
import {useCompetitions} from './context/competitions.context';

const MyGamePlan = (): JSX.Element => {
  const _auth = useAuth();
  const _teamsContext = useTeams();
  const _matches = useMatches();
  const _zones = useZones();
  const _videoPlayList = useVideoPlaylist();
  const _competitions = useCompetitions();

  useEffect(() => {
    initAnalytics();
  }, []);

  // Players and matches are loaded everytime the selected team is changed
  useEffect(() => {
    const resourcesLoaded =
      _teamsContext.all.length > 0 &&
      _teamsContext.ownPlayers.length > 0 &&
      _competitions.all.length > 0 &&
      _auth.user != null;
    if (resourcesLoaded) {
      _auth.setAuthState(AUTH_STATE.LOGGED_IN);
    }
  }, [
    _teamsContext.all,
    _auth.user,
    _teamsContext.ownPlayers,
    _competitions.all,
  ]);

  useEffect(() => {
    if (_auth.user) {
      _teamsContext.getAll(_auth.user._id);
      _teamsContext.getOwnPlayers();
      _zones.getZones(_auth.user.team);
      _matches.getMatchesByTeam(_auth.user.team, true);
      _competitions.getAll();
    }
  }, [_auth.user]);

  useEffect(() => {
    if (_teamsContext.current) {
      _teamsContext.getFormations();
      _teamsContext.getPlayers();
      _matches.getMatchesByTeam(_teamsContext.current._id);
    }
  }, [_teamsContext.current]);

  const _isLoading = _auth.authState === AUTH_STATE.LOADING;

  return (
    <>
      {_videoPlayList.downloading}

      {!_isLoading && <Navigation />}

      <Loader isVisible={_isLoading} />
    </>
  );
};

export default Sentry.withProfiler(MyGamePlan);
