const SUBSCRIPTION_TRANSLATIONS = {
  videoLimit: {
    reached: {
      title: 'You have reached your video limit',
      text: 'The video minutes available to your team in your current pricing plan have all been used up. Consider upgrading your pricing plan to continue watching video.',
      resetInfo: 'New minutes will be available on {{date}}',
    },
  },
  pricing: {
    checkPlans: 'Check pricing plans',
  },
};

export default SUBSCRIPTION_TRANSLATIONS;
