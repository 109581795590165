import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import {LOADING_STATE} from '@/types/screen.types';
import {CenteredSpinner} from '../common/centered-spinner/CenteredSpinner';
import CustomEmptyState from '../error-states/CustomEmptyState';
import {useTranslation} from 'react-i18next';
import {ILinkState} from '@/types/routes.types';

interface IScreenProps {
  children: React.ReactNode;
  title?: string;
  HeaderContent?: React.ReactNode;
  HeaderRightContent?: React.ReactNode;
  HeaderBottomContent?: React.ReactNode;
  displayHeaderBorder?: boolean;
  backLink?: ILinkState;
  loadingState: LOADING_STATE;
  errorPrefix?: string;
  coloredHeader?: boolean;
}

function Screen(props: IScreenProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Handlers
   */

  /*
   * Render
   */

  let _Content = null;
  if (props.loadingState === LOADING_STATE.LOADING) {
    _Content = (
      <Stack p={10}>
        <CenteredSpinner />
      </Stack>
    );
  } else if (props.loadingState === LOADING_STATE.ERROR) {
    const _errorPrefix = props.errorPrefix || 'error-states.not-found';

    _Content = (
      <CustomEmptyState
        imageType="error"
        header={t(`${_errorPrefix}.header`)}
        description={t(`${_errorPrefix}.description`)}
      />
    );
  } else if (props.loadingState === LOADING_STATE.SUCCESS) {
    _Content = props.children;
  }

  let _Header = null;
  if (props.HeaderContent) {
    _Header = props.HeaderContent;
  } else if (props.title) {
    _Header = (
      <Typography fontSize={24} lineHeight={1.4} fontWeight={700}>
        {props.title}
      </Typography>
    );
  }
  return (
    <Stack>
      {/* Header */}
      <Stack
        py={3}
        px={3}
        alignItems="flex-start"
        bgcolor={props.coloredHeader ? 'background.paper' : undefined}
        sx={{
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
          borderBottomWidth: props.displayHeaderBorder ? 1 : 0,
        }}>
        {props.backLink && (
          <Button
            variant="text"
            color="secondary"
            component={Link}
            size="small"
            to={props.backLink.route}
            startIcon={<ArrowBackIcon />}
            sx={{mb: 0.5}}>
            {props.backLink.label}
          </Button>
        )}
        <Stack direction="row" width="100%" gap={3} alignItems="center">
          <Box flex={1}>{_Header}</Box>
          {props.HeaderRightContent && props.HeaderRightContent}
        </Stack>

        {props.HeaderBottomContent && (
          <Stack width="100%">{props.HeaderBottomContent}</Stack>
        )}
      </Stack>

      {/* Main Content */}
      <Stack px={3} paddingTop={4} paddingBottom={8} gap={4}>
        {_Content}
      </Stack>
    </Stack>
  );
}

export default Screen;
