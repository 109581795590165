import {
  Dimensions,
  IRect,
  XYDimensions,
} from '@/types/dashboard/dashboard-pitch-visual.types';
import {FCarry, FIMatchEvent, FPass, FRun, TBox} from '@my-game-plan/types';

function flattenPosition(value: number): number {
  let _flattenedValue = value;
  if (_flattenedValue < 1.5) {
    _flattenedValue = 1.5;
  } else if (_flattenedValue > 98.5) {
    _flattenedValue = 98.5;
  }

  return _flattenedValue;
}

function _pos(total: number, value: number): number {
  return Math.round(total * (value / 100));
}

export function calculatePosition(
  event: FIMatchEvent,
  dimensions: Dimensions,
  isHalf?: boolean,
  isOpponentAction?: boolean,
  shouldDisplayOnOwnHalf?: boolean,
): XYDimensions {
  const _startX = flattenPosition(
    isOpponentAction ? event.coordinates[0] : 100 - event.coordinates[0],
  );
  let _startY = flattenPosition(
    isOpponentAction ? 100 - event.coordinates[1] : event.coordinates[1],
  );

  let _endX = null;
  let _endY = null;

  const eventTypeFields = (event.pass || event.run || event.carry) as
    | FPass
    | FRun
    | FCarry;

  if (typeof eventTypeFields?.end_coordinates?.[0] !== 'undefined') {
    _endX = flattenPosition(
      (isOpponentAction
        ? eventTypeFields.end_coordinates[0]
        : 100 - eventTypeFields.end_coordinates[0]) || 0,
    );
  }

  if (typeof eventTypeFields?.end_coordinates?.[1] !== 'undefined') {
    _endY = flattenPosition(
      (isOpponentAction
        ? 100 - eventTypeFields.end_coordinates[1]
        : eventTypeFields.end_coordinates[1]) || 0,
    );
  }

  let _heightDimension = dimensions.height;
  if (isHalf) {
    _heightDimension = dimensions.height * 2;

    if (!isOpponentAction && !shouldDisplayOnOwnHalf) {
      if (_startY !== null) _startY -= 50;
      if (_endY !== null) _endY -= 50;
    }
  }

  const _position = {
    startX: _pos(dimensions.width, _startX),
    endX: _endX === null ? null : _pos(dimensions.width, _endX),
    startY: _pos(_heightDimension, _startY),
    endY: _endY === null ? null : _pos(_heightDimension, _endY),
  };

  return _position;
}

const SIDE_OFFSET = 40;
const OFFSET_LEFT = SIDE_OFFSET; //x1
const OFFSET_RIGHT = 100 - SIDE_OFFSET; //x2
const OFFSET_BOTTOM = 0; //x1
const OFFSET_TOP = 80; //x2
const START_LEFT = 0; //y1
const START_RIGHT = 100; //y2
export function calculateFinishingPosition(
  event: FIMatchEvent,
  dimensions: Dimensions,
  // isHalf?: boolean,
  // isOpponentAction?: boolean,
): XYDimensions {
  let _endY = null;
  let _endZ = null;
  if (typeof event.shot?.shot_coordinate_y !== 'undefined') {
    _endY =
      ((event.shot.shot_coordinate_y - OFFSET_LEFT) /
        (OFFSET_RIGHT - OFFSET_LEFT)) *
      (START_RIGHT - START_LEFT);
  }

  const _z = event.shot?.shot_coordinate_z || 1;
  if (typeof event.shot?.shot_coordinate_z !== 'undefined') {
    _endZ =
      ((_z - OFFSET_BOTTOM) / (OFFSET_TOP - OFFSET_BOTTOM)) *
      (START_RIGHT - START_LEFT);
  }

  const _position = {
    startX: 0,
    endX: _endY === null ? null : _pos(dimensions.width, 100 - _endY),
    startY: 0,
    endY: _endZ === null ? null : _pos(dimensions.height, 100 - _endZ),
  };

  return _position;
}

export function calculateZoneDimensions(
  box: TBox,
  dimensions: Dimensions,
  isObservingOpponent?: boolean,
): IRect {
  const [[_x1, _y1], [_x2, _y2]] = box;

  const _adjustedX1 = 100 - _x1;
  const _adjustedX2 = 100 - _x2;
  let _adjustedY1 = _y1;
  let _adjustedY2 = _y2;
  if (!isObservingOpponent) {
    _adjustedY1 = 100 - _y1;
    _adjustedY2 = 100 - _y2;
  }

  const _startX = _pos(dimensions.width, _adjustedX1);
  const _endX = _pos(dimensions.width, _adjustedX2);
  const _startY = _pos(dimensions.height, _adjustedY1);
  const _endY = _pos(dimensions.height, _adjustedY2);

  const _rect: IRect = {
    x: _startX,
    y: _startY,
    width: _endX - _startX,
    height: _endY - _startY,
  };

  // console.log(`---> [[${_startX}, ${_startY}], [${_endX}, ${_endY}]]`);

  return _rect;
}
