import {FILE_UPLOAD_CONFIG} from '@/config/api.config';
import {REQUEST_ERRORS} from '@/config/errors.config';
import {generateAWSKeyString} from '@/helpers/file-upload.helper';
import {useAPI} from '@/hooks/api.hooks';
import {HTTPResponse, Resource} from '@/types/api.types';
import {S3} from '@aws-sdk/client-s3';
import {Upload} from '@aws-sdk/lib-storage';
import {
  FIFileUploadPostData,
  FIUploadedFile,
  IFileUploadFilters,
} from '@my-game-plan/types';

const api = useAPI();

export async function createFileDocument(
  postData: Partial<FIFileUploadPostData>,
): Promise<FIUploadedFile> {
  try {
    // const _formData = uploadPostDataToFormData(postData);

    const {data} = await api.post<FIUploadedFile>({
      resource: Resource.file_uploads,
      data: postData,
      url: FILE_UPLOAD_CONFIG.uploadFile(),
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function getSingleFile(id: string): Promise<FIUploadedFile> {
  try {
    const {data} = await api.get<FIUploadedFile>({
      resource: Resource.file_uploads,
      url: FILE_UPLOAD_CONFIG.getSingleFile(id),
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function getAllFiles(
  teamId: string,
  params?: IFileUploadFilters,
): Promise<HTTPResponse<FIUploadedFile[]>> {
  const response = await api.get<FIUploadedFile[]>({
    resource: Resource.file_uploads,
    url: FILE_UPLOAD_CONFIG.getAllFiles(teamId),
    params: params,
  });

  return response;
}

export async function editFileDocument(
  id: string,
  postData: Partial<FIUploadedFile>,
): Promise<FIUploadedFile> {
  try {
    // const _formData = uploadPostDataToFormData(postData);
    const {data} = await api.put<FIUploadedFile>({
      resource: Resource.file_uploads,
      url: FILE_UPLOAD_CONFIG.editFile(id),
      data: postData,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function deleteFile(id: string): Promise<boolean> {
  try {
    const {data} = await api.del<boolean>({
      resource: Resource.file_uploads,
      url: FILE_UPLOAD_CONFIG.deleteFile(id),
    });

    if (typeof data === 'undefined') {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export function generateFileUploader(
  id: string,
  teamId: string,
  file: File,
): Upload {
  /* Init S3 client */
  const _s3Client = new S3({
    region: process.env.REACT_APP_AWS_REGION || '',
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || '',
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '',
    },
  });

  /* Generate key */
  const _key = generateAWSKeyString(teamId, id);

  const _uploader = new Upload({
    client: _s3Client,
    params: {
      Bucket: process.env.REACT_APP_S3_BUCKET || '',
      Key: _key,
      Body: file,
      ContentType: file.type,
    },
    partSize: 5 * 1024 * 1024,
  });

  return _uploader;
}
