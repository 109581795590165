import React from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Logo from '../../common/Logo/Logo';
import {usePlayerDetail} from '@/context/player-detail.context';

function TeamDetailHeader(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const _playerDetailContext = usePlayerDetail();

  /*
   * Render
   */
  if (!_playerDetailContext.team) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" width="100%" gap={2}>
      <Logo src={_playerDetailContext.team.image_url} />
      <Typography fontWeight={600} fontSize={24} lineHeight={1}>
        {_playerDetailContext.team.name}
      </Typography>
    </Stack>
  );
}

export default TeamDetailHeader;
