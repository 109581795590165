import React, {useEffect, useState} from 'react';

import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import Collapse from '@mui/material/Collapse';

import {useTranslation} from 'react-i18next';
import {MatchScore} from '@/components/common/match-score/MatchScore';
import {formatDate} from '@/helpers/general/general.helper';
import {useAutomationDetail} from '@/context/event-automations/automation-detail.context';
import {
  IAutomationSeasonInfo,
  ISelectedMatchesInfo,
} from '@/types/event-automations-misc.types';

import MatchesFilterCompetitionSelector from './matches-filter-competition-selector.view';

interface IMatchesFilterSeasonSubListProps {
  season: IAutomationSeasonInfo;
  isLast: boolean;
}

function MatchesFilterSeasonSubList(
  props: IMatchesFilterSeasonSubListProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _automationDetailContext = useAutomationDetail();

  const [_isExpanded, _setIsExpanded] = useState<boolean>(false);
  const [_selectedMatchesInfo, _setSelectedMatchesInfo] =
    useState<ISelectedMatchesInfo>({
      total: 0,
      selected: 0,
    });

  useEffect(() => {
    const _totalMatches = props.season.matches.length;
    const _selectedMatches = _automationDetailContext.observingMatchIds.filter(
      (matchId) => props.season.matches.some((match) => match._id === matchId),
    ).length;

    _setSelectedMatchesInfo({
      total: _totalMatches,
      selected: _selectedMatches,
    });
  }, [_automationDetailContext.observingMatchIds, props.season]);

  /*
   * Handlers
   */
  function _onSeasonClick() {
    // event.stopPropagation();
    _setIsExpanded(!_isExpanded);
  }

  function _onMatchSelect(matchId: string) {
    const _newMatchIds = [..._automationDetailContext.observingMatchIds];
    const _indexOfMatch = _newMatchIds.indexOf(matchId);

    if (_indexOfMatch > -1) {
      _newMatchIds.splice(_indexOfMatch, 1);
    } else {
      _newMatchIds.push(matchId);
    }

    _automationDetailContext.onObservingMatchIdChange(_newMatchIds);
  }

  function _onSeasonSelect(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    // Unselect all - When check is enabled, we'll re-add them to avoid duplicates
    const _newMatchIds = _automationDetailContext.observingMatchIds.filter(
      (matchId) => !props.season.matches.some((match) => match._id === matchId),
    );
    if (!_selectedMatchesInfo.selected) {
      // Select all
      // Add all matches from this season to observing matches
      _newMatchIds.push(...props.season.matches.map((match) => match._id));
    }
    _automationDetailContext.onObservingMatchIdChange(_newMatchIds);
  }

  /*
   * Render
   */

  const _ExpandIcon = _isExpanded ? ExpandLessIcon : ExpandMoreIcon;

  const _SecondaryText = t('eventAutomations.matchFilter.selectedCount', {
    count: _selectedMatchesInfo.total,
    selected: _selectedMatchesInfo.selected,
  });

  return (
    <>
      {/* Season toggle */}
      <ListItemButton onClick={_onSeasonClick} sx={{p: 1}}>
        <ListItemIcon>
          <Checkbox
            checked={
              _selectedMatchesInfo.total === _selectedMatchesInfo.selected
            }
            indeterminate={
              Boolean(_selectedMatchesInfo.selected) &&
              _selectedMatchesInfo.total !== _selectedMatchesInfo.selected
            }
            onClick={_onSeasonSelect}
          />
        </ListItemIcon>
        <ListItemText
          primary={props.season.seasonLabel}
          primaryTypographyProps={{fontWeight: 500}}
          secondary={_SecondaryText}
        />

        <_ExpandIcon color="secondary" />
      </ListItemButton>

      <Collapse in={_isExpanded} timeout="auto" unmountOnExit>
        <List dense>
          {/* Competitions selector */}
          <MatchesFilterCompetitionSelector
            competitions={props.season.competitions}
            seasonId={props.season.seasonId}
            matches={props.season.matches}
          />

          {/* List of matches */}
          {props.season.matches.map((match) => {
            const _isMatchChecked =
              _automationDetailContext.observingMatchIds.includes(match._id);
            return (
              <ListItemButton
                key={match._id}
                sx={{pl: 2}}
                onClick={() => _onMatchSelect(match._id)}>
                <ListItemIcon>
                  <Checkbox
                    color="primary"
                    checked={_isMatchChecked}
                    onChange={() => _onMatchSelect(match._id)}
                  />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  primary={<MatchScore match={match} />}
                  secondary={formatDate(match.date, 'DD/MM/YY')}></ListItemText>
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
      {!props.isLast && <Divider />}
    </>
  );
}

export default MatchesFilterSeasonSubList;
