import React, {useEffect, useState} from 'react';
import {ICommonSentenceProps} from '@/types/sentence-form.types';
import Segment from '../../segment';

import AutomationTypeDropdown from '../../type-dropdown/type-dropdown.view';
import Sentence from '../../sentence.view';
import {
  OPPONENT_AUTOMATION_TEAM_SELECTOR_OPTIONS,
  TRACKER_TEAM_SELECTOR_OPTIONS,
} from '@/config/event-automation-builder.config';
import {
  ACTIONS_CONFIG,
  FIEventCondition,
  PlayerPosition,
  TEventObservingTeam,
  isTrackingAction,
} from '@my-game-plan/types';
import ObservingDropdownOpponentSelector from '../../segment/dropdown/observing-dropdown/observing-dropdown-opponent-selector.view';
import {getVerbKey, startsWithVowel} from '@/helpers/translation.helper';
import {useTranslation} from 'react-i18next';

function AutomationBaseLine(props: ICommonSentenceProps): JSX.Element {
  /* Hooks n State */
  const {t} = useTranslation();
  const [_shouldDisplayRuleSeconds, _setShouldDisplayRuleSeconds] =
    useState<boolean>(true);

  useEffect(() => {
    let _newShouldDisplayRuleSeconds = true;
    if (
      props.data.calculation === 'rule' &&
      props.data.rule_condition?.action &&
      isTrackingAction(props.data.rule_condition.action)
    ) {
      const _actionConfig = ACTIONS_CONFIG[props.data.rule_condition.action];

      if (!_actionConfig?.tracking?.entersOnly) {
        _newShouldDisplayRuleSeconds = false;
      }
    }

    _setShouldDisplayRuleSeconds(_newShouldDisplayRuleSeconds);
  }, [props.data]);

  /* Handlers */
  function _onObservingTeamChange(value: TEventObservingTeam) {
    if (props.onChange) {
      props.onChange({
        observing_players: {
          team: value,
        },
      });
    }
  }

  function _onPositionsChange(value: PlayerPosition[]) {
    if (props.onChange) {
      props.onChange({
        observing_players: {
          ...props.data.observing_players,
          positions: value,
        },
      });
    }
  }

  function _onRuleConditionSecondsChange(value: Partial<FIEventCondition>) {
    if (props.onChange && props.data.rule_condition) {
      props.onChange({
        rule_condition: {
          ...props.data.rule_condition,
          ...value,
        },
      });
    }
  }

  /* Render */
  const _isRatio = props.data.calculation === 'ratio';
  const _isRule = props.data.calculation === 'rule';
  const _countToDisplay = _isRule ? 1 : 0;
  // if (!_isRatio)
  const _commonProps: ICommonSentenceProps = {
    ...props,
  };

  {
    /* Sentence Start */
  }
  const _CountSegment = (
    <Sentence.Segment>
      <Segment.Text
        {..._commonProps}
        translationKey={'sentenceForm.count'}
        capitalize
      />
    </Sentence.Segment>
  );

  /* Amount/Percent Of segmemt */
  const _shouldDisplayAmountOfText =
    props.data.metric || !props.readonly || _isRatio;
  let _AmountOfSegment = null;
  if (_isRatio) {
    _AmountOfSegment = (
      <Sentence.Segment>
        <Segment.Text
          {..._commonProps}
          capitalize={props.readonly}
          translationKey={'sentenceForm.percentOf'}
        />
      </Sentence.Segment>
    );
  } else {
    _AmountOfSegment = (
      <>
        <Sentence.Segment>
          <Segment.Metrics
            {..._commonProps}
            property="metric"
            onChange={props.onChange}
          />
        </Sentence.Segment>
        <Sentence.Segment>
          <Segment.Text {..._commonProps} translationKey={'sentenceForm.of'} />
        </Sentence.Segment>
      </>
    );
  }

  /* OBSERVING TEAM / PLAYERS */

  const _byDropdownOptions =
    props.automationType === 'opponent-automation'
      ? OPPONENT_AUTOMATION_TEAM_SELECTOR_OPTIONS
      : TRACKER_TEAM_SELECTOR_OPTIONS;

  const _ByAgainstDropdownSegment = (
    <Sentence.Segment>
      <Segment.Dropdown
        {..._commonProps}
        onChange={_onObservingTeamChange}
        // property="observing_players"
        options={_byDropdownOptions}
        displayReadonlyValueAsRegularText
        value={props.data.observing_players?.team || 'own'}
      />
    </Sentence.Segment>
  );

  const _PositionsDropdownSegment = (
    <Segment.Positions
      {..._commonProps}
      onChange={_onPositionsChange}
      value={props.data.observing_players?.positions || []}
      hideIfEmpty
    />
  );

  let _ObservingDropdownSegment = null;

  if (props.automationType === 'opponent-automation' && !props.hideSubject) {
    // Opponent automations
    // "EVT by POS of next opponent" - "EVT by pos against next opponent"

    let _OpponentSegment = (
      <Segment.Text
        {..._commonProps}
        translationKey={'sentenceForm.observing.nextOpponent'}
      />
    );
    if (props.canSelectOpponent) {
      _OpponentSegment = (
        <ObservingDropdownOpponentSelector {..._commonProps} />
      );
    }

    // Temporary hack to show "by" instead of default "of" for OA in shorthand
    let _OAByAgainstDropdownSegment = _ByAgainstDropdownSegment;
    if (
      props.readonly &&
      props.data.observing_players?.team !== 'opponent' &&
      !props.data.observing_players?.positions?.length
    ) {
      _OAByAgainstDropdownSegment = (
        <Sentence.Segment>
          <Segment.Text {..._commonProps} translationKey={'sentenceForm.by'} />
        </Sentence.Segment>
      );
    }
    _ObservingDropdownSegment = (
      <>
        {_PositionsDropdownSegment}
        {_OAByAgainstDropdownSegment}

        <Sentence.Segment>{_OpponentSegment}</Sentence.Segment>
      </>
    );
  } else if (props.data.observing_players?.team === 'opponent') {
    // Trackers - Opponent
    // "EVT by POS against team"
    _ObservingDropdownSegment = (
      <>
        {_PositionsDropdownSegment}
        {_ByAgainstDropdownSegment}
        <Sentence.Segment>
          <Segment.Text
            {..._commonProps}
            translationKey={'sentenceForm.observing.entireTeam'}
          />
        </Sentence.Segment>
      </>
    );
  } else if (props.data.calculation === 'rule') {
    _ObservingDropdownSegment = (
      <Segment.Positions
        {..._commonProps}
        onChange={_onPositionsChange}
        value={props.data.observing_players?.positions || []}
        hideBy
        placeholder={t('sentenceForm.observing.anyPlayer')}
      />
    );
  } else if (!props.hideSubject) {
    // Trackers - Own team
    // "EVT by team" - "EVT by player"
    _ObservingDropdownSegment = (
      <>
        {_ByAgainstDropdownSegment}
        <Sentence.Segment>
          <Sentence.Segment>
            <Segment.Players
              {..._commonProps}
              property="observing_players"
              value={props.data.observing_players}
            />
          </Sentence.Segment>
        </Sentence.Segment>
      </>
    );
  }

  {
    /* Action */
  }
  const _ActionInputSegment = (
    <Sentence.Segment>
      <Segment.Action
        {..._commonProps}
        property="action"
        required
        count={_countToDisplay}
        value={props.data.action}
        error={props.errors?.action}
      />
    </Sentence.Segment>
  );

  {
    /* Automation Type Dropdown */
  }
  let _AutomationTypeDropdown = null;
  if (
    !_isRule ||
    (_isRule &&
      props.data.rule_condition?.action &&
      !isTrackingAction(props.data.rule_condition.action))
  ) {
    _AutomationTypeDropdown = (
      <AutomationTypeDropdown {..._commonProps} action={props.data.action} />
    );
  }

  const _hasDetails = props.data.details?.filters.some(
    (filter) => filter.values?.length,
  );
  const _shouldShowDetailsSegment = !props.readonly || _hasDetails;
  const _DetailsSegment = (
    <Sentence.Segment>
      <Segment.Details
        {..._commonProps}
        property="details"
        placeholder="sentenceForm.all"
        value={props.data.details}
        action={props.data.action}
      />
    </Sentence.Segment>
  );

  /* RULE TRACKERS - Return different structure */
  if (props.data.calculation === 'rule') {
    /* Verb */
    let _articleKey = 'sentenceForm.an';
    if (!startsWithVowel(props.data.action)) {
      _articleKey = 'sentenceForm.a';
    }

    const _verbKey = getVerbKey(props.data.action, true);
    const _VerbSegment = (
      <>
        <Sentence.Segment>
          <Segment.Text
            translationKey={'sentenceForm.rule.must'}
            {..._commonProps}
          />
        </Sentence.Segment>
        <Sentence.Segment>
          <Segment.Text translationKey={_verbKey} {..._commonProps} />
        </Sentence.Segment>
        <Sentence.Segment>
          <Segment.Text translationKey={_articleKey} {..._commonProps} />
        </Sentence.Segment>
      </>
    );

    const _WithinTextSegment = (
      <Sentence.Segment>
        <Segment.Text
          {..._commonProps}
          translationKey={'sentenceForm.conditions.occurringWithin'}
        />
      </Sentence.Segment>
    );

    const _NumbersInputSegment = (
      <Sentence.Segment>
        <Segment.NumberInput<FIEventCondition>
          {..._commonProps}
          property="seconds"
          value={props.data.rule_condition?.seconds}
          onChange={_onRuleConditionSecondsChange}
        />
      </Sentence.Segment>
    );

    const _SecondsTextSegment = (
      <Sentence.Segment>
        <Segment.Text
          {..._commonProps}
          translationKey={'sentenceForm.conditions.seconds'}
          translationKeyOptions={{
            count: props.data.rule_condition?.seconds || 0,
          }}
        />
      </Sentence.Segment>
    );
    return (
      <>
        {_ObservingDropdownSegment}
        {_VerbSegment}
        {_ActionInputSegment}

        {_shouldDisplayRuleSeconds && (
          <>
            {_WithinTextSegment}
            {_NumbersInputSegment}
            {_SecondsTextSegment}
          </>
        )}

        {/* Tracking data / actions switch */}
        {_AutomationTypeDropdown}
      </>
    );
  }

  return (
    <>
      {/* "Count amount of" / "Count % of"  */}
      {_CountSegment}
      {_shouldDisplayAmountOfText && _AmountOfSegment}

      {/* Success Details */}
      {_isRatio &&
        props.data.action &&
        _shouldShowDetailsSegment &&
        _DetailsSegment}

      {/* Action */}
      {_ActionInputSegment}

      {/* By + target */}
      {_ObservingDropdownSegment}

      {/* Tracking data / actions switch */}
      {_AutomationTypeDropdown}
    </>
  );
}

export default AutomationBaseLine;
