import Screen from '@/components/screen/screen.view';

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import FileUploadForm from '@/components/file-upload/file-upload-form.view';
import FileUploadsProvider, {
  useFileUploads,
} from '@/context/file-uploads.context';
import FilesTable from '@/components/files-table/files-table.view';
import CustomEmptyState from '@/components/error-states/CustomEmptyState';
import FilesTableFilters from '@/components/files-table/files-table-filters.view';
import {useAnalytics} from '@/context/analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

function UploadsViewWithoutContext() {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_isFilePopupOpen, _setIsFilePopupOpen] = useState(false);
  const _filesContext = useFileUploads();
  const _analyticsContext = useAnalytics();

  /*
   * Side effects
   */
  // Track screen view
  useEffect(() => {
    _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_UPLOADS);
  }, []);

  /*
   * Handlers
   */
  function _onFilePopupClose() {
    _setIsFilePopupOpen(false);
  }

  function _onFilePopupOpen() {
    _setIsFilePopupOpen(true);
  }

  /*
   * Render
   */
  let _screenSubtitle = null;

  if (_filesContext.pagination?.total_results) {
    _screenSubtitle = (
      <Typography color="secondary.main" variant="body2">
        {t('uploads.filesCount', {
          count: _filesContext.pagination.total_results,
        })}
      </Typography>
    );
  }

  return (
    <Screen
      title={t('uploads.title')}
      coloredHeader
      HeaderBottomContent={_screenSubtitle}
      HeaderRightContent={<FilesTableFilters />}
      loadingState={_filesContext.loadingState}>
      {_filesContext.files.length ? (
        <FilesTable />
      ) : (
        <CustomEmptyState description={t('uploads.empty')} />
      )}
      <Fab
        color="primary"
        aria-label={t('uploads.uploadForm.title')}
        title={t('uploads.uploadForm.title')}
        onClick={_onFilePopupOpen}
        sx={{position: 'fixed', bottom: '1rem', right: '1rem'}}>
        <AddIcon />
      </Fab>
      <FileUploadForm isOpened={_isFilePopupOpen} onClose={_onFilePopupClose} />
    </Screen>
  );
}

function UploadsView() {
  return (
    <FileUploadsProvider>
      <UploadsViewWithoutContext />
    </FileUploadsProvider>
  );
}
export default UploadsView;
