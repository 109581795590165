import i18n from '../../localization';

import {ITypedOption} from '@/types/option.types';

import {
  ACTION,
  EVENT_TYPES,
  EventType,
  FIConditionalEventFilters,
  FIPlayer,
  GAME_STATES,
  generateActionConfigs,
  IActionConfigDetails,
  FIEventFilters,
  ITeamFormations,
  PlayerPosition,
  POSITION_IN_MATCHES,
  POSITIONS_PER_LINE,
  TDataProvider,
  TEventAutomationType,
  TPositionGroup,
} from '@my-game-plan/types';

import {
  translateAction,
  translateGeneralFilterValue,
} from '../translation.helper';

import {TRANSFERED_PLAYER} from '@/config/event-automation-builder.config';
import {capitalize} from 'lodash';

export function removeEmptyFilters(
  filters: FIConditionalEventFilters,
  returnUndefinedIfNecessary = false,
): FIConditionalEventFilters | undefined {
  const _filteredParams: FIConditionalEventFilters = {
    ...filters,
    filters: filters.filters.filter(
      (selectedFilter) =>
        selectedFilter.values && selectedFilter.values.length > 0,
    ),
  };

  const _hasFilters = _filteredParams.filters.length > 0;

  if (!_hasFilters && returnUndefinedIfNecessary) return undefined;

  return _filteredParams;
}

/*
 * Players Options
 */

export function getPlayerOptions(
  players: FIPlayer[],
  transferredPlayerOptions?: ITypedOption<string>[],
): ITypedOption<string>[] {
  const _groups = Object.keys(POSITIONS_PER_LINE);
  const _sortedPlayers = players.sort((a, b) =>
    a.last_name.localeCompare(b.last_name),
  );
  const _options: ITypedOption<string>[] = _sortedPlayers.map((player) => {
    let _group: TPositionGroup = 'defender';
    for (const [key, value] of Object.entries(POSITIONS_PER_LINE)) {
      if (value.positions.includes(player.position[0])) {
        _group = key as TPositionGroup;
      }
    }
    const _option: ITypedOption<string> = {
      label: player.display_name,
      value: player._id,
      name: player.name,
      group: _group,
    };

    return _option;
  });
  const _sortedOptions = _options.sort(
    (a, b) => _groups.indexOf(a.group || '') - _groups.indexOf(b.group || ''),
  );

  // If transferred players were passed, add options to uncheck
  if (transferredPlayerOptions) {
    _sortedOptions.unshift(...transferredPlayerOptions);
  }

  return _sortedOptions;
}

/*
 * Positions Options
 */
/* Generate options for autocomplete */
export function getPositionOptions(): ITypedOption<PlayerPosition>[] {
  const _formattedPositions: ITypedOption<PlayerPosition>[] = [];
  Object.keys(POSITIONS_PER_LINE).forEach((line) => {
    const _positionGroup = line as TPositionGroup;
    // /* Generate "Any X" or "All X" option */
    // const _allOption: ITypedOption<PlayerPosition> = {
    //   label: i18n.t(`playerPosition.${_positionGroup}sAny`),
    //   value: _positionGroup,
    //   group: _positionGroup,
    //   name: i18n.t(`playerPosition.${_positionGroup}sAny`),
    // };
    // if (POSITIONS_PER_LINE[_positionGroup].positions.length > 1) {
    //   _formattedPositions.push(_allOption);
    // }

    /* For each position of line, create an option too */
    POSITIONS_PER_LINE[_positionGroup].positions.forEach((position) => {
      const _option: ITypedOption<PlayerPosition> = {
        label: position,
        value: position,
        name: `${_positionGroup}-${position}`,
        group: _positionGroup,
      };

      _formattedPositions.push(_option);
    });
  });

  return _formattedPositions;
}

/* Transform value: convert PlayerPosition[] to group if necessary  */
export function getSelectedPositionOptions(
  selectedPositions: string[],
  returnAsOptions = true,
  groupItems = false,
): ITypedOption<string>[] | string[] {
  const _allOptions = getPositionOptions();

  const _selectedGroups: string[] = [];
  const _indexesToRemove: number[] = [];

  /* If all items of a group are selected, return the group name */
  Object.keys(POSITIONS_PER_LINE).forEach((group) => {
    const _group = group as TPositionGroup;
    const _groupPositions = POSITIONS_PER_LINE[_group].positions;
    const _allItemsAreSelected = _groupPositions.every((position) =>
      selectedPositions.includes(position),
    );

    if (_allItemsAreSelected && _groupPositions.length > 1) {
      /* Add group to selected items */
      _selectedGroups.push(group);

      /* Remove positions, because entire group should be selected */
      _groupPositions.forEach((position) => {
        const _indexInSelectedPositions = selectedPositions.findIndex(
          (selectedPosition) => selectedPosition === position,
        );
        if (_indexInSelectedPositions > -1) {
          _indexesToRemove.push(_indexInSelectedPositions);
        }
      });
    }
  });

  /* Remove positions that are in _indexesToRemove, replace them with group options */
  const _filteredPositionsWithoutGroup = selectedPositions.filter(
    (selectedPositions, index) => _indexesToRemove.indexOf(index) === -1,
  );

  if (returnAsOptions) {
    /* Return objects */
    const _groupOptions: ITypedOption<string>[] = _selectedGroups.map(
      (group) => {
        return {
          label: i18n.t(`playerPosition.${group}`),
          value: group,
          name: i18n.t(`playerPosition.${group}`),
          group: group,
        };
      },
    );

    const _positionsToReturn = groupItems
      ? [..._filteredPositionsWithoutGroup]
      : [...selectedPositions];

    const _optionsToReturn: ITypedOption<string>[] = groupItems
      ? [..._groupOptions]
      : [];
    _positionsToReturn.forEach((position) => {
      const _matchingOption = _allOptions.find(
        (option) => option.value === position,
      );
      if (_matchingOption) {
        _optionsToReturn.push(_matchingOption);
      }
    });

    return _optionsToReturn;
  }
  const _formattedPositions = [
    ..._selectedGroups.map((group) => i18n.t(`playerPosition.${group}s`)),
    ..._filteredPositionsWithoutGroup,
  ];

  /* Return strings for values */
  return _formattedPositions;
}

export function handlePositionOptionChange(
  data: ITypedOption<string>[],
): PlayerPosition[] {
  // console.log('------------------');
  // console.log('CHANGE:', data);

  const _selectedValues: string[] = data.map((option) => option.value);

  // console.log('ONCHANGE', _selectedValues);

  // return _selectedValues as PlayerPosition[];
  // const _lastSelectedItem = _selectedValues.at(-1);
  const _topLevelGroupNames = Object.keys(POSITIONS_PER_LINE);

  // const _selectedGroupIndexes: string[] = [];
  const _groupIndexesToRemove: number[] = [];

  // const _positionsWithoutHighLevelCheckboxes: PlayerPosition[] = [];

  /* Filter array of positions */
  _selectedValues.forEach((selectedValue, index) => {
    /* Check if "All XXX" has been checked */
    const _indexOfGroupOption = _topLevelGroupNames.findIndex(
      (groupValue) => groupValue === selectedValue,
    );

    /* If so, save the groupIndex to add all needed positions */
    /* and add position index to filter later on */
    if (_indexOfGroupOption > -1) {
      // _selectedGroupIndexes.push(selectedValue);
      _groupIndexesToRemove.push(index);
    }
  });

  // /* Filter out top level items: eg. "All defenders" */
  const _positionsWithoutHighLevelCheckboxes = _selectedValues.filter(
    (value, index) => !_groupIndexesToRemove.includes(index),
  );

  return _positionsWithoutHighLevelCheckboxes as PlayerPosition[];
}

export function getSelectedValuesInSentence(
  values: ITypedOption<string | number>[],
): string {
  let _allValues = '';
  values.forEach((position, index) => {
    _allValues += position.label;
    if (index === values.length - 2) {
      _allValues += ` ${i18n.t('sentenceForm.or')} `;
    } else if (index < values.length - 1) {
      _allValues += ', ';
    }
  });

  return _allValues;
}

/*
 * Generate options for topic dropdown
 */
export const actionConfigsToFormOptions = (
  dataProvider: TDataProvider,
  forTrackers = false,
  count = 2,
  capitalze = false,
): ITypedOption<ACTION>[] => {
  const _optionsToReturn: ITypedOption<ACTION>[] = [];
  const _groups = EVENT_TYPES.sort((a, b) => b.localeCompare(a));

  const _actionsConfig = generateActionConfigs(dataProvider);

  Object.keys(_actionsConfig).forEach((action) => {
    const _action = action as ACTION;
    const _actionConfig = _actionsConfig[_action];
    if (_actionConfig) {
      const _shouldIncludeAction =
        !forTrackers || !_actionConfig.isOpponentAction;
      if (_shouldIncludeAction) {
        let _translatedAction = translateAction(_action, count);
        if (capitalze) {
          _translatedAction = capitalize(_translatedAction);
        }
        _optionsToReturn.push({
          label: _translatedAction,
          value: _action,
          name: translateAction(_action, count),
          group: _actionConfig.parentAction,
        });
      }
    }
  });

  const _sortedOptions = _optionsToReturn.sort((a, b) => {
    const _nameSort = a.value.localeCompare(b.value);
    const _groupSort =
      _groups.indexOf((b.group as EventType) || '') -
      _groups.indexOf((a.group as EventType) || '');

    return _groupSort || _nameSort;
  });

  return _sortedOptions;
};

export function translateAutomationType(
  type: TEventAutomationType,
  count = 0,
  short?: boolean,
  lowerCase?: boolean,
): string {
  let _key =
    type === 'opponent-automation' ? 'opponentAutomations' : 'trackers';

  if (short) {
    _key += 'Short';
  }

  const _translation = i18n.t(`eventAutomations.${_key}`, {count: count});

  if (lowerCase) return _translation.toLowerCase();

  return _translation;
}

/*
 * Generate general match details
 */
export function generateGeneralMatchDetails(
  formations: ITeamFormations,
): IActionConfigDetails {
  return {
    'opponent_team.defence': formations.against_defence.map(
      (defence) => defence.label,
    ),
    'team.formation': formations.own.map((formation) => formation.label),
    position_in_match: POSITION_IN_MATCHES,
    time_block: ['0', '1', '2', '3', '4', '5', '6', '7', '8'],
    // 'game_state': GAME_STATE_VALUE_OPTIONS
    game_states: GAME_STATES,
  };
}

export function getGeneralMatchFilterOptions(
  key: keyof FIEventFilters,
  values: string[],
  shouldReturnOptions = true,
): ITypedOption<string>[] | string[] {
  const _options: ITypedOption<string>[] = values.map((value) => {
    const _label = translateGeneralFilterValue(key, value);

    return {
      label: _label,
      value: value,
    };
  });

  if (!shouldReturnOptions) return _options.map((option) => option.label);

  return _options;
}

/* Errors - Transferred players in automation */
export function generateTransferredPlayerOption(
  id: string,
): ITypedOption<string> {
  return {
    value: id,
    name: TRANSFERED_PLAYER,
    label: i18n.t('playerPosition.transferredPlayer'),
    group: 'transferredPlayer',
  };
}

/* Check if observing field is actually a share field */
export function isShareField(field?: string | number | symbol): boolean {
  return field === 'shared_with_players';
}
