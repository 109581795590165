const ERRORS_TRANSLATIONS = {
  'not-found': {
    header: 'Something went wrong fetching your data!',
    description: 'If this problem persists, send us a message.',
  },
  forbidden: {
    header: 'You are not allowed to view this content!',
    description: 'Please go back or log in with another account.',
    trackers: 'Back to performance trackers',
  },
  empty: {
    header: 'No data available',
  },
  video: {
    header: 'Something went wrong fetching this video!',
    description: 'If this problem persists, send us a message.',
  },
  'video-not-available': {
    short: 'No videos yet',
    header: 'Video is not yet available for this match.',
    description: 'Please try again later.',
  },
  defaultError: {
    header: 'Something went wrong.',
    description: 'Please try again later.',
  },
  'no-data': {
    header: 'No data available',
    description: 'Please try again later',
  },
  default: 'Something went wrong. Please try again later.',
  'auth-incorrect-credentials': 'Wrong credentials. Try again.',
  'not-analyst': 'You must be an analyst to login.',
  'user-not-active':
    'This account is not active. Please contact your team staff.',
  'team-not-active':
    'Looks like your team has not been activated yet. Please try again later.',
  'subscription-not-active': 'Looks like your team subscription expired.',
  'title-required': 'Title is required.',
  'minimum-players': 'Please select at least one player.',
  matches: {
    header: 'No matches found for this season',
    description: 'Please try again later.',
  },
  noNextOpponent: 'No next opponent found.',
};
export default ERRORS_TRANSLATIONS;
