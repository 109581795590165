import {
  getCompetitions,
  getCompetitionSeasons,
} from '@/controllers/competitions.controllers';
import {
  createCustomContext,
  createContextHook,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {
  FICompetition,
  FICompetitionSeason,
  TCompetitionType,
} from '@my-game-plan/types';
import {PropsWithChildren, useEffect, useState} from 'react';

import {useTeams} from './team.context';

import {useAuth} from './auth.context';

export interface ICompetitionsAPI {
  // List of all FICompetition documents
  all: FICompetition[];
  getAll: () => void;

  // Defines whether tracking data is supported for the team's user
  isTrackingDataSupported: boolean;

  // Domestic competition: last season with data
  domesticCompetition: FICompetition | null;
  getCompetitionForTeam: (
    teamId: string,
    competitionType?: TCompetitionType,
    seasonId?: number,
  ) => FICompetition | null;

  // All available competition seasons - mostly used to get competition info for scouted players
  allCompetitionSeasons: FICompetitionSeason[];
  userCountryCode: string;
}

const context = createCustomContext<ICompetitionsAPI>();
export const useCompetitions = createContextHook(context);

function CompetitionsProvider(
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element {
  /* Hooks n State */
  const [_competitions, _setCompetitions] = useState<FICompetition[]>([]);

  const [_isTrackingDataSupported, _setIsTrackingDataSupported] =
    useState<boolean>(false);

  const [_domesticCompetition, _setDomesticCompetition] =
    useState<FICompetition | null>(null);

  const [_allCompetitionSeasons, _setAllCompetitionSeasons] = useState<
    FICompetitionSeason[]
  >([]);

  const [_userCountryCode, _setUserCountryCode] = useState<string>('');

  const _authContext = useAuth();
  const _teamsContext = useTeams();

  useEffect(() => {
    if (!_teamsContext.ownTeam || !_authContext.user) {
      return;
    }

    const _latestSeasonId = Math.max(
      ..._authContext.user.competition_info.all_competition_seasons.map(
        (competitionSeason) => competitionSeason.season_id,
      ),
    );

    const _lastDomesticCompetitionSeason =
      _authContext.user.competition_info.all_competition_seasons.find(
        (competitionSeason) =>
          competitionSeason.competition_type === 'domestic_league' &&
          competitionSeason.season_id === _latestSeasonId,
      );

    if (_lastDomesticCompetitionSeason) {
      // Get domestic competition
      _teamsContext.setLeagueTeams(_lastDomesticCompetitionSeason.team_ids);

      const _latestDomesticCompetition = _competitions.find(
        (competition) =>
          competition._id === _lastDomesticCompetitionSeason?.competition_id,
      );

      _setDomesticCompetition(_latestDomesticCompetition ?? null);
      if (_latestDomesticCompetition) {
        _setUserCountryCode(_latestDomesticCompetition.country_code);
      }
      _setIsTrackingDataSupported(
        Boolean(_latestDomesticCompetition?.tracking_provider),
      );
    }
  }, [
    _teamsContext.ownTeam,
    _competitions,
    _authContext.user?.competition_info,
  ]);

  /* Handlers */
  async function _getAll() {
    const _fetchedCompetitions = await getCompetitions();
    _setCompetitions(_fetchedCompetitions);

    const _fetchedCompetitionSeasons = await getCompetitionSeasons();
    _setAllCompetitionSeasons(_fetchedCompetitionSeasons);
  }

  function _getCompetitionForTeam(
    teamId: string,
    competitionType?: TCompetitionType,
    seasonId?: number,
  ): FICompetition | null {
    const _competitionToCheck = competitionType || 'domestic_league';
    const _season =
      seasonId ||
      _authContext.user?.competition_info.latest_domestic_competition_with_data
        .season_id;
    const _matchingCompetitionSeason = _allCompetitionSeasons.find((season) => {
      const _teamIdCheck = season.team_ids.some((id) => id === teamId);

      return (
        _teamIdCheck &&
        season.competition_type === _competitionToCheck &&
        season.season_id === _season
      );
    });

    if (_matchingCompetitionSeason) {
      return (
        _competitions.find(
          (competition) =>
            competition._id === _matchingCompetitionSeason.competition_id,
        ) || null
      );
    }
    return null;
  }

  /* Return context provider */
  return createProvider(context, props, {
    all: _competitions,
    getAll: _getAll,
    isTrackingDataSupported: _isTrackingDataSupported,
    domesticCompetition: _domesticCompetition,
    getCompetitionForTeam: _getCompetitionForTeam,
    userCountryCode: _userCountryCode,
    allCompetitionSeasons: _allCompetitionSeasons,
  });
}

export default CompetitionsProvider;
