import React, {useEffect, useState} from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import LinearProgress from '@mui/material/LinearProgress';
import SyncIcon from '@mui/icons-material/Sync';
import {useTranslation} from 'react-i18next';
import {usePlayerDetail} from '@/context/player-detail.context';
import {useTeams} from '@/context/team.context';

import {ITypedOption} from '@/types/option.types';
import {getPlayerOptions} from '@/helpers/automation/automation-form.helper';
import CustomAutocomplete from '@/components/material-customised/autocomplete/autocomplete.view';

import {LOADING_STATE} from '@/types/screen.types';

const translationsPrefix = 'players.performance.mirrorring.builder';
function PlayerMirrorBuilder(): JSX.Element {
  /*
   * Hooks n State
   */
  const _playerDetailContext = usePlayerDetail();
  const _teamsContext = useTeams();
  const {t} = useTranslation();

  const [_playerOptions, _setPlayerOptions] = useState<ITypedOption<string>[]>(
    [],
  );
  const [_selectedPlayerOption, _setSelectedPlayerOption] =
    useState<ITypedOption<string> | null>(null);

  useEffect(() => {
    const _newPlayerOption = getPlayerOptions(_teamsContext.ownPlayers);
    _setPlayerOptions(_newPlayerOption);
  }, [_teamsContext.ownPlayers]);

  /*
   * Handlers
   */
  function _onPlayerSelect(
    data: ITypedOption<string> | ITypedOption<string>[] | null,
  ) {
    if (!Array.isArray(data)) {
      _setSelectedPlayerOption(data);
    }
  }

  function _onApplyClick() {
    if (!_selectedPlayerOption) {
      return;
    }

    _playerDetailContext.mirrorPlayer(_selectedPlayerOption.value as string);
  }

  /*
   * Render
   */
  let _Content = null;
  function _playersGroupHeader(text: string): string {
    return t(`playerPosition.${text}s`);
  }

  let _ProgressContent = null;
  if (_playerDetailContext.mirrorringProgress?.status === 'active') {
    _ProgressContent = (
      <Stack gap={1} width="100%" alignItems="center">
        <LinearProgress
          variant="determinate"
          value={_playerDetailContext.mirrorringProgress?.progress}
          sx={{width: '100%'}}
        />
        <Typography fontSize={12}>
          {`${Math.round(
            _playerDetailContext.mirrorringProgress?.progress || 0,
          )}%`}
        </Typography>
      </Stack>
    );
  } else if (_playerDetailContext.mirrorringProgress?.status !== 'completed') {
    _ProgressContent = (
      <Typography variant="body2" color="text.disabled">
        {t('players.performance.mirrorring.builder.waiting')}
      </Typography>
    );
  }
  if (_playerDetailContext.mirrorringLoadingState === LOADING_STATE.LOADING) {
    // Loader
    _Content = (
      <Stack spacing={6} alignItems="center" minWidth={240}>
        <Stack spacing={1} alignItems="center" textAlign="center">
          <Typography fontSize={18}>
            {t('players.performance.mirrorring.builder.syncing.header')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('players.performance.mirrorring.builder.syncing.description')}
          </Typography>
        </Stack>
        {_ProgressContent}
      </Stack>
    );
  } else {
    _Content = (
      <Grid container spacing={6}>
        {/* Select player */}
        <Grid item xs={12}>
          <Stack spacing={3} alignItems="center" textAlign="center">
            <Stack spacing={1} alignItems="center" whiteSpace="pre-line">
              <Typography fontWeight={500} fontSize={18}>
                {t(`${translationsPrefix}.selectPlayer.title`)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t(`${translationsPrefix}.selectPlayer.description`, {
                  name: _playerDetailContext.player?.name,
                })}
              </Typography>
            </Stack>
            {/* <Divider orientation="horizontal" /> */}
            <Box minWidth={320}>
              <CustomAutocomplete
                options={_playerOptions}
                value={_selectedPlayerOption}
                onChange={_onPlayerSelect}
                groupHeader={_playersGroupHeader}
                placeholder={t(
                  `${translationsPrefix}.selectPlayer.placeholder`,
                )}
              />
            </Box>
            <Button
              onClick={_onApplyClick}
              endIcon={<SyncIcon />}
              variant="contained"
              size="large"
              disabled={!_selectedPlayerOption}>
              {t(`${translationsPrefix}.applyTrackers`)}
            </Button>
          </Stack>
        </Grid>

        {/* Select trackers */}
        {/* <Grid item xs={12} sm={6}>
        <Typography>
          {t(`${translationsPrefix}.selectTrackers.title`)}
        </Typography>
        <Typography>
          {t(`${translationsPrefix}.selectTrackers.description`)}
        </Typography>
      </Grid> */}
      </Grid>
    );
  }
  return (
    <Stack
      spacing={3}
      minHeight={320}
      alignItems="center"
      justifyContent="center">
      {_Content}
    </Stack>
  );
}

export default PlayerMirrorBuilder;
