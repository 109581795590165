import React from 'react';

import {t} from 'i18next';
import {TDevComponent} from '@/types/dev.types';
import {DevSection} from './dev-section';
import CustomEmptyState from '../error-states/CustomEmptyState';

import {EmailButton} from '../buttons/EmailButton';
import AutomationCreateButton from '../automations/automation-create-button/automation-create-button.view';

export const DevSectionEmptyStateView = () => {
  const empty_states: TDevComponent[] = [
    {
      title: 'Empty State',
      variants: [
        {
          title: 'No trackers',
          component: (
            <CustomEmptyState
              header={t('trackers.empty.header')}
              description={t('trackers.empty.description')}
              primaryAction={<AutomationCreateButton type="tracker" />}
            />
          ),
        },
        {
          title: 'No result',
          component: (
            <CustomEmptyState
              header={t('trackers.empty.header')}
              description={t('trackers.empty.description')}
              primaryAction={<EmailButton />}
            />
          ),
        },
      ],
    },
  ];

  return (
    <DevSection
      title="Empty State"
      componentList={empty_states}
      showItemTitle={false}
    />
  );
};
