import styles from '../../components/dashboard/stylesheet/dashboard.module.scss';
import {Filter} from '@/components/dashboard/filter/Filter';
import {EditOvervieDashboardButton} from '@/components/dashboard/overview-dashboards/edit-overview-dashboard-button/EditOverviewDashboardButton';
import FilterTags from '../../components/dashboard/filter/tags/FilterTags';
import {OverviewDashboard} from '@/components/dashboard/overview-dashboards/OverviewDashboard';
import React from 'react';
import {useOverviewDashboards} from '@/context/dashboard/overview-dashboards.context';

export function CustomDashboardView(): JSX.Element {
  /* Hooks n State */
  const {isLoading, current} = useOverviewDashboards();

  /* Render */
  return (
    <div className={styles.overview}>
      <div className={styles.header}>
        <div className={styles.topRow}>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>{current ? current.title : ' '}</h2>
          </div>
          <div className={styles.topRowButtons}>
            {!isLoading && current && (
              <>
                <Filter />
                <EditOvervieDashboardButton />
              </>
            )}
          </div>
        </div>
        <div className={styles.bottomRow}>
          <FilterTags />
        </div>
      </div>

      <div className={styles.contentSingle}>
        <OverviewDashboard />
      </div>
    </div>
  );
}
