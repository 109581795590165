import {ActionCategory} from '@/types/action.types';
import ShieldIcon from '@mui/icons-material/Shield';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

const DASHBOARD_CATEGORIES: ActionCategory[] = [
  {
    icon: DoubleArrowIcon,
    label: 'attacking',
    subcategories: [
      {
        label: 'build-up',
        actions: ['goal_kick', 'start_of_play'],
      },
      {
        label: 'chance-creation',
        actions: [
          'final_third_pass',
          'cross',
          'run',
          'shot_assist',
          'shot_pre_assist',
          'shot',
        ],
      },
      {
        label: 'set-pieces',
        actions: [
          'corner_kick',
          'wide_free_kick',
          'direct_free_kick',
          'penalty',
        ],
      },
    ],
  },
  {
    icon: ShieldIcon,
    label: 'defending',
    subcategories: [
      {
        label: 'build-up',
        actions: ['interception', 'pressure_action'],
      },
      {
        label: 'chance-creation',
        actions: [
          'opponent_final_third_pass',
          'opponent_cross',
          'opponent_shot_assist',
          'opponent_shot',
        ],
      },
      {
        label: 'set-pieces',
        actions: [
          'opponent_corner_kick',
          'opponent_wide_free_kick',
          'opponent_direct_free_kick',
          'opponent_penalty',
        ],
      },
    ],
  },
];

export default DASHBOARD_CATEGORIES;
