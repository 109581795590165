import {AUTH_STATE} from '@/types/user/user.types';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {CreateOverviewDashboardModal} from '@/components/dashboard/overview-dashboards/overview-dashboard-modal/OverviewDashboardModal';

import {ROUTE} from '@/config/routes.config';
import LoginRoute from './login.routes';
import React from 'react';
import {DashboardRoutes} from './dashboard/dashboard.routes';
import styles from './navigation.module.scss';

import {PlaylistRoutes} from './playlist.routes';
import {TrackerRoutes} from './tracker.routes';
import {useAuth} from '@/context/auth.context';

import {ComponentsRoutes} from './components.routes';

import VideoPlayerModal from '@/components/video/events-video-player/video-player-modal.view';

import {AppBarView} from '@/components/app-bar/app-bar.view';
import Sidebar from '@/components/sidebar/Sidebar';
import {PreferencesView} from '@/views/preferences.view';
import ShotAnalysisRoutes from './shot-analysis.routes';
import PlayersRoutes from './players.routes';
import TeamView from '@/views/team.view';
import ScoutingRoutes from './scouting.routes';
import UploadsView from '@/views/uploads.view';

export const Navigation = () => {
  const _auth = useAuth();
  const _location = useLocation();
  const _isDashboardRoute = _location.pathname.includes(ROUTE.dashboards);

  if (_auth.authState !== AUTH_STATE.LOGGED_IN)
    return (
      <Routes>
        <Route path={ROUTE.login} element={<LoginRoute />} />
        <Route path="*" element={<Navigate to={ROUTE.login}></Navigate>} />
      </Routes>
    );

  return (
    <>
      {/*TODO these components should not be here*/}
      <CreateOverviewDashboardModal />
      <VideoPlayerModal />

      <AppBarView />

      <div className={styles.grid}>
        {_isDashboardRoute && <Sidebar />}

        <div className={styles.content_container}>
          <Routes>
            <Route
              path={`${ROUTE.dashboards}/*`}
              element={<DashboardRoutes />}
            />
            <Route path={`${ROUTE.playlists}/*`} element={<PlaylistRoutes />} />
            <Route path={`${ROUTE.trackers}/*`} element={<TrackerRoutes />} />

            <Route path={`${ROUTE.shots}/*`} element={<ShotAnalysisRoutes />} />
            <Route
              path={`${ROUTE.preferences}/*`}
              element={<PreferencesView />}
            />
            <Route path={`${ROUTE.players}/*`} element={<PlayersRoutes />} />
            <Route path={ROUTE.teams} element={<TeamView />} />
            <Route path={`${ROUTE.scouting}/*`} element={<ScoutingRoutes />} />
            <Route path={`${ROUTE.uploads}/*`} element={<UploadsView />} />
            <Route path="/components" element={<ComponentsRoutes />} />
            <Route
              path="*"
              element={<Navigate to={ROUTE.trackers} replace />}
            />
          </Routes>
        </div>
      </div>
    </>
  );
};
