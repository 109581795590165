const PHYSICAL_TRANSLATIONS = {
  distance: 'Total dist.',
  // distance_tip: 'Total dist. (in possession)',
  // distance_otip: 'Total dist. (out of possession)',
  distance_tip: 'In possession',
  distance_otip: 'Out of possession',

  running_distance: 'Running dist.',
  // running_distance_tip: 'Running dist. (in possession)',
  // running_distance_otip: 'Running dist. (out of possession)',
  running_distance_tip: 'In possession',
  running_distance_otip: 'Out of possession',

  hsr_distance: 'High speed dist.',
  // hsr_distance_tip: 'High speed dist. (in possession)',
  // hsr_distance_otip: 'High speed dist. (out of possession)',
  hsr_distance_tip: 'In possession',
  hsr_distance_otip: 'Out of possession',

  sprinting_distance: 'Sprinting dist.',
  // sprinting_distance_tip: 'Sprinting dist. (in possession)',
  // sprinting_distance_otip: 'Sprinting dist. (out of possession)',
  sprinting_distance_tip: 'In possession',
  sprinting_distance_otip: 'Out of possession',

  count_hsr: 'High speed runs',
  // count_hsr_tip: 'High speed runs (in possession)',
  // count_hsr_otip: 'High speed runs (out of possession)',
  count_hsr_tip: 'In possession',
  count_hsr_otip: 'Out of possession',

  count_sprint: 'Sprints',
  // count_sprint_tip: 'Sprints (in possession)',
  // count_sprint_otip: 'Sprints (out of possession)',
  count_sprint_tip: 'In possession',
  count_sprint_otip: 'Out of possession',

  'psv-99': 'Max speed',

  units: {
    default_90: "/90'",
    m_90: "m/90'",
    km_90: "km/90'",

    cm: 'cm',
    m: 'm',
    km: 'km',

    kg: 'kg',
    km_h: 'km/h',
  },
};

export default PHYSICAL_TRANSLATIONS;
