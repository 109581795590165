import moment from 'moment';
import {dateToTimestampFilename} from '@/helpers/general/general.helper';
import {AxiosResponse} from 'axios';
import {FIMatchEvent} from '@my-game-plan/types';

export function timestampToSecondsString(
  timestamp: number,
  period: number,
): string {
  const _duration = moment
    .duration()
    .add(period == 2 ? 45 : 0, 'minutes')
    .add(timestamp, 'seconds');

  let _durationMinutes = _duration.asMinutes();
  if (_durationMinutes < 0) {
    _durationMinutes = 0;
  }

  let _durationSeconds = _duration.seconds();
  if (_durationSeconds < 0) {
    _durationSeconds = 0;
  }
  const seconds =
    _duration.seconds() < 10
      ? `0${_durationSeconds.toString()}`
      : _durationSeconds.toString();
  return `${Math.floor(_durationMinutes).toFixed(0)}:${seconds}`;
  // const _minutes =
  //   _duration.minutes() < 10 ? `0${_duration.minutes()}` : _duration.minutes();
  // const _seconds =
  //   _duration.seconds() < 10 ? `0${_duration.seconds()}` : _duration.seconds();
  // return `${_minutes}:${_seconds}`;
}
export function formatVideoTime(
  event: FIMatchEvent,
  secondsInVideo: number,
  startOffset: number,
) {
  const duration = moment
    .duration()
    .add(event.period == 2 ? 45 : 0, 'minutes')
    .add(event.timestamp - startOffset + secondsInVideo, 'seconds');

  // console.log(
  //   '-------> DISPLAY:',
  //   event.timestamp,
  //   '-',
  //   startOffset,
  //   '+',
  //   secondsInVideo,
  // );

  let _durationMinutes = duration.asMinutes();
  if (_durationMinutes < 0) {
    _durationMinutes = 0;
  }

  let _durationSeconds = duration.seconds();
  if (_durationSeconds < 0) {
    _durationSeconds = 0;
  }
  const seconds =
    duration.seconds() < 10
      ? `0${_durationSeconds.toString()}`
      : _durationSeconds.toString();
  return `${Math.floor(_durationMinutes).toFixed(0)}:${seconds}`;
}

export function groupEventsOnMatchID(events: FIMatchEvent[]) {
  const uniqueMatches: {[matchId: string]: FIMatchEvent[]} = {};

  for (const event of events) {
    if (!uniqueMatches[event.match._id]) uniqueMatches[event.match._id] = [];
    uniqueMatches[event.match._id].push(event);
  }

  return uniqueMatches;
}

export function sortEvents(matches: {[matchId: number]: FIMatchEvent[]}) {
  return Object.entries(matches).sort(([, m1], [, m2]) => {
    const d1 = m1[0].match.date;
    const d2 = m2[0].match.date;

    if (d1 === d2) return 0;
    return d1 > d2 ? -1 : 1;
  });
}

export async function handleVideoDownload(
  videos: AxiosResponse<BlobPart>,
  zipName: string,
  resolve: (value: void | PromiseLike<void>) => void,
) {
  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const blob = new Blob([videos], {type: 'application/zip'});
  const url = window.URL.createObjectURL(blob);
  const timestamp = dateToTimestampFilename(new Date());

  // Make the zip downloadable
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${timestamp} - ${zipName}.zip`);
  document.body.appendChild(link);
  link.click();

  // Resolve the promise
  resolve();
}

export function calcEditHandlePosition(
  currPos: number,
  oldOffsets: number[],
  newOffsets: number[] | null,
): number {
  if (!newOffsets) return 0;
  return (
    (newOffsets[0] -
      oldOffsets[0] +
      currPos * (oldOffsets[1] + oldOffsets[0])) /
    (newOffsets[1] + newOffsets[0])
  );
}
