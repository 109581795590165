import React from 'react';
import {IDataExplorer} from '@/types/dashboard/dashboard-explorer.types';
import {IPitchVisual} from '@/types/dashboard/dashboard-pitch-visual.types';
import {IPlayerStats} from '@/types/dashboard/dashboard-player-stats.types';
import {
  IDashboardBlock,
  IDashboardBlockType,
} from '@/types/dashboard/dashboard.types';
import DataExplorer from '../data-explorer/DataExplorer';
import PitchVisual from '../pitch-visual/PitchVisual';
import PlayerStatistics from '../players/PlayerStatistics';

import classNames from 'classnames';
import styles from './block.module.scss';
import {Card} from '../../common/card/Card';
import {useDashboard} from '@/context/dashboard/dashboard.context';
import {FIEventFilters} from '@my-game-plan/types';
import {filterQueryParamsToConditionalParams} from '@/helpers/automation/automation.helper';

interface DashboardBlockProps {
  data: IDashboardBlock;
}

function DashboardBlock({data}: DashboardBlockProps): JSX.Element | null {
  let _content = null;
  const _dashboard = useDashboard();

  if (_dashboard.current) {
    switch (data.type) {
      case IDashboardBlockType.PLAYER_STATISTICS: {
        _content = (
          <PlayerStatistics data={data.data as IPlayerStats} isInteractive />
        );
        break;
      }

      case IDashboardBlockType.DATA_EXPLORER: {
        _content = <DataExplorer data={data.data as IDataExplorer} />;
        break;
      }

      case IDashboardBlockType.PITCH_VISUAL: {
        const {players, ..._eventFilters} =
          _dashboard.filters[
            (data.data as IPitchVisual).tabs[_dashboard.activePlayerStatsTab]
              .linkedTable
          ];
        const _typedFilters = _eventFilters as FIEventFilters;
        const _filterData = filterQueryParamsToConditionalParams(_typedFilters);

        _content = (
          <Card>
            <PitchVisual
              activeTab={_dashboard.activePlayerStatsTab}
              data={data.data as IPitchVisual}
              action={_dashboard.current.action}
              details={_filterData}
            />
          </Card>
        );
        break;
      }

      default: {
        _content = null;
        break;
      }
    }
  }

  const _classNames = classNames(styles.block, {
    [styles.blockFixed]: data.type === IDashboardBlockType.PITCH_VISUAL,
  });
  return <div className={_classNames}>{_content}</div>;
}

export default DashboardBlock;
