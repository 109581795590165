import React, {useEffect, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import TRACKERS_EXAMPLE_GIF from '@/assets/images/trackers/tracker_example.gif';
import OA_EXAMPLE_GIF from '@/assets/images/trackers/opponent_automation_example.gif';
import InlineFiltersSentence from '@/components/automations/sentence/builder-sentence.view';
import {Divider} from '@/components/common/divider/Divider';

import {useTeams} from '@/context/team.context';

import {
  formatOpponentAutomationsExamples,
  formatTrackerExamples,
} from '@/helpers/automation/automation.helper';
import styles from './automation-examples-content.module.scss';
import {useTranslation} from 'react-i18next';

import {
  FIEventAutomationPostData,
  TEventAutomationType,
} from '@my-game-plan/types';
import {IEventAutomationExampleInfo} from '@/types/event-automations-misc.types';
import {
  getSelectedPositionOptions,
  translateAutomationType,
} from '@/helpers/automation/automation-form.helper';
import {useCompetitions} from '@/context/competitions.context';

interface IExampleOverviewProps {
  type: TEventAutomationType;
  onSubmit: (data?: Partial<FIEventAutomationPostData>) => void;
}

function ExamplesOverview(props: IExampleOverviewProps) {
  /*
   * Hooks n State
   */

  const [examples, setExamples] = useState<IEventAutomationExampleInfo[]>([]);
  const _teamsContext = useTeams();
  const _competitionsContext = useCompetitions();
  const {t} = useTranslation();
  const _translatedType = translateAutomationType(props.type, 1, true, true);

  useEffect(() => {
    if (_teamsContext.ownPlayers && _competitionsContext.domesticCompetition) {
      const _formattedExamples =
        props.type === 'tracker'
          ? formatTrackerExamples(
              _teamsContext.ownPlayers,
              _competitionsContext.isTrackingDataSupported,
            )
          : formatOpponentAutomationsExamples(
              _competitionsContext.isTrackingDataSupported,
            );
      setExamples(_formattedExamples);
      return () => {
        setExamples([]);
      };
    }
  }, [_teamsContext.ownPlayers, _competitionsContext.isTrackingDataSupported]);

  /*
   * Handlers
   */

  function _onExampleClick(example: Partial<FIEventAutomationPostData>) {
    const _exampleWithoutId = {...example};
    delete _exampleWithoutId._id;
    props.onSubmit(_exampleWithoutId);
  }

  function _onNewAutomationClick() {
    props.onSubmit();
  }

  /*
   * Render
   */
  const _addLabel = t('eventAutomations.create.titleCustom', {
    type: _translatedType,
  });
  const _imageURL =
    props.type === 'tracker' ? TRACKERS_EXAMPLE_GIF : OA_EXAMPLE_GIF;
  return (
    <>
      <div className={styles.explanationText}>
        <p>{t('eventAutomations.examples.explanationText')}</p>
      </div>
      <div className={styles.header}>
        <div className={styles.imgContainer}>
          <div
            className={styles.img}
            style={{backgroundImage: `url('${_imageURL}')`}}></div>
        </div>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={_onNewAutomationClick}>
          {_addLabel}
        </Button>
      </div>

      <div className={styles.content}>
        {examples.map((section) => {
          return (
            <div key={section.section} className={styles.items}>
              <h2>{section.section}</h2>
              <Divider />
              <div className={styles.section}>
                {section.examples.map((example) => {
                  return (
                    <div className={styles.example} key={example._id}>
                      <InlineFiltersSentence
                        automationType={props.type}
                        data={example}
                        readonly
                        size="small"
                        withTarget={typeof example.target !== 'undefined'}
                      />
                      <div className={styles.bottomRow}>
                        {props.type === 'tracker' ? (
                          <p>
                            {example.calculation === 'occurrences'
                              ? t(
                                  'eventAutomations.calculationType.occurrences',
                                )
                              : t('eventAutomations.calculationType.ratio')}
                          </p>
                        ) : props.type === 'opponent-automation' ? (
                          <>
                            {example.shared_with_players ? (
                              <>
                                {example.shared_with_players.positions && (
                                  <p>
                                    {t('sentenceForm.share.shareWith') + ' '}
                                    {getSelectedPositionOptions(
                                      example.shared_with_players.positions,
                                      false,
                                      true,
                                    ).join(', ')}
                                  </p>
                                )}
                                {example.shared_with_players.team && (
                                  <p>
                                    {t(
                                      'sentenceForm.share.shareWithAllPlayers',
                                    )}
                                  </p>
                                )}
                              </>
                            ) : (
                              <p>
                                {t('sentenceForm.share.shareWith') +
                                  ' ' +
                                  t('sentenceForm.share.placeholder')}
                              </p>
                            )}
                          </>
                        ) : null}
                        <div>
                          <Button
                            variant="outlined"
                            onClick={() => _onExampleClick(example)}>
                            {t('eventAutomations.examples.useTemplate')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
export default ExamplesOverview;
