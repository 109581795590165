import React, {useEffect, useState} from 'react';
import cn from 'classnames';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LabelIcon from '@mui/icons-material/Label';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import styles from '@/components/dashboard/stylesheet/overview.module.scss';

import {
  FIEventAutomationPostData,
  FITag,
  FIDisplayPlayer,
} from '@my-game-plan/types';

import {useTeams} from '@/context/team.context';
import {deepCopy} from '@/helpers/general/general.helper';
import {hashtagify} from '@/helpers/translation.helper';
import {useAuth} from '@/context/auth.context';
import {formattedAutomationToPostData} from '@/helpers/event-automations.helper';

import AutomationCreateButton from '../automation-create-button/automation-create-button.view';
import TrackerDetailFilterBar from '@/components/automations/automation-detail/tracker-detail-filter-bar/tracker-detail-filter-bar.view';
import InlineFiltersSentence from '../sentence/builder-sentence.view';
import {useTranslation} from 'react-i18next';
import Indicator from '@/components/dashboard/Indicator/Indicator';
import {useAutomationDetail} from '@/context/event-automations/automation-detail.context';
import {Link, useLocation} from 'react-router-dom';
import {ILinkState} from '@/types/routes.types';
import {PLAYLIST_ROUTES, ROUTE} from '@/config/routes.config';

interface IBackButton {
  label: string;
  to: string;
}
function AutomationDetailHeader(): JSX.Element {
  // props: IAutomationDetailHeaderProps,
  /*
   * Hooks n State
   */
  const [_sentenceInfo, _setSentenceInfo] =
    useState<Partial<FIEventAutomationPostData> | null>(null);
  const [_additionalPlayersInfo, _setAdditionalPayersInfo] = useState<
    FIDisplayPlayer[]
  >([]);

  const {t} = useTranslation();
  const _location = useLocation();
  const _teamsContext = useTeams();
  const _authContext = useAuth();
  const _automationDetailContext = useAutomationDetail();
  const [_tags, _setTags] = useState<FITag[]>([]);
  const [_backButton, _setBackButton] = useState<IBackButton | null>(null);

  /* Generate tags */
  useEffect(() => {
    const _filteredTags: FITag[] = [];
    if (
      _automationDetailContext.automation &&
      'tags' in _automationDetailContext.automation &&
      _automationDetailContext.automation.tags?.length &&
      _teamsContext.ownTeam?.preferences?.tags?.length
    ) {
      _automationDetailContext.automation.tags.forEach((t) => {
        const foundTag = (
          deepCopy(_teamsContext.ownTeam?.preferences?.tags) as FITag[]
        )?.find((tag) => tag._id == t);
        if (foundTag) {
          foundTag.label = `#${hashtagify(foundTag.label, false)}`;

          _filteredTags.push(foundTag);
        }
      });

      _setTags(_filteredTags.sort());
    }
  }, [_automationDetailContext.automation, _teamsContext.ownTeam]);

  /* Generate sentenceInfo for title */
  useEffect(() => {
    if (_automationDetailContext.automation && _authContext.user) {
      _setSentenceInfo(
        formattedAutomationToPostData(_automationDetailContext.automation),
      );

      if (
        _automationDetailContext.automationType === 'shadow-tracker' &&
        _automationDetailContext.automation.players
      ) {
        const _playersOfAutomation: FIDisplayPlayer[] =
          _automationDetailContext.automation.players.map((p) => {
            return {
              _id: p._id,
              name: p.name,
              display_name: p.display_name,
              image_url: p.image_url,
            };
          });

        _setAdditionalPayersInfo(_playersOfAutomation);
      }
    } else {
      _setSentenceInfo(null);
    }
  }, [
    _automationDetailContext.automation,
    _authContext.user,
    _automationDetailContext.automationType,
  ]);

  /* Define back button */
  useEffect(() => {
    let _newBackButton: IBackButton | null = null;
    const _locationState = _location.state as ILinkState | undefined;
    if (_locationState) {
      _newBackButton = {
        label: _locationState.label,
        to: _locationState.route,
      };
    } else {
      const _label = t(
        `eventAutomations.${
          _automationDetailContext.automationType === 'opponent-automation'
            ? 'opponentAutomations'
            : 'trackers'
        }`,
        {count: 0},
      );
      const _to = `/${
        _automationDetailContext.automationType === 'opponent-automation'
          ? `${ROUTE.playlists}/${PLAYLIST_ROUTES.opponent_automations}`
          : ROUTE.trackers
      }`;

      _newBackButton = {
        label: _label,
        to: _to,
      };
    }

    _setBackButton(_newBackButton);
  }, [_location.state, _automationDetailContext.automationType]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  let _Title = null;
  if (_automationDetailContext.automation && _sentenceInfo) {
    _Title = (
      <InlineFiltersSentence
        automationType={_automationDetailContext.automationType}
        size="medium"
        data={_sentenceInfo}
        readonly
        withTarget={Boolean(_automationDetailContext.automation.target)}
        observingTeamId={_automationDetailContext.observingTeamId}
        onObservingTeamIdChange={
          _automationDetailContext.onObservingTeamIdChange
        }
        canSelectOpponent={
          _automationDetailContext.automationType === 'opponent-automation'
        }
        additionalPlayerInfo={_additionalPlayersInfo}
      />
    );
  }

  const _editButtonTitle = t(
    `eventAutomations.${
      _automationDetailContext.automationType === 'opponent-automation'
        ? 'automations'
        : 'trackers'
    }New`,
  );

  return (
    <div className={cn(styles.header, styles.headerWithBorder)}>
      {_backButton && (
        <Button
          variant="text"
          color="secondary"
          component={Link}
          size="small"
          to={_backButton.to}
          startIcon={<ArrowBackIcon />}>
          {_backButton.label}
        </Button>
      )}
      <div className={styles.topRow}>
        <div className={styles.titleContainer}>
          {_Title}
          {/* Edit button */}
          {_automationDetailContext.automation &&
            _automationDetailContext.automationType !== 'shadow-tracker' && (
              <AutomationCreateButton
                data={_automationDetailContext.automation}
                type={_automationDetailContext.automationType}
                onSave={_automationDetailContext.onAutomationChanged}
                Button={
                  <IconButton
                    title={_editButtonTitle}
                    color="secondary"
                    sx={{
                      mt:
                        _automationDetailContext.automationType !==
                        'opponent-automation'
                          ? -0.5
                          : undefined,
                    }}>
                    <EditIcon />
                  </IconButton>
                }
              />
            )}
        </div>
        <div className={styles.topRowButtons}>
          <Stack direction="row" alignItems="center" gap={2}>
            {/* Score */}
            {typeof _automationDetailContext.benchmarkedScore !==
              'undefined' && (
              <Box sx={{width: 240, pr: 1}}>
                <Indicator value={_automationDetailContext.benchmarkedScore} />
              </Box>
            )}

            {/* Tags */}
            {Boolean(_tags.length) && (
              <Tooltip title={_tags?.map((tag) => tag.label).join(' ')}>
                <Stack direction="row" alignItems="center" gap={0.5} pr={0.5}>
                  <LabelIcon color="secondary" />
                  <Typography fontSize={14} color="secondary">
                    {_tags.length}
                  </Typography>
                </Stack>
              </Tooltip>
            )}
          </Stack>
        </div>
      </div>
      {_automationDetailContext.automation?.players &&
        Boolean(_automationDetailContext.automation?.players?.length) && (
          <div className={styles.bottomRow}>
            <TrackerDetailFilterBar
              displayObjectiveCheckbox={
                _automationDetailContext.automation?.calculation !==
                'occurrences'
              }
            />
          </div>
        )}
    </div>
  );
}

export default AutomationDetailHeader;
