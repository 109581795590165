import React, {useState} from 'react';

import {Tab, Tabs} from '@mui/material';
import {DevSection} from './dev-section';
import {TDevComponent} from '@/types/dev.types';

interface ITabState {
  selectedTab: number;
}

export const DevSectionTabsView = () => {
  const [tabState, setTabState] = useState<ITabState>({
    selectedTab: 0,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabState({
      selectedTab: newValue,
    });
  };

  const tabs: TDevComponent[] = [
    {
      title: 'Tabs',
      variants: [
        {
          title: 'Regular',
          component: (
            <Tabs value={tabState.selectedTab} onChange={handleChange}>
              <Tab label="Scatter plot" />
              <Tab label="Heat Map" />
            </Tabs>
          ),
        },
      ],
    },
  ];

  return <DevSection title="Tabs" componentList={tabs} showItemTitle={false} />;
};
