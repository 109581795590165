import {SCOUTING_ROUTES} from '@/config/routes.config';
import ShadowTrackerDetailView from '@/views/automations/shadow-tracker_detail.view';
import PlayerView from '@/views/player.view';
import ScoutingView from '@/views/scouting.view';
import React from 'react';
import {Route, Routes} from 'react-router-dom';

function ScoutingRoutes() {
  return (
    <Routes>
      <Route
        path=""
        element={<ScoutingView viewType="scouted-players" />}
        key="scouted-players"
      />
      <Route
        path={SCOUTING_ROUTES.search}
        element={<ScoutingView viewType="search" />}
        key="search"
      />
      <Route path="player/:id" element={<PlayerView />} />
      <Route path="shadow-trackers/:id" element={<ShadowTrackerDetailView />} />
    </Routes>
  );
}

export default ScoutingRoutes;
