import {IDashboardScore} from '@/types/dashboard/dashboard-score.types';
import {IPitchVisualEventGroup} from '@/types/dashboard/dashboard-pitch-visual.types';
import {RowType} from '@atlaskit/dynamic-table/types';
import {StringMap} from '@/types/map.types';

import _ from 'lodash';

import {
  EVENT_FILTERS_MAP,
  EXCLUDED_ACTIONS_PER_PROVIDER,
  FIMatchEvent,
  FIEventFilters,
  TDataProvider,
} from '@my-game-plan/types';
import DASHBOARD_CATEGORIES from '@/config/dashboards.config';
import {ActionCategory} from '@/types/action.types';
import {Breakpoint} from '@mui/material';

export const getCurrentTeamScoreData = (
  _scores: IDashboardScore[],
  _teamId: string | undefined,
) => {
  if (!_teamId) return undefined;

  return _scores.find((score) => score.team._id === _teamId);
};
export const getCurrentTeamScore = (
  _scores: IDashboardScore[],
  _teamId: string | undefined,
) => {
  if (!_teamId) return 0;

  const score = _scores.find((score) => score.team._id === _teamId);

  return score ? score.score : 0;
};
export const getCurrentTeamScoreAndPosition = (
  _scores: IDashboardScore[],
  _teamId: string | undefined,
) => {
  if (!_teamId) return {score: 0, position: -1};

  const scoreIndex = _scores.findIndex((score) => score.team._id === _teamId);

  // If the team is not found
  if (scoreIndex === -1) return {score: 0, position: -1};

  return {score: _scores[scoreIndex].score, position: scoreIndex};
};
export const getMinMaxScores = (_scores: IDashboardScore[]) => {
  const scores = _scores.map((score) => score.score);
  const min = Math.min(...scores);
  const max = Math.max(...scores);

  return {min, max};
};
export const getMinMaxSubscores = (_scores: IDashboardScore[]) => {
  const subscoresMinMax: Record<string, {min: number; max: number}> = {};

  for (const score of _scores) {
    if (!score.subscores) continue;

    for (const subscore of score.subscores) {
      const property = subscore.property as keyof typeof subscoresMinMax;

      if (subscoresMinMax[property]) {
        subscoresMinMax[property].min =
          subscoresMinMax[property].min > subscore.events
            ? subscore.events
            : subscoresMinMax[property].min;
        subscoresMinMax[property].max =
          subscoresMinMax[property].max < subscore.events
            ? subscore.events
            : subscoresMinMax[property].max;
        continue;
      }

      subscoresMinMax[property] = {
        min: subscore.events,
        max: subscore.events,
      };
    }
  }

  return subscoresMinMax;
};
export const getSubscoresWeights = (_score: IDashboardScore) => {
  return _score.subscores?.sort((a, b) => b.weight - a.weight);
};
export const getScoreIndicatorModalWidth = (
  _score: IDashboardScore | undefined,
) => {
  let width: Breakpoint = 'sm';

  // if (_score && _score.subscores) width += 256 * (_score.subscores.length - 1);
  if (_score && _score.subscores) {
    if (_score.subscores.length > 2) {
      width = 'lg';
    }

    if (_score.subscores.length > 3) {
      width = 'xl';
    }
  }

  return width;
}; // If a coordinate is too close to 0 or 100, it won't be displayed correctly. Fix this.

export const extendRows = (
  rows: Array<RowType>,
  onClick: (e: React.MouseEvent, rowIndex: number) => void,
) => {
  return rows.map((row, index) => ({
    ...row,
    onClick: (e: React.MouseEvent) => onClick(e, index),
  }));
};

export function filterEventsForPitchVisualGroup(
  eventGroup: IPitchVisualEventGroup,
  filters: StringMap,
): FIMatchEvent[] {
  let _filteredEvents: FIMatchEvent[] = eventGroup.events;
  // Get filters for linked table

  // For every key in the filters
  Object.keys(filters).forEach((key) => {
    if (key === 'players' && filters[key].length) {
      // Filter players
      const events = [];

      for (const filter of filters[key]) {
        let temp = [];

        if (filter.receiving_player !== undefined) {
          temp = eventGroup.events.filter(
            (event) =>
              event.player._id === filter.player._id &&
              event.pass?.receiving_player?._id === filter.receiving_player._id,
          );
        } else {
          temp = eventGroup.events.filter(
            (event) => event.player._id === filter.player._id,
          );
        }

        events.push(...temp);
      }

      _filteredEvents = events;
    } else {
      // Filter qualifier keys
      if (filters[key]?.length) {
        const _qualifiersToFilter: string[] = [];
        const _qualifiersToFilterNin: string[] = [];
        for (const qualifier of filters[key]) {
          if (qualifier.startsWith('!')) {
            _qualifiersToFilterNin.push(qualifier.substring(1));
          } else {
            _qualifiersToFilter.push(qualifier);
          }
        }

        // console.log(`FILTER ${key} IN:`, _qualifiersToFilter);
        // console.log(`FILTER ${key} NOT IN:`, _qualifiersToFilterNin);

        const _typedKey = key as keyof FIEventFilters;
        const _fieldType = EVENT_FILTERS_MAP[_typedKey];
        if (_fieldType) {
          //
          _filteredEvents = _filteredEvents.filter((event) => {
            // const _eventFields = _fieldType.qualifier.split('.');
            const _value = _.get(event, _typedKey);
            // console.log('FILTER - ', `${key}:`, _value);
            if (_fieldType === 'array_string') {
              /* Array fields */
              const _containsAllInFilters = _qualifiersToFilter.every(
                (qualifier) => _value.includes(qualifier),
              );
              const _doesntContainNinFilters = _qualifiersToFilterNin.every(
                (qualifier) => !_value.includes(qualifier),
              );

              return _containsAllInFilters && _doesntContainNinFilters;
            } else {
              /* Other fields */
              const _inComparison =
                !_qualifiersToFilter.length ||
                _qualifiersToFilter.includes(_value);
              const _ninComparison =
                !_qualifiersToFilterNin.length ||
                !_qualifiersToFilterNin.includes(_value);
              return _inComparison && _ninComparison;
            }
          });
        }
      }
    }
  });
  return _filteredEvents;
}

export default function sortEvents(events: FIMatchEvent[]) {
  return events.sort((a, b) => {
    if (a.match._id === b.match._id) {
      const timeA = a.minute * 60 + a.second;
      const timeB = b.minute * 60 + b.second;
      return timeA - timeB;
    }
    return new Date(b.match.date).getTime() - new Date(a.match.date).getTime();
  });
}

export function getDashboardCategoriesForProvider(
  dataProvider: TDataProvider,
): ActionCategory[] {
  // const _filteredActionCategories: ActionCategory[] = [];

  const _actionsToRemove = EXCLUDED_ACTIONS_PER_PROVIDER[dataProvider];
  const _filteredActionCategories = DASHBOARD_CATEGORIES.map((category) => {
    const _subctategories = category.subcategories?.map((subcategory) => {
      return {
        ...subcategory,
        actions: subcategory.actions?.filter(
          (action) => !_actionsToRemove?.includes(action),
        ),
      };
    });
    return {
      ...category,
      actions: category.actions?.filter(
        (action) => !_actionsToRemove?.includes(action),
      ),
      subcategories: _subctategories,
    };
  });

  return _filteredActionCategories;
}
