import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {
  FIMatchEvent,
  FISingleSend,
  FISingleSendPostData,
} from '@my-game-plan/types';
import {PropsWithChildren, useState} from 'react';
import {useAuth} from '@/context/auth.context';

import {
  addSingleSend,
  deleteSingleSend,
  editSingleSend,
  fetchEvents,
  getSingleSends,
} from '@/controllers/single-sends.controller';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {useAnalytics} from './analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

export interface ISingleSendsAPI {
  all: FISingleSend[];
  getAll: () => void;
  isLoading: boolean;
  hasError: boolean;
  isInitialised: boolean;
  setIsInitialised: (isInitialised: boolean) => void;
  saveSingleSend: (
    singleSend: FISingleSendPostData,
    id?: string,
  ) => Promise<void>;
  deleteSingleSend: (id: string) => Promise<void>;
  fetchEvents: (id: string) => Promise<FIMatchEvent[]>;
}

const context = createCustomContext<ISingleSendsAPI>();
export const useSingleSends = createContextHook(context);

function SingleSendsProvider(
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element {
  /*
   * Init
   */
  const _auth = useAuth();
  const _snackbar = useSnackbar();
  const {t} = useTranslation();
  const _analyticsContext = useAnalytics();
  const [_all, _setAll] = useState<FISingleSend[]>([]);
  const [_isLoading, _setIsLoading] = useState<boolean>(true);
  const [_hasError, _setHasError] = useState<boolean>(false);
  const [_isInitialised, _setIsInitialised] = useState<boolean>(false);

  /*
   * Handlers
   */
  async function _getAll() {
    if (_auth.user) {
      try {
        _setIsLoading(true);
        const _fetchedData = await getSingleSends(_auth.user.team);
        _setAll(_fetchedData);
      } catch (error) {
        _setHasError(true);
      } finally {
        _setIsLoading(false);
        _setIsInitialised(true);
      }
    }
  }

  async function _fetchEvents(id: string): Promise<FIMatchEvent[]> {
    try {
      const _events = await fetchEvents(id);
      return _events;
    } catch (error) {
      throw new Error((error as any).message);
    }
  }

  async function _saveSingleSend(
    singleSend: FISingleSendPostData,
    id?: string,
  ): Promise<void> {
    try {
      if (!_auth.user) return;

      if (singleSend.title === '') {
        throw new Error('title-required');
      }
      const _dataToSave: FISingleSendPostData = {
        ...singleSend,
        team_id: _auth.user?.team,
      };

      if (id) {
        await editSingleSend(id, _dataToSave);
        _snackbar.enqueueSnackbar(t('singleSends.edit.success'), {
          variant: 'success',
        });
      } else {
        await addSingleSend(_dataToSave);
        _analyticsContext.trackEvent(
          ANALYTICS_EVENT.CREATED_SINGLE_SEND_PLAYLIST,
        );
        _snackbar.enqueueSnackbar(t('singleSends.create.success'), {
          variant: 'success',
        });
      }
      _getAll();
    } catch (error) {
      _snackbar.enqueueSnackbar(t('error-states.default'), {
        variant: 'error',
      });
      throw new Error((error as any).message);
    }
  }

  async function _deleteSingleSend(id: string) {
    await deleteSingleSend(id);
    _snackbar.enqueueSnackbar(t('singleSends.delete.success'), {
      variant: 'success',
    });
    _getAll();
  }

  /*
   * Return context
   */
  return createProvider(context, props, {
    all: _all,
    getAll: _getAll,
    isLoading: _isLoading,
    hasError: _hasError,
    isInitialised: _isInitialised,
    setIsInitialised: _setIsInitialised,
    fetchEvents: _fetchEvents,
    saveSingleSend: _saveSingleSend,
    deleteSingleSend: _deleteSingleSend,
  });
}

export default SingleSendsProvider;
