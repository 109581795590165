import DefaultDropdown from './dropdown/default-dropdown.view';
import DetailsDropdown from './dropdown/details-dropdown/details-dropdown.view';
import ObservingDropdown from './dropdown/observing-dropdown/observing-dropdown.view';
import PlayersDropdown from './dropdown/players-dropdown.view';
import ActionsDropdown from './dropdown/actions-dropdown.view';
import NumberInputSegment from './number-input/number-input.view';
import TextSegment from './text/text.view';
import ZonesDropdown from './dropdown/zones-dropdown.view';
import CompetitionsDropdown from './dropdown/competitions-dropdown.view';
import PositionsDropdown from './dropdown/positions-dropdown.view';
import MetricsDropdown from './dropdown/metrics-dropdown.view';

const Segment = {
  Text: TextSegment,
  Players: PlayersDropdown,
  NumberInput: NumberInputSegment,
  Action: ActionsDropdown,
  Details: DetailsDropdown,
  Dropdown: DefaultDropdown,
  Observing: ObservingDropdown,
  Positions: PositionsDropdown,
  Zones: ZonesDropdown,
  Competitions: CompetitionsDropdown,
  Metrics: MetricsDropdown,
};

export default Segment;
