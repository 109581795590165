import React from 'react';
import BoltIcon from '@mui/icons-material/Bolt';
import {ChartSvgGroup} from '../../common/chart-svg/ChartSvgGroup';
import {ChartSvgIcon} from '../../common/chart-svg/ChartSvgIcon';
import Logo from '../../common/Logo/Logo';
import {TrackerDetailVideoIcons} from '../../automations/automation-detail/videos/item/icons/TrackerDetailVideoIcons';
import {TrackerDetailVideoIconsTotalEvents} from '../../automations/automation-detail/videos/item/icons/TrackerDetailVideoIconTotalEvents';
import {
  formatDate,
  getOpponent,
  getOpponentImage,
  roundAndFormatNumber,
  roundNumber,
} from '@/helpers/general/general.helper';
import {
  FIAutomationPlayerMatch,
  FIEventFilters,
  TCompetitionType,
} from '@my-game-plan/types';
import CompetitionIcon from '@/components/common/competition-icon/competition-icon.view';

export const getTooltip = (
  team: string | undefined,
  relative: boolean,
  match: FIAutomationPlayerMatch,
  displayMinutesPlayed = true,
  metric?: keyof FIEventFilters,
) => {
  const _opponentTeam = getOpponent(team, match.match);

  const _score = roundAndFormatNumber(match.score || 0, 2);
  return (
    <div
      style={{
        backgroundColor: 'var(--separator)',
        padding: 8,
        borderRadius: 4,
      }}>
      {_opponentTeam?.name && (
        <p style={{fontWeight: 'bold'}}>{_opponentTeam?.name}</p>
      )}
      <span className="subtitle">{formatDate(match.match.date)}</span>
      <div
        style={{display: 'flex', gap: 16, alignItems: 'center', marginTop: 8}}>
        <TrackerDetailVideoIcons
          relative={relative}
          totalEvents={match.total_events || 0}
          totalSuccessfulEvents={match.total_successful_events || 0}
          minutesPlayed={
            displayMinutesPlayed ? match.minutes_played : undefined
          }
          rate={match.score}
        />
        {Boolean(metric) && (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <BoltIcon color="primary" />
            <span style={{fontWeight: 'bold'}}>{_score}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export const getCompactTooltip = (
  team: string | undefined,
  match: FIAutomationPlayerMatch,
) => {
  if (!team) return <></>;

  let _totalEventsLabel: number | string = match.total_events || 0;
  if (match.total_successful_events || match.total_successful_events === 0) {
    _totalEventsLabel = match.total_events
      ? `${(
          roundNumber(match.total_successful_events / match.total_events, 2) *
          100
        ).toFixed(0)}%`
      : '0%';
  }

  return (
    <div
      style={{
        backgroundColor: 'var(--separator)',
        padding: 8,
        borderRadius: 4,
        display: 'flex',
        gap: 8,
      }}>
      <Logo src={getOpponentImage(team, match.match) || ''} />
      <TrackerDetailVideoIconsTotalEvents
        totalEvents={_totalEventsLabel}
        compact
      />
    </div>
  );
};

export const getXAxisTick = (
  props: any,
  opponentImage: string,
  matchDate: Date | undefined,
  amountOfMatches?: number,
  isObservingMatch?: boolean,
  competitionType?: TCompetitionType,
) => {
  const {x, y} = props;

  const _isSmall = amountOfMatches && amountOfMatches >= 20;
  const _size = _isSmall ? 16 : 24;
  const _competitionIconXOffset = _isSmall ? -4 : 4;
  const _competitionIconYOffset = _isSmall ? -8 : -16;
  return (
    <ChartSvgGroup x={x} y={y}>
      <ChartSvgIcon
        isObserving={isObservingMatch}
        src={opponentImage}
        size={_size}
        value={
          amountOfMatches &&
          (window.innerWidth >= 1200
            ? amountOfMatches <= 15
            : amountOfMatches < 8)
            ? matchDate
              ? formatDate(matchDate, 'DD/MM')
              : ''
            : ''
        }
      />
      <g
        transform={`translate(${_competitionIconXOffset}, ${_competitionIconYOffset})`}>
        <CompetitionIcon
          elevation={10}
          competition_type={competitionType || 'domestic_league'}
          size={_isSmall ? 'small' : 'medium'}
        />
      </g>
    </ChartSvgGroup>
  );
};
