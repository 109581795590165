import React, {useEffect, useState} from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useTranslation} from 'react-i18next';
import {useAutomationForm} from '@/context/event-automations/automations-form.context';
import {FIEventAutomationSubject} from '@my-game-plan/types';

function AutomationShareCheckbox(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _automationFormContext = useAutomationForm();
  const [_isChecked, _setIsChecked] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    _getSharedWithSubjectBasedOnObserving(
      _automationFormContext.data?.observing_players,
      _automationFormContext.data?.shared_with_players,
      _isChecked,
    );
  }, [_automationFormContext.data, _isChecked]);

  /*
   * Handlers
   */
  function _getSharedWithSubjectBasedOnObserving(
    observingPlayers?: FIEventAutomationSubject,
    sharedWithPlayers?: FIEventAutomationSubject,
    checked?: boolean,
  ): FIEventAutomationSubject | undefined {
    let _newValue: FIEventAutomationSubject | undefined =
      _automationFormContext.data?.observing_players;

    if (
      (checked && !observingPlayers) ||
      (typeof checked === 'undefined' && sharedWithPlayers)
    ) {
      _newValue = {
        team: 'own',
      };
      if (typeof checked === 'undefined') {
        _setIsChecked(true);
      }
    } else if (typeof checked !== 'undefined' && !checked) {
      _newValue = undefined;
    }

    return _newValue;
  }

  function _onCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) {
    const _newValue = _getSharedWithSubjectBasedOnObserving(
      _automationFormContext.data?.observing_players,
      _automationFormContext.data?.shared_with_players,
      checked,
    );

    _setIsChecked(Boolean(_newValue));

    _automationFormContext.setData({
      shared_with_players: _newValue,
    });
  }

  /*
   * Render
   */
  const _text = t('sentenceForm.share.shareWithPlayers');
  return (
    <FormControlLabel
      control={
        <Checkbox checked={_isChecked || false} onChange={_onCheckboxChange} />
      }
      label={_text}
    />
  );
}

export default AutomationShareCheckbox;
