import {COMPETITIONS_API_CONFIG} from '@/config/api.config';
import {useAPI} from '@/hooks/api.hooks';
import {Resource} from '@/types/api.types';
import {FICompetition, FICompetitionSeason} from '@my-game-plan/types';

const api = useAPI();

export async function getCompetitions(): Promise<FICompetition[]> {
  try {
    const {data} = await api.get<FICompetition[]>({
      resource: Resource.competitions,
      url: '',
    });
    if (!data) {
      return [];
    }
    return data;
  } catch (error) {
    return [];
  }
}

export async function getCompetitionSeasons(): Promise<FICompetitionSeason[]> {
  try {
    const {data} = await api.get<FICompetitionSeason[]>({
      resource: Resource.competitions,
      url: COMPETITIONS_API_CONFIG.getCompetitionSeasons(),
    });
    if (!data) {
      return [];
    }
    return data;
  } catch (error) {
    return [];
  }
}
