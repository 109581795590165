import * as React from 'react';
import {styled, Theme, CSSObject} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import AddIcon from '@mui/icons-material/Add';

import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import {
  toggleActionSnakeCase,
  translateAction,
} from '@/helpers/translation.helper';

import {NavLink} from 'react-router-dom';

import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

import {NAV_ICONS, ROUTE} from '@/config/routes.config';

import {useTeams} from '@/context/team.context';
import {useOverviewDashboards} from '@/context/dashboard/overview-dashboards.context';

import {ACTION} from '@my-game-plan/types';
import {ActionCategory} from '@/types/action.types';
import {getDashboardCategoriesForProvider} from '@/helpers/dashboard/dashboard.helper';

import Collapse from '@mui/material/Collapse';
import DASHBOARD_CATEGORIES from '@/config/dashboards.config';
import {TeamSelector} from './team-selector/TeamSelector';
import './sidebar.scss';
import {Arrow} from './Arrow';
import {capitalize} from 'lodash';

const OVERVIEW_DASHBOARDS_CATEGORY = 'overview-dashboards';
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Sidebar = () => {
  /*
   * Hooks n State
   */
  const [_activeCategory, _setActiveCategory] = useState<string | null>(null);
  const [_openedSubcategories, _setOpenedSubCategories] = useState<string[]>(
    [],
  );
  const [_actionCategories, _setActionCategories] = useState<ActionCategory[]>(
    [],
  );

  const _teamsContext = useTeams();
  const _location = useLocation();
  const {t} = useTranslation();
  const _overviewDashboards = useOverviewDashboards();
  const {isCustomDashboard, selectedDashboard} = _overviewDashboards;

  useEffect(() => {
    if (_activeCategory) {
      setOpen(true);
    }
  }, [_activeCategory]);

  useEffect(() => {
    if (isCustomDashboard(selectedDashboard))
      _setActiveCategory(OVERVIEW_DASHBOARDS_CATEGORY);
    else {
      let _subCategoryToPush = null;
      const _category = DASHBOARD_CATEGORIES.find((category) => {
        if (category.actions) {
          return category.actions.includes(selectedDashboard as ACTION);
        }

        if (category.subcategories) {
          return category.subcategories.some((subcategory) => {
            if (subcategory.actions.includes(selectedDashboard as ACTION)) {
              _subCategoryToPush = subcategory.label;
              return true;
            }

            return false;
          });
        }

        return false;
      });
      if (_category && _category.label !== _activeCategory) {
        _setActiveCategory(_category.label);
        if (_subCategoryToPush) {
          _setOpenedSubCategories([_subCategoryToPush]);
        }
      }
    }
  }, [selectedDashboard]);

  useEffect(() => {
    if (_teamsContext.ownTeam) {
      const _filteredActionCategories = getDashboardCategoriesForProvider(
        _teamsContext.ownTeam.data_provider,
      );
      _setActionCategories(_filteredActionCategories);
    }
  }, [_teamsContext.ownTeam]);

  /*
   * Handlers
   */
  function _onCategoryClick(category: string) {
    _setOpenedSubCategories([]);
    if (category === _activeCategory) {
      return _setActiveCategory(null);
    }
    _setActiveCategory(category);
  }

  function _onSubcategoryClick(subcategory: string) {
    const _newSubcategories = [..._openedSubcategories];
    const _index = _newSubcategories.indexOf(subcategory);
    if (_index < 0) {
      _newSubcategories.push(subcategory);
    } else {
      _newSubcategories.splice(_index, 1);
    }

    _setOpenedSubCategories(_newSubcategories);
  }

  function _onNewDashboardClick() {
    _overviewDashboards.setIsEditing(false);
    _overviewDashboards.setIsModalOpen(true);
  }

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    _setActiveCategory(null);
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{paddingTop: 8}}
      className="sidebar">
      <Toolbar sx={{mb: 2}} />
      <Box sx={{paddingX: open ? 1 : 0, mb: 1}}>
        <TeamSelector open={open} />
      </Box>

      <List>
        {/* OVERVIEW DASHBOARDS */}
        <ListItemButton
          onClick={() => _onCategoryClick(OVERVIEW_DASHBOARDS_CATEGORY)}
          // selected={_activeCategory === OVERVIEW_DASHBOARDS_CATEGORY}
        >
          <ListItemIcon sx={{minWidth: 32, ...(!open && {m: 0})}}>
            {NAV_ICONS.dashboards && <NAV_ICONS.dashboards color="secondary" />}
          </ListItemIcon>
          {open && (
            <>
              <ListItemText>
                <Typography variant="subtitle2" noWrap>
                  {t('overview-dashboards.nav')}
                </Typography>
              </ListItemText>
              <Arrow
                isExpanded={_activeCategory === OVERVIEW_DASHBOARDS_CATEGORY}
              />
            </>
          )}
        </ListItemButton>
        <Collapse
          in={_activeCategory === OVERVIEW_DASHBOARDS_CATEGORY}
          unmountOnExit>
          <List>
            {_overviewDashboards?.all?.map((overviewDashboard) => {
              const _to = `${ROUTE.dashboards}/${_teamsContext.current?._id}/${overviewDashboard._id}`;
              return (
                <ListItemButton
                  key={overviewDashboard._id}
                  component={NavLink}
                  to={_to}
                  sx={{
                    '&.active': {
                      color: 'primary.main',
                      bgcolor: 'secondary.dark',
                    },
                  }}>
                  <ListItemText sx={{pl: 3}}>
                    <Typography variant="subtitle2" noWrap>
                      {overviewDashboard.title}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              );
            })}
            <ListItemButton onClick={_onNewDashboardClick}>
              <ListItemIcon sx={{pl: 3, minWidth: 32}}>
                <AddIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="subtitle2" noWrap>
                  {t('overview-dashboards.add.button')}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </List>
        </Collapse>

        {/* ACTIONS DASHBOARDS */}
        {_actionCategories.map((category) => {
          const _isCategoryActive = _activeCategory === category.label;
          const _Icon = category.icon;

          const _categoryLabel = t(
            `dashboard.categories.${category.label}.label`,
          );

          return (
            <React.Fragment key={category.label}>
              <ListItemButton
                onClick={() => _onCategoryClick(category.label)}
                // selected={_isCategoryActive}
              >
                <ListItemIcon sx={{minWidth: 32}}>
                  <_Icon color="secondary" />
                </ListItemIcon>
                {open && (
                  <>
                    <ListItemText>
                      <Typography variant="subtitle2">
                        {_categoryLabel}
                      </Typography>
                    </ListItemText>
                    <Arrow isExpanded={_isCategoryActive} />
                  </>
                )}
              </ListItemButton>
              {/* ACTIONS: SUBCATEGORIES */}
              <Collapse in={_isCategoryActive}>
                <List disablePadding>
                  {category.subcategories &&
                    category.subcategories.map((subcategory) => {
                      const _isSubcategoryVisible =
                        _openedSubcategories.indexOf(subcategory.label) > -1;

                      const _subCategoryLabel = t(
                        `dashboard.categories.${category.label}.subcategories.${subcategory.label}`,
                      );
                      return (
                        <React.Fragment key={subcategory.label}>
                          <ListItemButton
                            key={`button-${subcategory.label}`}
                            onClick={() =>
                              _onSubcategoryClick(subcategory.label)
                            }>
                            <ListItemText sx={{pl: 3}}>
                              <Typography noWrap variant="subtitle2">
                                {_subCategoryLabel}
                              </Typography>
                            </ListItemText>
                            <Arrow isExpanded={_isSubcategoryVisible} />
                          </ListItemButton>

                          {/* ACTIONS */}
                          <Collapse
                            key={`collapse-${subcategory.label}`}
                            in={_isSubcategoryVisible}
                            unmountOnExit>
                            <List disablePadding>
                              {subcategory.actions.map((action) => {
                                const _to = `${ROUTE.dashboards}/${
                                  _teamsContext.current?._id
                                }/${toggleActionSnakeCase(action, true)}${
                                  _location.search
                                }`;
                                const _translatedAction = capitalize(
                                  translateAction(action),
                                );
                                return (
                                  <ListItemButton
                                    key={action}
                                    component={NavLink}
                                    to={_to}
                                    // selected
                                    sx={{
                                      '&.active': {
                                        color: 'primary.main',
                                        bgcolor: 'secondary.dark',
                                      },
                                    }}>
                                    <ListItemText sx={{pl: 4}}>
                                      <Typography noWrap variant="subtitle2">
                                        {_translatedAction}
                                      </Typography>
                                    </ListItemText>
                                  </ListItemButton>
                                );
                              })}
                            </List>
                          </Collapse>
                        </React.Fragment>
                      );
                    })}
                </List>
              </Collapse>
            </React.Fragment>
          );
        })}
      </List>

      <List style={{marginTop: 'auto'}}>
        <ListItem>
          {open ? (
            <IconButton onClick={handleDrawerClose} color="secondary">
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <IconButton
              color="secondary"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start">
              <ChevronRightIcon />
            </IconButton>
          )}
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
