import React, {useEffect} from 'react';
import {Tab} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import overviewStyles from '@/components/dashboard/stylesheet/overview.module.scss';
import {RunThresholdItem} from '@/components/preferences/run_thresholds/RunThresholdItem';
import {TagPreferences} from '@/components/preferences/TagPreferences';
import {useTeams} from '@/context/team.context';
import {CountTagUsageBody, F} from '@my-game-plan/types';

enum Tabs {
  runs = 'runs',
  tags = 'tags',
  pitchZones = 'pitch',
}

export function PreferencesView(): JSX.Element {
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const [value, setValue] = React.useState<Tabs>(Tabs.runs);
  const [tagCountData, setTagCountData] =
    React.useState<F<CountTagUsageBody>>();

  const handleChange = (event: React.SyntheticEvent, newValue: Tabs) => {
    setValue(newValue);
  };

  // call this even before the tab has been opened to improve UX
  useEffect(() => {
    _teamsContext.getTagCountData().then((data) => setTagCountData(data));
  }, []);

  // In case tags are deleted
  useEffect(() => {
    if (!tagCountData || !_teamsContext.ownTeam?.preferences?.tags) return;
    const newTagCountData: any = {};
    _teamsContext.ownTeam.preferences.tags.forEach((tag) => {
      newTagCountData[tag._id] = tagCountData[tag._id];
      newTagCountData[tag._id].tag = tag; // in case the label changes
    });
    setTagCountData(newTagCountData);
  }, [_teamsContext.ownTeam?.preferences?.tags]);

  /*
   * Render
   */
  return (
    <>
      <Box padding={3}>
        <div className={overviewStyles.titleContainer}>
          <h2 className={overviewStyles.title}>{t('preferences.title')}</h2>
        </div>

        <TabContext value={value}>
          <Box>
            <TabList onChange={handleChange}>
              <Tab
                label={t('preferences.run_thresholds.title')}
                value={Tabs.runs}
              />
              <Tab label={t('preferences.tags.title')} value={Tabs.tags} />
            </TabList>
          </Box>
          <TabPanel value={Tabs.runs} style={{paddingLeft: 0}}>
            <RunThresholdItem run_type={'run'} />
            <RunThresholdItem run_type={'deep_run'} />
            <RunThresholdItem run_type={'diagonal_run'} />
            <RunThresholdItem run_type={'lateral_run'} />
          </TabPanel>
          <TabPanel value={Tabs.tags} style={{paddingLeft: 0}}>
            <TagPreferences tagCountData={tagCountData} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
