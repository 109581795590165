import React, {useState} from 'react';
import {DevSection} from './dev-section';
import {TDevComponent} from '@/types/dev.types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export const DevSectionModalView = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modal: TDevComponent[] = [
    {
      title: 'Modal',
      variants: [
        {
          title: 'Regular',
          component: (
            <div>
              <Button variant="outlined" color="primary" onClick={handleOpen}>
                Open Modal
              </Button>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Modal title</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    This is an example of a modal description
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Close</Button>
                </DialogActions>
              </Dialog>
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <DevSection title="Modal" componentList={modal} showItemTitle={false} />
  );
};
