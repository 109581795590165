import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Button, {ButtonProps} from '@mui/material/Button';

import React from 'react';
import {useTranslation} from 'react-i18next';
import {useVideo} from '@/context/video/video.context';
import {FIEventAutomationPostData, FIMatchEvent} from '@my-game-plan/types';

interface VideoPlayerButtonProps extends ButtonProps {
  events: FIMatchEvent[] | null;
  displayTotalEvents?: boolean;
  actionInfo?: Partial<FIEventAutomationPostData>;
}

function VideoPlayerButton({
  events,
  actionInfo,
  displayTotalEvents = true,
  ...buttonProps
}: VideoPlayerButtonProps) {
  const _video = useVideo();
  const {t} = useTranslation();

  if (!events) {
    return (
      <Button startIcon={<PlayArrowIcon />} disabled>
        {t('video-player.button.play')}
      </Button>
    );
  }

  const handleClick = () => {
    _video.openVideoPlayer(events, undefined, true, actionInfo);
  };

  return (
    // <Button
    //   iconBefore={<VidPlayIcon label="Play icon" />}
    //   isDisabled={events.length <= 0}
    //   onClick={handleClick}
    //   appearance="primary">
    //   {t('video-player.button.play')} ({events.length})
    // </Button>
    <Button
      startIcon={<PlayArrowIcon />}
      disabled={events.length <= 0}
      onClick={handleClick}
      color={buttonProps.color || 'primary'}
      variant={buttonProps.variant || 'contained'}
      size={buttonProps.size || 'small'}>
      {`${t('video-player.button.play')}${
        displayTotalEvents ? ` (${events.length})` : ''
      }`}
    </Button>
  );
}

export default VideoPlayerButton;
