import React, {useEffect, useState} from 'react';

import {ICommonSentenceProps} from '@/types/sentence-form.types';
import SentenceLine from './line';

import Conditions from './conditions/conditions.view';

import {FIEventCondition, isTrackingAction} from '@my-game-plan/types';
import Sentence from './sentence.view';

function InlineFiltersSentence(props: ICommonSentenceProps): JSX.Element {
  /*
   * Hooks n State
   */
  const [_isTrackingAction, _setIsTrackingAction] = useState<boolean>(false);
  const [_shouldHideDetails, _setShouldHideDetails] = useState<boolean>(true);
  const [_shouldHideRuleConditionDetails, _setShouldHideRuleConditionDetails] =
    useState<boolean>(true);
  const [_isRatio, _setIsRatio] = useState<boolean>(false);
  const [_isRule, _setIsRule] = useState<boolean>(false);
  const [_isOpponentAutomation, _setIsOpponentAutomation] =
    useState<boolean>(false);

  useEffect(() => {
    let _newIsTrackingActionValue = false;
    if (props.data.action) {
      _newIsTrackingActionValue = isTrackingAction(props.data.action);
    }

    _setIsTrackingAction(_newIsTrackingActionValue);
    _setIsRatio(props.data.calculation === 'ratio');
    _setIsRule(props.data.calculation === 'rule');
  }, [props.data]);

  useEffect(() => {
    _setIsOpponentAutomation(props.automationType === 'opponent-automation');
  }, [props.automationType]);

  useEffect(() => {
    const _hasDetails = Boolean(props.data.details?.filters.length);
    const _shouldHideMainDetails =
      // _isRule ||
      _isTrackingAction ||
      (!_isRatio && !props.data.action) ||
      Boolean(props.readonly && !_isRatio && !_hasDetails);

    _setShouldHideDetails(_shouldHideMainDetails);
  }, [
    _isRatio,
    props.readonly,
    props.data.action,
    _isTrackingAction,
    props.data.details,
  ]);

  useEffect(() => {
    let _hideDetails = false;
    const _hasRuleConditionDetails = Boolean(
      props.data.rule_condition?.details?.filters.length,
    );
    if (
      (props.data.rule_condition?.action &&
        isTrackingAction(props.data.rule_condition?.action)) ||
      (props.readonly && !_hasRuleConditionDetails)
    ) {
      _hideDetails = true;
    }

    _setShouldHideRuleConditionDetails(_hideDetails);
  }, [_isRule, props.readonly, props.data.rule_condition]);

  // const _isRatio = props.data.calculation === 'ratio';
  // const _shouldShowDetails = _isRatio || props.data.action &&;

  // const _sentenceClasses = cn(styles.sentence, {
  //   [styles.sentenceReadonly]: props.readonly,
  // });

  /*
   * Handlers
   */
  function _onRuleConditionChange(data: Partial<FIEventCondition>) {
    if (props.onChange && props.data.rule_condition) {
      props.onChange({
        rule_condition: {
          ...props.data.rule_condition,
          ...data,
        },
      });
    }
  }

  /*
   * Render
   */
  let _detailsProperty: 'details' | 'success_details' = 'details';
  let _detailsValue = props.data.details;
  let _isDetailsRequired = false;

  if (_isRatio) {
    _detailsProperty = 'success_details';
    _detailsValue = props.data.success_details;
    _isDetailsRequired = true;
  }

  let _BaseLineComponent = SentenceLine.AutomationBaseline;
  if (_isTrackingAction) {
    // Opponent automation with tracking data
    _BaseLineComponent = SentenceLine.AutomationBaselineTrackingData;
  }

  return (
    <Sentence.Sentence readonly={props.readonly} size={props.size}>
      <Sentence.Section>
        {_isRule ? (
          <SentenceLine.RuleCondition {...props} />
        ) : (
          <Sentence.Line>
            <_BaseLineComponent {...props} />
          </Sentence.Line>
        )}

        {!_shouldHideRuleConditionDetails && _isRule && (
          <Sentence.Line>
            <SentenceLine.Details<FIEventCondition>
              {...props}
              action={props.data.rule_condition?.action}
              property={'details'}
              value={props.data.rule_condition?.details}
              required={false}
              onChange={_onRuleConditionChange}
              count={1}
            />
          </Sentence.Line>
        )}

        {/* Trackers: Conditions */}
        <Conditions {...props} />

        {!_shouldHideDetails && !_isRule && (
          <Sentence.Line>
            <SentenceLine.Details
              {...props}
              action={props.data.action}
              property={_detailsProperty}
              value={_detailsValue}
              required={_isDetailsRequired}
            />
          </Sentence.Line>
        )}
      </Sentence.Section>
      {/* Opponent automations */}
      {_isOpponentAutomation && !props.readonly && (
        <>
          <Sentence.Section>
            <Sentence.Line>
              <SentenceLine.OpponentAutomationsMatchLimit {...props} />
            </Sentence.Line>

            <Sentence.Line>
              <SentenceLine.OpponentAutomationsShare {...props} />
            </Sentence.Line>
          </Sentence.Section>
        </>
      )}

      {!props.readonly && _isOpponentAutomation && (
        <Sentence.Section>
          <Sentence.Line>
            <SentenceLine.Notify {...props} />
          </Sentence.Line>
        </Sentence.Section>
      )}

      {_isRule && (
        <Sentence.Section>
          <Sentence.Line>
            <_BaseLineComponent {...props} />
          </Sentence.Line>
          {!_shouldHideDetails && (
            <Sentence.Line>
              <SentenceLine.Details
                {...props}
                action={props.data.action}
                property={'details'}
                value={props.data.details}
                required={false}
                count={1}
              />
            </Sentence.Line>
          )}
        </Sentence.Section>
      )}
    </Sentence.Sentence>
  );
}

export default InlineFiltersSentence;
