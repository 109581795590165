import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

// import OverviewScreen from '@/components/overview-screen/groups/overview-groups-screen.view';
import {FIEventAutomation, FIPlayerWithPerformance} from '@my-game-plan/types';
import {useTrackers} from '@/context/event-automations/tracker.context';
import {useAuth} from '@/context/auth.context';
import OverviewScreen, {
  IOverviewScreenView,
} from '@/components/overview-screen/overview-screen.view';
import {useAnalytics} from '@/context/analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

function TrackersView(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _auth = useAuth();
  const _trackerContext = useTrackers();
  const _analyticsContext = useAnalytics();

  useEffect(() => {
    if (_auth.user) {
      _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_TRACKERS_OVERVIEW, {
        team_id: _auth.user.team,
      });
    }

    return () => {
      _trackerContext.setIsInitialised(false);
    };
  }, [_auth.user]);

  /*
   * Handlers
   */

  /*
   * Render
   */

  // return (
  //   <OverviewScreen
  //     data={_trackerContext.trackers}
  //     title={t('eventAutomations.trackers', {count: 0})}
  //     keyExtractor={_keyExtractor}
  //     isLoading={_trackerContext.loading}
  //     automationType="tracker"
  //     isInitialised={_trackerContext.isInitialised}
  //     error={_trackerContext.hasError}
  //   />
  // );
  const _tableView: IOverviewScreenView<FIEventAutomation> = {
    loadingState: _trackerContext.getAllLoadingState,
    type: 'table',
    data: _trackerContext.trackers,
    availableFilterOptions: {
      tags: [],
      actions: [],
      players: [],
    },
    getData: _trackerContext.getAll,
  };
  const _cardView: IOverviewScreenView<FIPlayerWithPerformance> = {
    loadingState: _trackerContext.getAllPerPlayerLoadingState,
    type: 'cards',
    data: _trackerContext.players,
    availableFilterOptions: {
      tags: [],
      actions: [],
      players: [],
    },
    getData: _trackerContext.getAllPerPlayer,
  };

  return (
    <OverviewScreen
      title={t('team.performance')}
      automationType="tracker"
      views={[_cardView, _tableView]}
    />
  );
}

export default TrackersView;
