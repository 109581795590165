import React, {PropsWithChildren, useState} from 'react';

import {FIEventAutomation, FIPlayerWithPerformance} from '@my-game-plan/types';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {useAuth} from '@/context/auth.context';

import {
  getAllAutomations,
  getAllAutomationsGroupedByPlayer,
} from '@/controllers/event-automations.controller';
import {LOADING_STATE} from '@/types/screen.types';

export interface TrackerAPI {
  // loading: boolean;
  trackers: FIEventAutomation[];
  getAllLoadingState: LOADING_STATE;
  getAll: () => void;

  getAllPerPlayerLoadingState: LOADING_STATE;
  getAllPerPlayer: () => void;
  players: FIPlayerWithPerformance[];
  // hasError: boolean;
  isInitialised: boolean;
  setIsInitialised: (isInitialised: boolean) => void;

  clear: () => void;
}

const context = createCustomContext<TrackerAPI>();
export const useTrackers = createContextHook(context);

export const TrackerProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  const _auth = useAuth();

  const [_getAllLoadingState, _setGetAllLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );
  const [_getAllPerPlayerLoadingState, _setGetAllPerPlayerLoadingState] =
    useState<LOADING_STATE>(LOADING_STATE.INITING);
  const [_trackers, _setTrackers] = useState<FIEventAutomation[]>([]);
  const [_players, _setPlayers] = useState<FIPlayerWithPerformance[]>([]);

  const [_isInitialised, _setIsInitialised] = useState<boolean>(false);

  async function _getAll() {
    if (_auth.user) {
      try {
        _setGetAllLoadingState(LOADING_STATE.LOADING);
        const _fetchedTrackers = await getAllAutomations(
          'tracker',
          _auth.user.team,
        );

        _setTrackers(_fetchedTrackers);
        _setGetAllLoadingState(LOADING_STATE.SUCCESS);
      } catch (error) {
        _setGetAllLoadingState(LOADING_STATE.ERROR);
      } finally {
        _setIsInitialised(true);
      }
    }
  }

  async function _getAllPerPlayer() {
    if (_auth.user) {
      try {
        _setGetAllPerPlayerLoadingState(LOADING_STATE.LOADING);
        const _fetchedPlayers = await getAllAutomationsGroupedByPlayer(
          'tracker',
          _auth.user.team,
        );

        _setPlayers(_fetchedPlayers);
        _setGetAllPerPlayerLoadingState(LOADING_STATE.SUCCESS);
      } catch (error) {
        _setGetAllPerPlayerLoadingState(LOADING_STATE.ERROR);
      } finally {
        _setIsInitialised(true);
      }
    }
  }

  function _clear() {
    _setTrackers([]);
    _setGetAllLoadingState(LOADING_STATE.INITING);
    _setPlayers([]);
    _setGetAllPerPlayerLoadingState(LOADING_STATE.INITING);
  }

  return createProvider(context, props, {
    trackers: _trackers,
    getAll: _getAll,
    getAllPerPlayer: _getAllPerPlayer,
    getAllLoadingState: _getAllLoadingState,
    getAllPerPlayerLoadingState: _getAllPerPlayerLoadingState,
    players: _players,
    clear: _clear,
    isInitialised: _isInitialised,
    setIsInitialised: _setIsInitialised,
  });
};
