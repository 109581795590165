import Box from '@mui/material/Box';
import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';

import {useTranslation} from 'react-i18next';

import {IBenchmarkOptions} from '@my-game-plan/types';

import BenchmarkSentence from '@/components/automations/sentence/benchmark-sentence.view';
import {lighten, useTheme} from '@mui/material';
import {useAutomationDetail} from '@/context/event-automations/automation-detail.context';

interface IBenchmarkFilterPopupProps {
  onClose: () => void;
  anchorEl?: Element | null;
}

function BenchmarkFilterPopup(props: IBenchmarkFilterPopupProps) {
  /*
   * Hooks n State
   */
  const [_data, _setData] = React.useState<IBenchmarkOptions | null>(null);
  const {t} = useTranslation();
  const _theme = useTheme();
  const _automationDetailContext = useAutomationDetail();

  /*
   * Side effects
   */
  useEffect(() => {
    _setData(_automationDetailContext.benchmarkInfo?.filters || null);
  }, [_automationDetailContext.benchmarkInfo?.filters]);

  /*
   * Handlers
   */
  function _onChange(data: Partial<IBenchmarkOptions>) {
    const _filters = {
      ...(_automationDetailContext.benchmarkInfo?.filters || {}),
      ...data,
    };
    _setData(_filters);

    // props.updateBenchmarkFilters(_filters, false, true);
  }

  function _onSubmit() {
    if (!_data) return;

    _automationDetailContext.getBenchmarkInfo(_data);
    props.onClose();
  }

  // function _onReset() {
  //   _trackerContext.updateBenchmarkFilters();
  //   // console.log('RESTORE DEFAULTS');
  // }
  function _onClose() {
    //
    props.onClose();
  }

  /*
   * Render
   */

  const _isOpen = Boolean(props.anchorEl);
  if (!_automationDetailContext.automation || !_data) return null;

  return (
    <Popover
      PaperProps={{
        sx: {
          bgcolor: lighten(_theme.palette.background.paper, 0.03),
        },
      }}
      elevation={4}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      anchorEl={props.anchorEl}
      open={_isOpen}
      onClose={_onClose}>
      <Box p={3} minWidth={360} maxWidth={440}>
        <Box mb={4}>
          <BenchmarkSentence
            tracker={_automationDetailContext.automation}
            onChange={_onChange}
            benchmarkOptions={_data}
          />
        </Box>

        {/* Buttons */}
        <Box display="flex" flexDirection="row" gap={4} alignItems="center">
          <Button variant="contained" color="primary" onClick={_onSubmit}>
            {t('eventAutomations.benchmark.benchmark')}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
}

export default BenchmarkFilterPopup;
