export interface IPagination {
  total_pages: number;
  current_page: number;
  total_results: number;
}

export interface IPaginationQueryParams {
  page?: string;
  limit?: string;
}
export interface HTTPResponse<T> {
  message?: string;
  data?: T;
  pagination?: IPagination;
}

export enum RequestMethod {
  get = 'get',
  post = 'post',
  put = 'put',
  delete = 'delete',
}

export interface RequestOptions {
  resource: Resource;
  url: string;
  method: RequestMethod;
  data?: any;
  params?: any;
  options?: object;
}

export interface NormalRequest {
  resource: Resource;
  url: string;
  params?: any;
  options?: object;
}

export interface BodyRequest {
  resource: Resource;
  url: string;
  params?: any;
  data?: any;
  options?: object;
}

export enum Resource {
  admin = 'admin',
  events = 'events',
  auth = 'auth',
  users = 'users',
  widgets = 'widgets',
  teams = 'teams',
  assigned = 'assigned',
  scores = 'scores',
  matches = 'matches',
  competitions = 'competitions',
  dashboards = 'dashboards',
  trackers = 'trackers',
  shadow_trackers = 'shadow-trackers',
  clips = 'clips',
  video = 'video',
  player_statistics = 'player-statistics',
  players = 'players',
  opponent_automations = 'opponent-automations',
  single_sends = 'single-sends',
  zones = 'zones',
  shot_analysis = 'shot-analysis',
  background_jobs = 'background-jobs',
  file_uploads = 'file-uploads',
  player_objectives = 'player-objectives',
}
