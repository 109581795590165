import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {
  FIBenchmarkInfo,
  FIEventFilters,
  FIFormattedAutomation,
  FIFormattedAutomationPlayerScores,
  FIMatchEvent,
  FIPitchZone,
  FMatch,
  IBenchmarkOptions,
  TEventAutomationType,
  generateTotalScoresForPlayer,
  TBenchmarkDisabledReason,
  shouldInvertScore,
  getBenchmarkDisabledReason,
} from '@my-game-plan/types';
import {PropsWithChildren, useEffect, useState} from 'react';
import {useAuth} from '../auth.context';
import {useTeams} from '../team.context';
import {useZones} from '../zones.context';
import {useParams, useSearchParams} from 'react-router-dom';
import {
  benchmarkAutomation,
  fetchEventsForAutomation,
  getSingleAutomation,
} from '@/controllers/event-automations.controller';
import {getPositionFilterByObservingPlayers} from '@/helpers/benchmark.helper';

import {formatPlayerScores} from '@/helpers/automation/automation-detail-history.helper';
import {useCompetitions} from '../competitions.context';
import {extractZonesFromAutomation} from '@/helpers/pitch-zones.helper';
import {useAnalytics} from '../analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

import {IAutomationSeasonInfo} from '@/types/event-automations-misc.types';
import {formatSeason} from '@/helpers/competitions.helper';
import {useMatches} from '../matches.context';
import {
  getMatchesByScoutedPlayer,
  getMatchesByTeam,
} from '@/controllers/matches.controller';

export interface AutomationDetailAPI {
  automation: FIFormattedAutomation | null;
  onAutomationChanged: (automation: FIFormattedAutomation | null) => void;
  automationType: TEventAutomationType;
  isFetchingAutomation: boolean;
  isFetchingEvents: boolean;
  events: FIMatchEvent[];
  scores: FIFormattedAutomationPlayerScores | null;

  observingMatchIds: string[];
  onObservingMatchIdChange: (selectedMatchIds: string[]) => void;

  observingTeamId: string | undefined;
  onObservingTeamIdChange: (teamId: string | undefined) => void;

  zones: FIPitchZone[];

  benchmarkDisabledReason: TBenchmarkDisabledReason | null;
  benchmarkInfo: FIBenchmarkInfo | null;
  getBenchmarkInfo: (benchmarkFilters: IBenchmarkOptions) => Promise<void>;

  benchmarkedScore: number | undefined;
  error: boolean;
  historicalSeasonInfo: IAutomationSeasonInfo[];

  getAutomation: (automationId: string) => Promise<void>;
  // getAutomationEvents: (automationId: string) => Promise<void>;
  // clear: () => void;

  isOnlyShowingObjectiveEvents: boolean;
  setIsOnlyShowingObjectiveEvents: (value: boolean) => void;
}

const context = createCustomContext<AutomationDetailAPI>();
export const useAutomationDetail = createContextHook(context);

interface IProviderProps {
  automationType: TEventAutomationType;
}
export function AutomationDetailProvider(
  props: PropsWithChildren<IProviderProps>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const [_automation, _setAutomation] = useState<FIFormattedAutomation | null>(
    null,
  );
  const [_isFetchingAutomation, _setIsFetchingAutomation] = useState(true);
  const [_isFetchingEvents, _setIsFetchingEvents] = useState(true);
  const [_events, setEvents] = useState<FIMatchEvent[]>([]);
  const [_isOnlyShowingObjectiveEvents, _setIsOnlyShowingObjectiveEvents] =
    useState<boolean>(false);

  const [_scores, _setScores] =
    useState<FIFormattedAutomationPlayerScores | null>(null);
  const [_observingMatchIds, _setObservingMatchIds] = useState<string[]>([]);
  const [_observingTeamId, _setObservingTeamId] = useState<string | undefined>(
    undefined,
  );

  const [_zones, _setZones] = useState<FIPitchZone[]>([]);

  const [_benchmarkDisabledReason, _setBenchmarkDisabledReason] =
    useState<TBenchmarkDisabledReason | null>(null);
  const [_benchmarkInfo, _setBenchmarkInfo] = useState<FIBenchmarkInfo | null>(
    null,
  );
  const [_benchmarkedScore, _setBenchmarkedScore] = useState<
    number | undefined
  >(undefined);

  const [_error, _setError] = useState<boolean>(false);

  const [_searchParams, _setSearchParams] = useSearchParams();
  const [_historicalSeasonInfo, _setHistoricalSeasonInfo] = useState<
    IAutomationSeasonInfo[]
  >([]);
  const [_playedMatches, _setPlayedMatches] = useState<FMatch[]>([]);

  const _params = useParams();

  const _matchesContext = useMatches();
  const _authContext = useAuth();
  const _teamsContext = useTeams();
  const _zonesContext = useZones();
  const _competitionsContext = useCompetitions();
  const _analyticsContext = useAnalytics();

  /* Get automation when params.id changes */
  useEffect(() => {
    if (
      _params.id &&
      _teamsContext.ownTeam &&
      _observingTeamId &&
      props.automationType
    ) {
      _getAutomation(_params.id, _observingTeamId);
    }
  }, [
    _params.id,
    _teamsContext.ownTeam,
    _observingTeamId,
    props.automationType,
  ]);

  /* Get observing team */
  useEffect(() => {
    const _teamIdfromQuery = _searchParams.get('team._id');

    let _teamToObserve =
      _teamIdfromQuery || _teamsContext.ownTeam?._id || undefined;
    if (
      props.automationType === 'opponent-automation' &&
      _teamsContext.nextOpponentId
    ) {
      _teamToObserve = _teamsContext.nextOpponentId;
    } else if (
      props.automationType === 'opponent-automation' &&
      !_teamsContext.nextOpponentId
    ) {
      _setError(true);
    }

    _setObservingTeamId(_teamToObserve);
  }, [
    _teamsContext.ownTeam,
    _teamsContext.nextOpponentId,
    props.automationType,
    _searchParams,
  ]);

  /* Log analytics event */
  useEffect(() => {
    if (_authContext.user && _params.id) {
      const _analyticsEvent =
        props.automationType === 'tracker'
          ? ANALYTICS_EVENT.VIEWED_TRACKERS_DETAIL
          : ANALYTICS_EVENT.VIEWED_OPPONENT_AUTOMATION;
      _analyticsContext.trackEvent(_analyticsEvent, {
        id: _params.id,
        team_id: _authContext.user.team,
      });
    }
  }, [_params.id, _authContext.user]);

  /* When automation was fetched, get events */
  useEffect(() => {
    if (_automation && _observingTeamId && _teamsContext.ownTeam) {
      _getAutomationEvents();
    }
  }, [
    _automation,
    props.automationType,
    _observingTeamId,
    _teamsContext.ownTeam,
  ]);

  useEffect(() => {
    async function _fetchHistoryOfOlderSeasons() {
      if (
        !_automation?.players?.length ||
        !_observingTeamId ||
        !_teamsContext.ownTeam ||
        !_params.id
      ) {
        return;
      }

      const _seasons = _automation.players[0].history.map(
        (history) => history.match.season_id,
      );
      const _uniqueSeasonsInHistory = Array.from(new Set(_seasons));

      const _observingMatches = _playedMatches.filter((match) =>
        _observingMatchIds.includes(match._id),
      );
      const _allSeasons = _observingMatches.map((match) => match.season_id);
      const _uniqueSeasonsInObservingMatches = Array.from(new Set(_allSeasons));

      const _seasonsMissingInHistory = _uniqueSeasonsInObservingMatches.filter(
        (season) => !_uniqueSeasonsInHistory.includes(season),
      );

      if (_seasonsMissingInHistory.length) {
        const _filters: FIEventFilters = {
          'match.season_id': _uniqueSeasonsInObservingMatches,
        };
        try {
          _setIsFetchingAutomation(true);

          // TODO - We should actually only get missing season data, now we just fetch all seasons
          const _automationWithMissingSeasons = await getSingleAutomation(
            props.automationType,
            _params.id,
            _filters,
          );

          _setAutomation(_automationWithMissingSeasons);
        } catch (error) {
          _setError(true);
        } finally {
          _setIsFetchingAutomation(false);
        }
      }
    }

    _fetchHistoryOfOlderSeasons();
  }, [
    _playedMatches,
    _observingMatchIds,
    _automation?.players,
    _observingTeamId,
    _teamsContext.ownTeam,
    props.automationType,
    _params,
  ]);

  /* Recalculate scores */
  useEffect(() => {
    if (_observingMatchIds?.length && _automation?.players?.length) {
      _setScoresHandler(
        _automation?.players?.[0].scores.benchmark_average,
        _automation?.benchmark_filters?.calculation === 'per_90',
      );
    } else {
      _setScores(null);
    }
  }, [_observingMatchIds, _automation?.players?.[0]?.history]);

  /* Define historical season info */
  /* Based on active competition seasons define default "loaded" seasons */
  useEffect(() => {
    async function _fetchMatchesAndDefineHistory() {
      //
      const _automationHistory = _automation?.players?.[0]?.history;
      if (
        !_authContext.user ||
        !_automationHistory ||
        !_matchesContext.ownMatches.length ||
        _playedMatches.length
      ) {
        return;
      }

      /* 1. Get all played matches for observing player / team */
      /* For players, this will be the current team's history, meaning data of transfered might not be included */
      /* 1.1 - For trackers, use matches from context */
      let _matchesByTeam = _matchesContext.ownMatches;

      if (props.automationType === 'opponent-automation') {
        /* 1.2 - For opponent-automations fetch all played matches from observing team */
        _matchesByTeam = await getMatchesByTeam(_observingTeamId);
      } else if (
        props.automationType === 'shadow-tracker' &&
        _automation.observing_players?.players?.length
      ) {
        /* 1.3 - For shadow trackers fetch all played matches of player's current team */
        _matchesByTeam = await getMatchesByScoutedPlayer(
          _automation.observing_players.players[0],
        );
      }

      /* 1.4 - Only show played matches */
      const _matches = _matchesByTeam.filter(
        (match) =>
          match.event_data_available && match.match_status === 'played',
      );
      _setPlayedMatches(_matches);

      /* 2. Define available seasons based on played matches */
      const _allSeasonIds = _matches.map((match) => match.season_id);
      /* Remove duplicates */
      const _distinctSeasons = Array.from(new Set(_allSeasonIds));

      const _generatedHistoricalSeasonInfo: IAutomationSeasonInfo[] =
        _distinctSeasons.map((seasonId) => {
          const _matchesForSeason = _matches.filter(
            (match) => match.season_id === seasonId,
          );

          /* Get list of competitions for season */
          const _allCompetitionIdsOfSeason = _matchesForSeason.map(
            (match) => match.competition_id,
          );
          const _uniqueCompetitionIdsOfSeason = Array.from(
            new Set(_allCompetitionIdsOfSeason),
          );
          const _competitionsForSeason = _competitionsContext.all.filter(
            (competition) => {
              return _uniqueCompetitionIdsOfSeason.includes(competition._id);
            },
          );

          /* Create season info object */
          const _info: IAutomationSeasonInfo = {
            seasonId: seasonId,
            seasonLabel: formatSeason(seasonId),
            competitions: _competitionsForSeason,
            // loading_state: _isSeasonInHistory
            //   ? LOADING_STATE.SUCCESS
            //   : LOADING_STATE.INITING,
            matches: _matchesForSeason,
          };
          return _info;
        });

      /* Set historical season info */
      _setHistoricalSeasonInfo(_generatedHistoricalSeasonInfo);
    }

    _fetchMatchesAndDefineHistory();
  }, [
    _competitionsContext.all,
    _automation?.players,
    _matchesContext.ownMatches,
  ]);

  /* Parse zone info */
  useEffect(() => {
    let _zones: FIPitchZone[] = [];
    const _zoneIds = extractZonesFromAutomation(_automation);
    _zones = _zonesContext.all.filter((zone) => _zoneIds.includes(zone._id));
    _setZones(_zones);
  }, [_automation, _zonesContext.all]);

  /* Init benchmark filters */
  useEffect(() => {
    if (!_automation?.benchmark_filters) {
      return;
    }
    const _shouldBenchmark = !getBenchmarkDisabledReason(_automation);

    if (_shouldBenchmark) {
      _getAutomationBenchmarkData({
        ..._automation.benchmark_filters,
        // season_id: [_seasonId],
      });
      return;
    }

    _setBenchmarkInfo(null);
  }, [_automation?.benchmark_filters]);

  /*
   * Handlers
   */
  // Calculate scores based on benchmark data, players and observing matches
  function _setScoresHandler(
    benchmarkAverage?: number,
    calculatePer90?: boolean,
    benchmarkedScore?: number,
  ) {
    if (!_automation?.players?.length || !_observingMatchIds?.length) {
      _setScores(null);
      _setBenchmarkedScore(undefined);
      return;
    }

    const _newBenchmarkedScore =
      typeof benchmarkedScore !== 'undefined'
        ? benchmarkedScore
        : _automation.players[0].scores.benchmarked_score;
    _setBenchmarkedScore(_newBenchmarkedScore);

    /* Filter history */
    const _filteredHistory = _automation.players[0].history.filter((history) =>
      _observingMatchIds.includes(history.match._id),
    );
    const _isRatioTracker =
      _automation.calculation === 'ratio' || _automation.calculation === 'rule';

    const _totalScores = generateTotalScoresForPlayer(
      _filteredHistory,
      !_automation.observing_players?.players?.length,
      _isRatioTracker,
      Boolean(_automation.metric),
      benchmarkAverage,
      _newBenchmarkedScore,
      calculatePer90,
    );

    const _shouldInvert = shouldInvertScore(_automation);

    _setScores(
      formatPlayerScores(
        _automation.action,
        _totalScores,
        _automation.calculation,
        _automation.rule_condition?.action,
        true,
        calculatePer90,
        _shouldInvert,
        _automation.metric,
      ),
    );
  }

  // Fetch automation
  async function _getAutomation(
    automationId: string,
    teamId?: string,
    params?: FIEventFilters,
  ) {
    if (!teamId) {
      teamId = _teamsContext.ownTeam?._id;
    }
    if (automationId && _teamsContext.ownTeam && teamId) {
      _setIsFetchingAutomation(true);

      try {
        _setPlayedMatches([]);
        const _params: FIEventFilters = {
          ...params,
          'own_team._id': [_teamsContext.ownTeam._id],
          ...(props.automationType === 'opponent-automation'
            ? {'team._id': [teamId]}
            : {}),
        };

        const _fetchedAutomation = await getSingleAutomation(
          props.automationType,
          automationId,
          _params,
        );

        // Set default selected matches
        const _automationHistory = _fetchedAutomation.players?.[0]?.history;
        if (_automationHistory) {
          const _allMatchesByTeam: FMatch[] = _automationHistory
            .map((historyItem) => historyItem.match)
            .sort(
              (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf(),
            );

          _setObservingMatchIds(_allMatchesByTeam.map((match) => match._id));
        }

        /* Benchmark */
        // Check if benchmark is disabled
        const _newBenchmarkDisabledReason =
          getBenchmarkDisabledReason(_fetchedAutomation);

        if (!_newBenchmarkDisabledReason) {
          /* Fetch benchmark */
          const _playerFilters = getPositionFilterByObservingPlayers(
            _teamsContext.ownPlayers,
            _fetchedAutomation.observing_players,
          );

          const _benchmarkFilters: IBenchmarkOptions = {
            ..._playerFilters,
            ...(_competitionsContext.domesticCompetition && {
              competition_id: [_competitionsContext.domesticCompetition._id],
            }),

            ..._fetchedAutomation.benchmark_filters,
          };

          if (_fetchedAutomation.benchmark_data) {
            _setBenchmarkInfo({
              benchmark_data: undefined,
              filters: _benchmarkFilters,
              state: 'active',
            });
          }
        }

        _setBenchmarkDisabledReason(_newBenchmarkDisabledReason);

        /* Set automation */

        _setAutomation(_fetchedAutomation);
        _setIsFetchingAutomation(false);
      } catch (error) {
        // TODO handle error
        _setError(true);
        _setIsFetchingAutomation(false);
        _setAutomation(null);
      }
    }
  }

  async function _getAutomationEvents() {
    /* Fetch events - To be removed when we add events to trackers response */
    if (_teamsContext.ownTeam && _observingTeamId && _automation) {
      /* Define season id to get events for */
      /* Get seasons that are present in history */
      const _seasonIdInHistory =
        _automation.players?.[0].history.map((item) => item.match.season_id) ||
        [];
      const _seasonId = Array.from(new Set(_seasonIdInHistory));
      _setIsFetchingEvents(true);
      const _eventParams: FIEventFilters = {
        'own_team._id': [_teamsContext.ownTeam._id],
        ...(props.automationType !== 'tracker'
          ? {'team._id': [_observingTeamId]}
          : {}),
        ...(_seasonId ? {'match.season_id': _seasonId} : {}),
      };

      const _fetchedEvents = await fetchEventsForAutomation(
        props.automationType,
        _automation._id,
        _eventParams,
      );
      setEvents(_fetchedEvents);
      _setIsFetchingEvents(false);
    }
  }

  async function _getAutomationBenchmarkData(
    benchmarkFilters: IBenchmarkOptions,
  ) {
    if (_automation && _authContext.user) {
      const _seasonId =
        _authContext.user.competition_info.latest_domestic_competition_with_data
          .season_id;
      const _benchmarkFilters: IBenchmarkOptions = {
        ...benchmarkFilters,
        season_id: [_seasonId],
      };
      _setBenchmarkInfo({
        filters: _benchmarkFilters,
        state: 'loading',
      });
      let _teamId = undefined;
      if (props.automationType === 'opponent-automation') {
        _teamId = _observingTeamId;
      }

      try {
        const _result = await benchmarkAutomation(
          props.automationType,
          _automation._id,
          _benchmarkFilters,
          _teamId,
        );

        if (_result.ranking?.length > 1) {
          _setBenchmarkInfo({
            filters: _benchmarkFilters,
            state: 'active',
            benchmark_data: _result,
          });

          _setScoresHandler(
            _result.benchmark_average,
            benchmarkFilters.calculation === 'per_90',
            _result.benchmarked_score,
          );
        } else {
          _setBenchmarkInfo({
            filters: _benchmarkFilters,
            state: 'error',
            benchmark_data: undefined,
            error: 'no-data',
          });
          _setBenchmarkedScore(undefined);
        }

        _analyticsContext.trackEvent(ANALYTICS_EVENT.BENCHMARKED_TRACKER, {
          ..._benchmarkFilters,
          id: _automation._id,
        });
      } catch (error) {
        _setBenchmarkInfo({
          filters: _benchmarkFilters,
          state: 'error',
          error: error ? (error as any).message : 'default',
        });
      }
    }
  }

  /* Match filter */
  function _onObservingMatchIdsChanged(selectedMatchIds: string[]) {
    _setObservingMatchIds(selectedMatchIds);
  }

  /*
   * RETURN PROVIDER
   */
  return createProvider<AutomationDetailAPI, IProviderProps>(context, props, {
    automation: _automation,
    onAutomationChanged: _setAutomation,
    automationType: props.automationType,
    isFetchingAutomation: _isFetchingAutomation,
    isFetchingEvents: _isFetchingEvents,
    events: _events,
    scores: _scores,

    observingMatchIds: _observingMatchIds,
    onObservingMatchIdChange: _onObservingMatchIdsChanged,

    observingTeamId: _observingTeamId,
    onObservingTeamIdChange: _setObservingTeamId,

    zones: _zones,

    benchmarkInfo: _benchmarkInfo,
    getBenchmarkInfo: _getAutomationBenchmarkData,
    benchmarkDisabledReason: _benchmarkDisabledReason,

    benchmarkedScore: _benchmarkedScore,
    error: _error,
    historicalSeasonInfo: _historicalSeasonInfo,

    getAutomation: _getAutomation,
    // getAutomationEvents: _getAutomationEvents,

    isOnlyShowingObjectiveEvents: _isOnlyShowingObjectiveEvents,
    setIsOnlyShowingObjectiveEvents: _setIsOnlyShowingObjectiveEvents,
  });
}
