const TEAM_TRANSLATIONS = {
  teams_one: 'Team',
  teams_other: 'Teams',
  performance: 'Team performance',
  cta: {
    viewTeam: 'View team',
  },
};

export default TEAM_TRANSLATIONS;
