import React, {useEffect, useState} from 'react';
import {startsWithVowel} from '@/helpers/translation.helper';

import {ICommonSentenceProps, TError} from '@/types/sentence-form.types';
import Segment from '../segment';

import {
  ACTION,
  FIEventCondition,
  TEventObservingTeam,
} from '@my-game-plan/types';

import {
  EVENT_CONDITION_OBSERVING_OPTIONS,
  OPPONENT_AUTOMATION_OBSERVING_OPTIONS,
} from '@/config/event-automation-builder.config';
import Sentence from '../sentence.view';
import {IObservingDropdownOptionGroup} from '../segment/dropdown/observing-dropdown/observing-dropdown-content.view';
import {useTranslation} from 'react-i18next';

interface IConditionLineProps
  extends Omit<ICommonSentenceProps<FIEventCondition>, 'errors'> {
  condition: FIEventCondition;
  errors?: TError<FIEventCondition>;
  action?: ACTION;
  observingTeam?: TEventObservingTeam;
}

function ConditionBaseLine(props: IConditionLineProps): JSX.Element {
  /* Hooks n State */
  const {t} = useTranslation();
  const [_observingOptions, _setObservingOptions] = useState<
    IObservingDropdownOptionGroup[]
  >([]);
  const {condition, ..._commonProps} = props;
  const _typedCommonProps =
    _commonProps as ICommonSentenceProps<FIEventCondition>;

  /* Handlers */
  useEffect(() => {
    const _options =
      props.automationType === 'tracker'
        ? EVENT_CONDITION_OBSERVING_OPTIONS
        : OPPONENT_AUTOMATION_OBSERVING_OPTIONS;
    _setObservingOptions(_options);
  }, [props.automationType]);

  /* Render */
  let _BaseSegments = null;
  if (condition.type === 'when') {
    let _whenConditionText = t('sentenceForm.conditions.when');

    const _isFirstConditionAboutTime =
      condition.details?.filters?.[0]?.property === 'time_block';
    if (props.observingTeam === 'opponent' && !_isFirstConditionAboutTime) {
      const _suffixKey =
        props.automationType === 'opponent-automation'
          ? 'nextOpponentOpponent'
          : 'opponent';
      const _suffix = t(`sentenceForm.observing.${_suffixKey}`);

      _whenConditionText = `${_whenConditionText} ${_suffix} ${t(
        'sentenceForm.is',
      )}`;
    }

    // if (props.automationType === )

    _BaseSegments = (
      <>
        <Sentence.Segment>
          <Segment.Text text={_whenConditionText} {..._typedCommonProps} />
        </Sentence.Segment>
        <Sentence.Segment>
          <Segment.Details<FIEventCondition>
            {..._typedCommonProps}
            property="details"
            placeholder="sentenceForm.conditions.gameStates.placeholder"
            action={props.action}
            isGameStateCondition
            value={condition.details}
            error={props.errors?.details}
            required
          />
        </Sentence.Segment>
      </>
    );
  } else {
    const _OccuringWithinSegment = (
      <Sentence.Segment>
        <Segment.Text
          {..._typedCommonProps}
          translationKey={'sentenceForm.conditions.occurringWithin'}
        />
      </Sentence.Segment>
    );

    const _NumberInputSegment = (
      <Sentence.Segment>
        <Segment.NumberInput<FIEventCondition>
          {..._typedCommonProps}
          property="seconds"
          // required
          value={condition.seconds}
          error={props.errors?.seconds}
        />
      </Sentence.Segment>
    );
    const _SecondsSegment = (
      <Sentence.Segment>
        <Segment.Text
          translationKey={'sentenceForm.conditions.seconds'}
          translationKeyOptions={{count: condition.seconds || 0}}
          {..._typedCommonProps}
        />
      </Sentence.Segment>
    );
    const _BeforeAfterSegment = (
      <Sentence.Segment>
        <Segment.Text
          translationKey={`sentenceForm.conditions.${condition.type}`}
          {..._typedCommonProps}
        />
      </Sentence.Segment>
    );

    let _articleKey = 'sentenceForm.an';
    if (condition.action && !startsWithVowel(condition.action)) {
      _articleKey = 'sentenceForm.a';
    }
    const _ActionArticleSegment = (
      <Sentence.Segment>
        <Segment.Text translationKey={_articleKey} {..._typedCommonProps} />
      </Sentence.Segment>
    );

    const _ActionInputSegment = (
      <Sentence.Segment>
        <Segment.Action<FIEventCondition>
          {..._typedCommonProps}
          property="action"
          isConditionInput
          required
          count={1}
          value={condition.action}
          error={props.errors?.action}
        />
      </Sentence.Segment>
    );

    const _BySegment = (
      <Sentence.Segment>
        <Segment.Text
          translationKey={'sentenceForm.by'}
          {..._typedCommonProps}
        />
      </Sentence.Segment>
    );

    const _ObservingSegment = (
      // <Sentence.Segment>
      <Segment.Observing<FIEventCondition>
        {..._typedCommonProps}
        canSelectOpponent={false}
        property="observing_players"
        value={condition.observing_players}
        onChange={props.onChange}
        maxItemsToShow={3}
        options={_observingOptions}
      />
      // </Sentence.Segment>
    );

    _BaseSegments = (
      <>
        {_OccuringWithinSegment}
        {_NumberInputSegment}
        {_SecondsSegment}
        {_BeforeAfterSegment}
        {_ActionArticleSegment}
        {_ActionInputSegment}
        {_BySegment}
        {_ObservingSegment}
      </>
    );
  }

  return _BaseSegments;
}

export default ConditionBaseLine;
