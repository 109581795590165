import {t} from 'i18next';
import React, {useEffect, useState} from 'react';
import {IPitchVisual} from '@/types/dashboard/dashboard-pitch-visual.types';

import {Card} from '../../../common/card/Card';
import {CenteredSpinner} from '../../../common/centered-spinner/CenteredSpinner';
import PitchVisual from '../../../dashboard/pitch-visual/PitchVisual';

import {
  ACTION,
  FIConditionalEventFilters,
  FIEventFilters,
  FIMatchEvent,
  FIPitchZone,
  TEventCalculationType,
  eventsArrayToPitchVisualGroups,
  getPitchVisualForAction,
} from '@my-game-plan/types';
import {useTeams} from '@/context/team.context';

interface TrackerDetailPitchVisualProps {
  id: string;
  action: ACTION;
  details?: FIConditionalEventFilters;
  metric?: keyof FIEventFilters;
  observingMatchIDs: string[];
  zones?: FIPitchZone[];
  events: FIMatchEvent[];
  loading: boolean;
  observingTeamId?: string;
  isObservingOpponent?: boolean;
  isOnlyShowingObjectiveEvents?: boolean;
  automationCalculation?: TEventCalculationType;
}

export const TrackerDetailPitchVisual = ({
  action,
  details,
  metric,
  observingMatchIDs,
  zones,
  events,
  loading,
  observingTeamId,
  isObservingOpponent,
  isOnlyShowingObjectiveEvents,
  automationCalculation,
}: TrackerDetailPitchVisualProps) => {
  const [_pitchVisualData, _setPitchVisualData] = useState<
    IPitchVisual | undefined
  >(undefined);
  const [_teamImage, _setTeamImage] = useState<string>('');

  const _teamsContext = useTeams();

  useEffect(() => {
    if (observingTeamId && _teamsContext.all.length) {
      const _observingTeam = _teamsContext.all.find(
        (team) => team._id === observingTeamId,
      );
      if (_observingTeam) {
        _setTeamImage(_observingTeam.image_url);
      }
    }
  }, [observingTeamId, _teamsContext.all]);

  useEffect(() => {
    const _filteredEvents = events.filter((event) => {
      let _includeObjectiveEvent = true;
      if (isOnlyShowingObjectiveEvents) {
        _includeObjectiveEvent = Boolean(event.successful);
      }
      return (
        _includeObjectiveEvent && observingMatchIDs.includes(event.match._id)
      );
    });
    const _groups = eventsArrayToPitchVisualGroups(action, _filteredEvents);

    const _pitchVisualConfig = getPitchVisualForAction(action);
    _setPitchVisualData({
      ..._pitchVisualConfig,
      linkedBlock: 'trackers',
      tabs: [
        {
          linkedTable: 'trackers',
          groups: _groups,
        },
      ],
    });
  }, [events, action, observingMatchIDs, isOnlyShowingObjectiveEvents]);

  return (
    <Card>
      {loading && <CenteredSpinner />}
      {((!loading && !_pitchVisualData) || !action) && (
        <p>{t('eventAutomations.noData')}</p>
      )}
      {!loading && _pitchVisualData && action && (
        <PitchVisual
          activeTab={0}
          details={details}
          data={_pitchVisualData}
          action={action}
          metric={metric}
          isOwnTeam={true}
          zones={zones}
          teamLogo={_teamImage}
          isObservingOpponent={isObservingOpponent}
          calculation={automationCalculation}
        />
      )}
    </Card>
  );
};
