import React from 'react';
import {IAutomationsGroup} from '@/types/event-automation-groups.types';
import AutomationsGroupPlayer from './automations-table-player-accordion.view';
import {ENTIRE_TEAM_LABEL, TEventAutomationType} from '@my-game-plan/types';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutomationsTable from './automations-table/automations-table.view';
import {useTranslation} from 'react-i18next';

interface IAutomationsGroupProps {
  group: IAutomationsGroup;
  automationType: TEventAutomationType;
  isFirstGroup?: boolean;
}

function AutomationsGroup(props: IAutomationsGroupProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Handlers
   */

  /*
   * Render
   */
  let _Content = null;

  if (props.group.players?.length) {
    _Content = (
      <Stack>
        {props.group.players.map((player, index) => {
          return (
            <AutomationsGroupPlayer
              key={player._id}
              player={player}
              automationType={props.automationType}
              isTeam={props.group.key === ENTIRE_TEAM_LABEL}
              defaultExpanded={props.isFirstGroup && index === 0}
            />
          );
        })}
      </Stack>
    );
  } else if (props.group.automations?.length) {
    _Content = (
      <Card sx={{p: 2}}>
        <AutomationsTable
          automations={props.group.automations}
          automationType={props.automationType}
          benchmarkLabel={t('eventAutomations.benchmark.leagueAverageShort')}
          data={props.group.postData}
        />
      </Card>
    );
  }
  return (
    <Stack gap={1}>
      {props.group.title && (
        <Typography variant="h6">{props.group.title}</Typography>
      )}
      {_Content}
    </Stack>
  );
}

export default AutomationsGroup;
