import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import React, {useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Logo from '../../common/Logo/Logo';
import {TeamDropwndownItem} from './team-dropdown-item/TeamDropdownItem';
import {ROUTE} from '@/config/routes.config';
import {useTeams} from '@/context/team.context';
import Typography from '@mui/material/Typography';

interface ITeamSelectorProps {
  open: boolean;
}

export const TeamSelector = ({open}: ITeamSelectorProps) => {
  /*
   * Hooks n State
   */
  const _teamsContext = useTeams();
  const _location = useLocation();
  const [_anchorEl, _setAnchorEl] = useState<HTMLElement | null>(null);

  const isDashboardRoute = _location.pathname.includes(ROUTE.dashboards);
  const _filteredTeams = useMemo(
    () =>
      _teamsContext.leagueTeams.filter(
        (team) => team._id !== _teamsContext.current?._id,
      ),
    [_teamsContext.current],
  );
  /*
   * Handlers
   */
  function _onTriggerClick(event: React.MouseEvent<HTMLButtonElement>) {
    _setAnchorEl(event.currentTarget);
  }

  function _onClose() {
    _setAnchorEl(null);
  }

  /*
   * Render
   */
  const image = _teamsContext.current ? _teamsContext.current.image_url : '';

  if (!isDashboardRoute) return <></>;
  const _isOpened = Boolean(_anchorEl);
  return (
    <>
      <Button
        color="secondary"
        variant={open ? 'outlined' : 'text'}
        startIcon={<Logo size="small" src={image} />}
        endIcon={open ? <ExpandMoreIcon color="secondary" /> : undefined}
        onClick={_onTriggerClick}
        fullWidth
        size="large"
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
          color: 'text.primary',
          ...(!open && {
            '& .MuiButton-startIcon': {
              margin: 0,
            },
          }),
        }}>
        {open && (
          <Typography
            noWrap
            variant="button"
            sx={{flex: 1, textAlign: 'left'}}
            textTransform="capitalize">
            {_teamsContext.current?.name}
          </Typography>
        )}
      </Button>
      <Menu
        anchorEl={_anchorEl}
        open={_isOpened}
        onClose={_onClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
        {_filteredTeams.map((team) => (
          <TeamDropwndownItem team={team} key={team._id} onClose={_onClose} />
        ))}
      </Menu>
    </>
  );
};
