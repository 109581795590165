import React from 'react';
import {FISingleSend} from '@my-game-plan/types';

import Button from '@mui/material/Button';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import OverviewCard from '@/components/overview-screen/single-sends/single-sends-card/overview-card.view';
import {useTranslation} from 'react-i18next';
import {useSingleSends} from '@/context/single-sends.context';
import {useVideo} from '@/context/video/video.context';
import SingleSendCreateButton from '../buttons/single-send-create-button.view';
import {useAuth} from '@/context/auth.context';
import {useAnalytics} from '@/context/analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

interface ISingleSendCardProps {
  data: FISingleSend;
}

function SingleSendCard(props: ISingleSendCardProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _singlesendsContext = useSingleSends();
  const _videoContext = useVideo();
  const _auth = useAuth();
  const _analyticsContext = useAnalytics();

  /*
   * Handlers
   */
  async function _playVideos(id: string) {
    if (_auth.user) {
      _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_SINGLE_SEND, {
        id: id,
        team_id: _auth.user.team,
      });
    }
    const _events = await _singlesendsContext.fetchEvents(id);
    _videoContext.openVideoPlayer(_events, false, false, {
      action: props.data.action,
    });
  }

  /*
   * Render
   */
  return (
    <OverviewCard
      data={props.data}
      primaryAction={
        <Button
          variant="outlined"
          startIcon={<PlayArrowIcon />}
          onClick={() => _playVideos(props.data._id)}>
          {`${t('video-player.button.play')} (${props.data.events.length})`}
        </Button>
      }
      secondaryAction={
        <SingleSendCreateButton
          data={props.data}
          events={props.data.events}
          variant="icon"
        />
      }
      fields={[
        [
          {
            label: t('eventAutomations.sharedWith'),
            type: 'players',
            value: props.data.shared_with_players,
          },
        ],
        [
          {
            label: t('eventAutomations.comment'),
            type: 'text',
            value: props.data.comment,
          },
        ],
      ]}></OverviewCard>
  );
}

export default SingleSendCard;
