import React, {useState} from 'react';
import {useTeams} from '@/context/team.context';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {capitalize} from '@/helpers/general/general.helper';
import EditIcon from '@mui/icons-material/Edit';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider'; // <-- Import Divider
import Dialog from '@mui/material/Dialog'; // <-- Import Dialog and related components
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {CountTagUsageBody, F, FITag} from '@my-game-plan/types';

export function TagPreferences(props: {
  tagCountData: F<CountTagUsageBody> | undefined;
}): JSX.Element {
  const _teamsContext = useTeams();
  const {t} = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [currentTag, setCurrentTag] = useState<FITag | null>(null);
  const [editedTag, setEditedTag] = useState('');

  const isEmpty = (obj: object) => Object.keys(obj).length === 0;

  const handleOpenModal = (tag: FITag) => {
    setCurrentTag(tag);
    setEditedTag(tag.label);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentTag(null);
    setEditedTag('');
  };

  return (
    <div>
      {!props.tagCountData || isEmpty(props.tagCountData) ? (
        t('preferences.tags.noTags')
      ) : (
        <List>
          {Object.entries(props.tagCountData).map(
            ([tag_id, info], index, arr) => (
              <div key={tag_id}>
                <ListItem>
                  <ListItemText
                    primary={capitalize(info.tag.label)}
                    secondary={`${info.trackersWithTagCount} ${t(
                      'eventAutomations.trackers',
                      {count: info.trackersWithTagCount},
                    )} - ${info.opponentAutomationsWithTagCount} ${t(
                      'eventAutomations.opponentAutomations',
                      {count: info.opponentAutomationsWithTagCount},
                    )}`}
                  />
                  <Box marginRight={4}>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      color={'secondary'}
                      onClick={() => handleOpenModal(info.tag)}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    color={'error'}
                    onClick={() => _teamsContext.deleteTag(info.tag)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
                {index !== arr.length - 1 && <Divider />}{' '}
                {/* Conditionally render divider except for the last item */}
              </div>
            ),
          )}
        </List>
      )}

      {/* Render the Dialog */}
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>{t('preferences.tags.edit')}</DialogTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            _teamsContext.editTag(currentTag!._id, editedTag);
            handleCloseModal();
          }}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              type="text"
              fullWidth
              value={editedTag}
              onChange={(e) => setEditedTag(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              {t('preferences.tags.cancel')}
            </Button>
            <Button type="submit" color="primary">
              {t('preferences.tags.save')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
