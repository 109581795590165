import {TDevComponent} from '@/types/dev.types';
import React from 'react';
import classes from './dev-section.module.scss';

export const DevSection: React.FC<{
  title: string;
  description?: string;
  componentList: TDevComponent[];
  showItemTitle?: boolean;
}> = (props) => {
  return (
    <div>
      <h2 className={classes.variant}>{props.title}</h2>
      <p>{props.description}</p>
      <div className={classes.devsection}>
        {props.componentList.map((item) => {
          return (
            <div key={item.title} className={classes.variants}>
              {props.showItemTitle && <h2>{item.title}</h2>}
              {item.variants.map((variant) => {
                return (
                  <div key={variant.title} className={classes.variant}>
                    <h3>{variant.title}</h3>
                    <p>{variant.component}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
