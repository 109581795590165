import {AppBar} from '@mui/material';
import {ProductHomeContainer} from '../layout/ProductHomeContainer';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ListItemIcon from '@mui/material/ListItemIcon';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {useAuth} from '@/context/auth.context';
import {useTrackers} from '@/context/event-automations/tracker.context';
import {useTeams} from '@/context/team.context';

import React, {useEffect, useState} from 'react';
import {Link, NavLink, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {ROUTE, ROUTES} from '@/config/routes.config';
import {USER_MANUAL_URL} from '@/config/links.config';
import Logo from '../common/Logo/Logo';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import SwitchTeamDialog from '../preferences/switch-team-dialog/switch-team-dialog.view';
import {FITeam, USER_ROLE} from '@my-game-plan/types';

export const AppBarView = () => {
  /*
   * Hooks n State
   */
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [_openedSubNav, _setOpenedSubNav] = useState<number>(-1);

  const [value, setValue] = React.useState(0);

  const _location = useLocation();

  const {user, logoutUser} = useAuth();
  const _trackers = useTrackers();
  const _teamsContext = useTeams();
  const {t} = useTranslation();

  const [_isPressingKey, _setIsPressingKey] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLAnchorElement>(null);
  const [_isSwitchingTeam, _setIsSwitchingTeam] = useState<boolean>(false);
  const [_isAdmin, _setIsAdmin] = useState<boolean>(false);
  const [_availableTeams, _setAvailableTeams] = useState<FITeam[]>([]);

  useEffect(() => {
    const _splittedPathName = _location.pathname.split('/');

    let _foundIndex = -1;

    if (_splittedPathName.length > 1) {
      _foundIndex = ROUTES.findIndex(
        (route) => _splittedPathName[1].indexOf(route.path) > -1,
      );
    }

    if (_foundIndex > -1) {
      setValue(_foundIndex);
    }

    handleClose();
  }, [_location.pathname]);

  useEffect(() => {
    document.addEventListener('keydown', _onKeyDown);
    document.addEventListener('keyup', _onKeyUp);

    return () => {
      document.removeEventListener('keydown', _onKeyDown);
      document.removeEventListener('keyup', _onKeyUp);
    };
  }, []);

  useEffect(() => {
    if (user) {
      _setIsAdmin(user.role === USER_ROLE.ADMIN);

      if (user.available_teams?.length) {
        _setAvailableTeams(
          user.available_teams.filter((t) => t._id !== user.team),
        );
      }
    }
  }, [user]);

  /*
   * Handlers
   */
  const handleLogout = () => {
    _trackers.clear();
    _teamsContext.clear();
    logoutUser();
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (ROUTES[newValue] && ROUTES[newValue].subroutes) {
      return;
    }

    if (!_isPressingKey) {
      setValue(newValue);
    }
  };

  const _onKeyDown = () => {
    _setIsPressingKey(true);
  };

  const _onKeyUp = () => {
    _setIsPressingKey(false);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    index: number,
  ) => {
    event.preventDefault();

    setAnchorEl(event.currentTarget);
    _setOpenedSubNav(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    _setOpenedSubNav(-1);
  };

  function _onSwitchTeamPress() {
    _setIsSwitchingTeam(true);
    setAnchorElUser(null);
  }
  function _onSwitchTeamClose() {
    _setIsSwitchingTeam(false);
  }
  /*
   * Render
   */
  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bgcolor: 'background.paper',
      }}>
      <Toolbar>
        <ProductHomeContainer />
        <Box sx={{flexGrow: 1, display: {md: 'flex'}}}>
          <Tabs value={value} onChange={handleChange}>
            {ROUTES.map((route, index) => {
              let _Icon = undefined;
              if (route.subroutes && _openedSubNav === index) {
                _Icon = (
                  <KeyboardArrowUpIcon color="secondary" fontSize="small" />
                );
              } else if (route.subroutes) {
                _Icon = (
                  <KeyboardArrowDownIcon color="secondary" fontSize="small" />
                );
              }
              return (
                <Tab
                  key={index}
                  icon={_Icon}
                  iconPosition="end"
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                    route.subroutes ? handleClick(event, index) : undefined
                  }
                  onKeyDown={_onKeyDown}
                  onKeyUp={_onKeyUp}
                  sx={{
                    '&:hover': {
                      color: 'primary.main',
                    },
                    '&.active': {
                      color: 'primary.main',
                    },
                    '&.active .MuiListItemIcon-root': {
                      color: 'primary.main',
                    },
                  }}
                  label={route.label}
                  component={NavLink}
                  to={`/${route.path}`}
                />
              );
            })}
          </Tabs>
          {ROUTES.map((route, key) => {
            const _open = Boolean(anchorEl) && _openedSubNav === key;
            return (
              <React.Fragment key={key}>
                {route.subroutes ? (
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={_open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}>
                    {route.subroutes?.map((subroute, index) => (
                      <MenuItem
                        key={index}
                        component={NavLink}
                        to={`/${route.path}/${subroute.path}`}
                        sx={{
                          '&.active': {
                            color: 'primary.main',
                            bgcolor: 'secondary.dark',
                          },
                          '&.active .MuiListItemIcon-root': {
                            color: 'primary.main',
                          },
                        }}>
                        <ListItemIcon>
                          {subroute.icon ? <subroute.icon /> : undefined}
                        </ListItemIcon>
                        {subroute.label}
                      </MenuItem>
                    ))}
                  </Menu>
                ) : null}
              </React.Fragment>
            );
          })}
        </Box>

        <Box sx={{flexGrow: 0}}>
          <Tooltip title={t('settings.open')}>
            <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
              <Badge
                overlap="circular"
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                badgeContent={
                  <Logo
                    src={_teamsContext.ownTeam?.image_url || ''}
                    size="small"
                    isBadge
                  />
                }>
                <Avatar
                  sx={{bgcolor: 'background.default', color: 'text.disabled'}}
                  alt={`${user?.firstName} ${user?.lastName}`}
                  src="/static/images/avatar/2.jpg"
                />
              </Badge>
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}>
            <Link
              onClick={handleCloseUserMenu}
              to={ROUTE.preferences}
              style={{textDecoration: 'none', color: 'inherit'}}>
              <MenuItem>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <Typography textAlign="center">
                  {t('settings.preferences')}
                </Typography>
              </MenuItem>
            </Link>
            <Link
              onClick={handleCloseUserMenu}
              to={ROUTE.uploads}
              style={{textDecoration: 'none', color: 'inherit'}}>
              <MenuItem>
                <ListItemIcon>
                  <VideoLibraryIcon />
                </ListItemIcon>
                <Typography textAlign="center">{t('uploads.title')}</Typography>
              </MenuItem>
            </Link>
            <MenuItem
              component="a"
              href={USER_MANUAL_URL}
              target="_blank"
              rel="noopener">
              <ListItemIcon>
                <MenuBookIcon />
              </ListItemIcon>
              <Typography textAlign="center">
                {t('settings.user_manual')}
              </Typography>
            </MenuItem>
            {(_isAdmin || _availableTeams.length > 0) && (
              <MenuItem onClick={_onSwitchTeamPress}>
                <ListItemIcon>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <Typography textAlign="center">
                  {t('settings.switch_team')}
                </Typography>
              </MenuItem>
            )}
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <Typography textAlign="center">
                {t('settings.log_out')}
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
      <SwitchTeamDialog
        open={_isSwitchingTeam}
        available_teams={_availableTeams}
        onClose={_onSwitchTeamClose}
      />
    </AppBar>
  );
};
