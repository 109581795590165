const EVENT_AUTOMATIONS_TRANSLATIONS = {
  createdAt: 'Created {{date}}',
  updatedAt: 'last updated {{date}}',
  sharedWith: 'Shared with',
  comment: 'Comment',
  action: 'Action',
  details: 'Details',
  successDetails: 'Success details',
  by: 'By',
  general: 'General',
  description: 'Description',
  title: 'Title',

  matchLimit: {
    matches: 'Matches',
    last: 'Last {{count}}',
  },

  allPlayers: 'All players',
  entireTeam: 'Entire team',

  notify: {
    label: 'Notify players',
    daysBefore: '{{count}} day before match',
    daysBefore_one: '{{count}} day before match',
    daysBefore_other: '{{count}} days before match',
  },

  automations_one: 'Automation',
  automations_other: 'Automations',
  automationsNew: 'New automation',
  automationsEdit: 'Edit automation',

  trackersShort_one: 'Tracker',
  trackersShort_other: 'Trackers',
  trackers_one: 'Performance tracker',
  trackers_other: 'Performance trackers',
  trackersNew: 'New tracker',
  trackersEdit: 'Edit tracker',

  opponentAutomationsShort_one: 'Automation',
  opponentAutomationsShort_other: 'Automations',
  opponentAutomations_one: 'Opponent automation',
  opponentAutomations_other: 'Opponent automations',
  opponentAutomationsNew: 'New opponent automation',
  opponentAutomationsEdit: 'Edit opponent automation',
  opponentAutomationsIntro:
    "Schedule a weekly playlist for next opponent's actions",

  viewCta: 'View {{subject}}',

  calculationType: {
    occurrences: 'Count occurrences',
    ratio: 'Calculate ratio',
    rule: 'Define a rule',
    description: {
      occurrences: 'Counts how often (#) a certain action occurs in a match.',
      ratio: 'Tracks how often (%) an event meets certain criteria.',
      rule: 'Tracks how often (%) an event happens under specific conditions.',
    },
  },

  // TODO: delete target copy
  target: {
    withTarget: 'With  target',
    noTarget: 'Without target',
  },
  properties: {
    average: 'Average',
    tags: 'Tags',
    lastMatch: 'Last match',
    score: 'Score',
    videos: 'Videos',
    minutesPlayed: 'Min. played',
    matchesPlayed: 'Matches played',
    totalEvents: 'Total events',
    successfulEvents: 'Objective/total',
    objective: 'Objective',
    totalSum: 'Total {{metric}}',
    averagePerEvent: 'Average / event',
  },

  values: {
    notAvailable: 'n/a',
    perMatch: '/match',
    per90: "/90'",
    noTags: 'no tags',
  },

  benchmark: {
    benchmark: 'Benchmark',

    update: 'Update benchmark',
    error: {
      default: {
        title: 'Something went wrong loading benchmark data',
        description: 'Please try again later',
      },
      'no-data': {
        title: 'No data to benchmark.',
        description: 'Try changing benchmark filters or tracker details.',
      },
    },

    competitions_one: 'competition',
    competitions_other: 'competitions',
    competitionsCount_one: '{{count}} competition',
    competitionsCount_other: '{{count}} competitions',
    notAvailable: 'No benchmark available',
    positionAverage: '{{position}} average',
    positionAverageShort: '{{position}} avg.',
    leagueAverage: 'league average',
    leagueAverageShort: 'league avg.',

    belowBenchmark: 'below benchmark',
    aboveBenchmark: 'above benchmark',

    belowBenchmarkProp: 'below {{benchmark}}',
    aboveBenchmarkProp: 'above {{benchmark}}',

    benchmarkAgainst: 'Benchmarking',

    restore: 'Restore defaults',

    ranking: {
      title: 'Ranking',

      player: 'Player',
      team: 'Team',
      notInRanking: 'not in ranking',
      successfulEvents: 'Obj./Total',
      totalEvents: 'Events',
      averageRate: 'Average',
      averageShort: 'Avg.',
      averagePerMatch: 'Avg./match',
      averagePer90: "Avg./90'",
      matchesPlayed: 'Matches',
      minutesPlayed: 'Min. played',
      minutesPlayedShort: 'Min.',
      fullRankingCta: 'Full ranking',
    },

    disabled: {
      disabled: 'Not benchmarking',
      player_details:
        'Benchmarking is disabled for automations with player-specific details. Try removing these to allow benchmarking.',
      tracking_data: 'Benchmarking is disabled for positioning automations',
      passes_no_details:
        'Benchmarking is disabled for automations combining passes with under 2 details and conditions',
    },
    baseline: {
      benchmarkAgainst: 'Benchmarking',
      compareTo: 'Compare to',
      otherTeams: 'other teams',
      otherPlayers: 'other players',
    },
    playingIn: 'playing in',

    average: 'average',
    average_90: "average per 90'",

    belowAverage: 'below average',
    aboveAverage: 'above average',

    in: 'in',
    of: 'of',
    all: 'all',
    ownLeague: 'own league',
    teams: 'teams',
    players: {
      placeholder: 'all players',
    },

    league: 'league',
    yourTeam: 'Your team',

    whoPlayedAtLeast: 'who played at least',
    minutes: 'minutes',
    atLeast: 'at least',
  },

  error: {
    no_group: {
      title: 'No groups to show',
      description:
        'Try creating an automation observing specific players/positions.',
    },
  },

  noTags: 'No tags',

  nextOpponent: 'Next opponent',
  noUpcomingOpponent: 'No upcoming opponent',

  filter: {
    filter: 'Filter',

    automationsFilter: 'Filter automations',
    trackersFilter: 'Filter trackers',

    actions: 'Actions',
    tags: 'Tags',
    players: 'Observing players',

    allActions: 'All actions',
    allTags: 'All tags',
    allPlayers: 'All players (+ team)',
    entireTeam: 'Entire team',

    view: 'View',
    viewType: {
      table: 'Table',
      cards: 'Cards',
    },
  },

  empty: {
    trackers: {
      header: "Couldn't find any trackers.",
      description:
        'You can create a performance tracker by clicking the button below.',
    },
    opponentAutomations: {
      header: "Couldn't find any opponent automations.",
      description:
        'You can create an opponent automations by clicking the button below.',
    },
    app: {
      header: 'No data available',
      description: 'Ask your staff to share content.',
    },
  },

  create: {
    save: 'Create {{type}}',
    title: 'Create {{type}}',
    titleCustom: 'Create custom {{type}}',
    cancelWarning: {
      title: 'Discard changes?',
      text: 'Would you like to cancel and discard changes? Unsaved changes might be lost.',
      cta: 'Discard changes',
    },
    success: '{{type}} has been created',
    error: {
      request: {
        title: "Couldn't save {{type}}",
        text: 'Seems like something went wrong here. Please try again later',
      },
    },
  },
  edit: {
    save: 'Save {{type}}',
    edit: 'Edit {{type}}',
    success: {
      title: '{{type}} has been updated!',
    },
  },
  delete: {
    success: '{{type}} has been deleted',
    error: {
      title: 'Something went wrong trying to delete {{type}}',
      text: 'Please try again later',
    },
    title: 'Delete {{type}}',
    message:
      "Are you sure you want to delete this {{type}}? This can't be undone",
  },

  examples: {
    title: 'Calculation type differences',
    description: 'Description',
    examples: 'Examples',
    useTemplate: 'Use template',
    explanationText:
      'Choose an example and customize as you like, or start from scratch by creating your own custom automation.',
  },

  automationNotFound:
    "The {{type}} you are trying to find doesn't seem to exist!",
  noData: 'No data available',
  noVideos: 'No videos to display',

  matchFilter: {
    showDataFor: 'Show data for',
    allMatches: 'all matches',
    last5Matches: 'last 5 matches',
    lastMatch: 'last match',
    selectedMatches: '{{count}} selected  matches',
    matches: 'Matches',
    matchesCount_one: '{{count}} match',
    matchesCount_other: '{{count}} matches',
    of: 'of',
    selectedCount_one: '{{selected}}/{{count}} match',
    selectedCount_other: '{{selected}}/{{count}} matches',

    competitionsCount_one: '{{count}} competition',
    competitionsCount_other: '{{count}} competitions',
  },
  transferredPlayerWarning:
    'This tracker references a player that is no longer on your squad. We recommend replacing this player or deleting this tracker.',

  trend: {
    noData: 'No data available',
    showingMatchday: 'Showing matchday {{matchday1}}-{{matchday2}}',
  },
  matchday: 'Matchday {{day}}',
  footer: {
    createdAt: 'Created on {{date}}',
    created: 'Created by {{name}} on {{date}}',
    update: 'Last updated on {{date}}',
  },
  displayObjectiveOnly: 'Only show events matching objective',

  pitchVisual: {
    successfulToggle: {
      ratio: 'Highlight events matching success details',
      rule: 'Highlight events matching rule conditions',
    },
  },

  appendHistory: {
    cta: 'Click to load matches',
  },
};

export default EVENT_AUTOMATIONS_TRANSLATIONS;
