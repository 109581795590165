import React, {useState} from 'react';

import Button from '@mui/material/Button';
import {IconButton} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import ShareIcon from '@mui/icons-material/Share';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {DevSection} from './dev-section';
import {TDevComponent} from '@/types/dev.types';
import Switch from '@mui/material/Switch';

const label = {inputProps: {'aria-label': 'Switch demo'}};

export const DevSectionButtonsView = () => {
  const [toggleIsOn, setToggleIsOn] = useState(true);

  const toggleHandler = () => {
    setToggleIsOn(!toggleIsOn);
  };

  const buttons: TDevComponent[] = [
    {
      title: 'Contained',
      variants: [
        {
          title: 'Regular',
          component: <Button variant="contained">Button label</Button>,
        },
        {
          title: 'Icon after',
          component: (
            <Button variant="contained" endIcon={<AddIcon />}>
              Button label
            </Button>
          ),
        },
        {
          title: 'Icon before',
          component: (
            <Button variant="contained" startIcon={<AddIcon />}>
              Button label
            </Button>
          ),
        },
      ],
    },

    {
      title: 'Outlined',
      variants: [
        {
          title: 'Regular',
          component: <Button variant="outlined">Button label</Button>,
        },
        {
          title: 'Icon after',
          component: (
            <Button variant="outlined" endIcon={<KeyboardArrowDownIcon />}>
              Button label
            </Button>
          ),
        },
        {
          title: 'Icon before',
          component: (
            <Button variant="outlined" startIcon={<KeyboardArrowDownIcon />}>
              Button label
            </Button>
          ),
        },
        {
          title: 'Error',
          component: (
            <Button variant="outlined" color="error" endIcon={<DeleteIcon />}>
              Delete tracker
            </Button>
          ),
        },
      ],
    },
    {
      title: 'Icon only',
      variants: [
        {
          title: 'Create/Edit',
          component: (
            <IconButton color="primary">
              <CreateIcon />
            </IconButton>
          ),
        },
        {
          title: 'Share',
          component: (
            <IconButton color="primary">
              <ShareIcon />
            </IconButton>
          ),
        },
      ],
    },
    {
      title: 'Other',
      variants: [
        {
          title: 'Add condition',
          component: (
            <Button startIcon={<AddCircleIcon />}>Add condition</Button>
          ),
        },
        {
          title: 'Toggle',
          component: (
            <>
              <Switch {...label} onClick={toggleHandler} />
              &nbsp; {toggleIsOn ? <p>Without target</p> : <p>Set a target</p>}
            </>
          ),
        },
      ],
    },
  ];

  return (
    <div>
      <DevSection title="Button" componentList={buttons} showItemTitle={true} />
    </div>
  );
};
