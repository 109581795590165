import {
  ACTION,
  FICompetition,
  FIEventAutomationPostData,
  FMatch,
  TEventCalculationType,
} from '@my-game-plan/types';
import {ReactNode} from 'react';

import {IFilterOption, TIcon} from './filter.types';
import LabelIcon from '@mui/icons-material/Label';
import PersonIcon from '@mui/icons-material/Person';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import i18n from '@/localization';

export interface ITrackerCalculationTypeInfo {
  calculation: TEventCalculationType;
  description: string;
  examples: Partial<FIEventAutomationPostData>[];
}

export interface IEventAutomationExampleInfo {
  section: string;
  examples: Partial<FIEventAutomationPostData>[];
}

export interface IExampleDummyAutomations {
  _id: string;
  example: ReactNode;
  calculation?: string;
}

/* Filters */
export interface IAutomationFilters {
  tags?: string[];
  actions?: ACTION[];
  players?: string[];
}

export type TFilterOptions = Record<keyof IAutomationFilters, IFilterOption[]>;

export interface IFilterConfig {
  label: string;
  icon: TIcon;
}
export const AUTOMATIONS_FILTERS_CONFIG: Record<
  keyof IAutomationFilters,
  IFilterConfig
> = {
  tags: {
    label: i18n.t('eventAutomations.filter.tags'),
    icon: LabelIcon,
  },
  actions: {
    label: i18n.t('eventAutomations.filter.actions'),
    icon: SportsSoccerIcon,
  },
  players: {
    label: i18n.t('eventAutomations.filter.players'),
    icon: PersonIcon,
  },
};

export interface IAutomationSeasonInfo {
  seasonId: number;
  seasonLabel: string;
  domesticCompetition?: FICompetition;
  // competitionSeasons: FICompetitionSeason[];
  competitions: FICompetition[];
  // loading_state: LOADING_STATE;
  matches: FMatch[];
}

export interface ISelectedMatchesInfo {
  total: number;
  selected: number;
}
