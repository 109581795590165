import cn from 'classnames';
import {ROUTES, SHOT_ANALYSIS_ROUTES} from '@/config/routes.config';
import ShotAnalysis from '@/views/shot-analysis.view';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';
import React from 'react';
import {NavLink, Navigate, Route, Routes, useLocation} from 'react-router-dom';

import styles from './page.module.scss';
import {useTranslation} from 'react-i18next';
import Button from '@mui/material/Button';

function ShotAnalysisRoutes(): JSX.Element {
  /*
   * Hooks n State
   */
  const _location = useLocation();
  const {t} = useTranslation();

  /*
   * Handlers
   */

  /*
   * Render
   */
  const _shotRoutes = ROUTES[3];
  return (
    <div className={styles.overview}>
      <div className={cn(styles.header, styles.headerWithBorder)}>
        <div className={styles.topRow}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>{t('shotAnalysis.title')}</div>
          </div>
        </div>
        <div className={styles.bottomRow}>
          <Stack direction="row" justifyContent="space-between">
            <Tabs value={_location.pathname.includes('conceded') ? 1 : 0}>
              {_shotRoutes.subroutes?.map((route) => (
                <Tab
                  key={route.label}
                  label={route.label}
                  component={NavLink}
                  to={`/${_shotRoutes.path}/${route.path}`}
                />
              ))}
            </Tabs>
            <Button
              color="secondary"
              sx={{textTransform: 'none'}}
              disabled
              size="small">
              {`${t('shotAnalysis.benchmark.benchmarkingAgainst')} ${t(
                'shotAnalysis.benchmark.league',
              )}`}
            </Button>
          </Stack>
        </div>
      </div>
      <div className={styles.content}>
        <Routes>
          <Route
            path=""
            element={<Navigate to={SHOT_ANALYSIS_ROUTES.shots_created} />}
          />
          <Route
            path={SHOT_ANALYSIS_ROUTES.shots_created}
            element={<ShotAnalysis type="created" />}
          />
          <Route
            path={SHOT_ANALYSIS_ROUTES.shots_conceded}
            element={<ShotAnalysis type="conceded" />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default ShotAnalysisRoutes;
