import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import {usePlayerDetail} from '@/context/player-detail.context';
import {LOADING_STATE} from '@/types/screen.types';
import {SxProps} from '@mui/material';
import {Theme} from '@mui/system';
import {useTranslation} from 'react-i18next';
import Stat, {IStatProps} from '../../common/stat/stat.view';
import {
  isNullOrUndefined,
  toPercentage,
} from '@/helpers/general/general.helper';
import moment from 'moment';
import Indicator from '../../dashboard/Indicator/Indicator';
import PlayerDetailHeaderActions from './player-detail-header-actions.view';

const AVATAR_SIZE = 64;

const avatarStyle: SxProps<Theme> = {
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
};

function PlayerDetailHeader() {
  /*
   * Hooks n State
   */
  const [_stats, _setStats] = useState<IStatProps[][]>([]);
  const _playerDetailContext = usePlayerDetail();
  const {t} = useTranslation();

  // Set stats on player load
  useEffect(() => {
    if (!_playerDetailContext.player) {
      _setStats([]);
      return;
    }

    const _notAvailableText = t('players.info.notAvailable');

    const _basicStats: IStatProps[] = [
      {
        label: t('players.info.minutesPlayed'),
        value: _playerDetailContext.player?.minutes_played.minutes_played || 0,
        valueSuffix: toPercentage(
          _playerDetailContext.player?.minutes_played?.percentage_played || 0,
        ),
      },
    ];
    if (!_playerDetailContext.player.position.includes('GK')) {
      _basicStats.unshift({
        label: t('players.info.ga'),
        value: `${_playerDetailContext.player?.stats?.goals || 0}/${
          _playerDetailContext.player?.stats?.assists || 0
        }`,
      });
    }

    const _age = moment().diff(_playerDetailContext.player.birth_date, 'years');
    const _personalStats: IStatProps[] = [
      {
        label: t('players.info.age'),
        value: _age,
      },
      {
        label: t('players.info.height'),
        value: _playerDetailContext.player.height
          ? `${_playerDetailContext.player.height}cm`
          : _notAvailableText,
      },
      {
        label: t('players.info.weight'),
        value: _playerDetailContext.player.height
          ? `${_playerDetailContext.player.weight}kg`
          : _notAvailableText,
      },
      ...(_playerDetailContext.player.preferred_foot
        ? [
            {
              label: t('players.info.foot'),
              value: _playerDetailContext.player.preferred_foot,
            },
          ]
        : []),
      {
        label: t('players.info.position'),
        value: _playerDetailContext.player.position[0],
      },
    ];
    const _newStats: IStatProps[][] = [_basicStats, _personalStats];
    // _newStats.push(_basicStats);
    _setStats(_newStats);
  }, [_playerDetailContext.player]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  let _Content = null;

  if (
    _playerDetailContext.playerLoadingState === LOADING_STATE.SUCCESS &&
    _playerDetailContext.player
  ) {
    let _BadgeAvatarContent = null;

    let _OverallScoreContent = null;
    if (
      _playerDetailContext.performanceLoadingState === LOADING_STATE.SUCCESS &&
      !isNullOrUndefined(
        _playerDetailContext.performanceStats?.benchmarked_score,
      )
    ) {
      _OverallScoreContent = (
        <Stack gap={0.5}>
          {/* <Typography variant="caption" color="secondary">
            {t('players.benchmark.overallPosBenchmark', {
              pos: _playerDetailContext.player.position[0],
            })}
          </Typography> */}
          <Box width={'12vw'} pt={1}>
            <Indicator
              value={_playerDetailContext.performanceStats?.benchmarked_score}
              bigValue
              coloredValue
            />
          </Box>
        </Stack>
      );
    }

    if (_playerDetailContext.player.country_code) {
      _BadgeAvatarContent = (
        <Box
          position="absolute"
          right={0}
          bottom={0}
          width={20}
          overflow="hidden"
          boxShadow={1}
          borderRadius={0.5}>
          <img
            style={{maxWidth: '100%', display: 'block'}}
            src={`${
              process.env.REACT_APP_ASSETS_URL
            }/flags/${_playerDetailContext.player.country_code.toLowerCase()}.png`}
          />
        </Box>
      );
    }
    _Content = (
      <>
        {/* Basic player info */}
        <Stack direction="row" gap={2} alignItems="center" position="relative">
          <Box position="relative">
            <Avatar
              sx={{
                ...avatarStyle,
                borderColor: 'secondary.main',
                borderWidth: 3,
                borderStyle: 'solid',
                bgcolor: 'secondary.light',
              }}
              src={_playerDetailContext.player.image_url}
            />
            {_BadgeAvatarContent}
          </Box>
          <Stack gap={1}>
            {Boolean(_playerDetailContext.player.first_name) && (
              <Typography fontWeight={400} lineHeight={1}>
                {_playerDetailContext.player.first_name}
              </Typography>
            )}
            <Typography fontWeight={600} fontSize={24} lineHeight={1}>
              {_playerDetailContext.player.last_name ||
                _playerDetailContext.player.display_name}
            </Typography>
          </Stack>
          <Typography
            lineHeight={1}
            fontSize={64}
            color="secondary.main"
            fontWeight={900}
            sx={{opacity: 0.2}}
            position={'absolute'}
            right={-24}
            top={0}>
            {_playerDetailContext.player.jersey_num}
          </Typography>
        </Stack>

        {/* Stats */}
        <Stack direction="row" gap={4}>
          <Stack
            direction="row"
            gap={6}
            sx={{display: {xs: 'none', lg: 'flex'}}}>
            {_stats.map((statGroup, groupIndex) => {
              return (
                <React.Fragment key={groupIndex}>
                  {statGroup.map((stat) => {
                    return (
                      <React.Fragment key={stat.label}>
                        <Stat {...stat} />
                      </React.Fragment>
                    );
                  })}
                  <Divider orientation="vertical" flexItem />
                </React.Fragment>
              );
            })}
          </Stack>
          {/* Overall score */}
          {_OverallScoreContent}

          {/* More actions */}
          <PlayerDetailHeaderActions />
        </Stack>
      </>
    );
  } else if (
    _playerDetailContext.playerLoadingState === LOADING_STATE.LOADING
  ) {
    _Content = (
      <>
        <Stack direction="row" gap={2}>
          <Skeleton variant="circular" sx={avatarStyle} />
          <Skeleton variant="text" sx={{width: 120}} />
        </Stack>
      </>
    );
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%">
      {_Content}
    </Stack>
  );
}

export default PlayerDetailHeader;
