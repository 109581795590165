const GENERAL_TRANSLATIONS = {
  back: 'Back',
  cancel: 'Cancel',
  confirm: 'Confirm',
  delete: 'Delete',
  remove: 'Remove',
  create: 'Create',
  add: 'Add',
  edit: 'Edit',
  share: 'Share',
  sort: 'Sort',
  save: 'Save',
  contact: 'Contact us',
  close: 'Close',
  update: 'Update',
  discard: 'Discard changes',
  moreInfo: 'More information',
  gotIt: 'Got it',
};

export default GENERAL_TRANSLATIONS;
