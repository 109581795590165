import React, {useState} from 'react';

import {TDevComponent} from '@/types/dev.types';
import {DevSection} from './dev-section';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';

interface IOption {
  label: string;
  value: string;
}

export const DevSectionInputDropdownView = () => {
  const [age, setAge] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [value, setValue] = React.useState('option 1');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDropdownChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  const open = Boolean(anchorEl);

  const dummyTeams = [
    {name: 'AS Roma', country: 'Italy'},
    {name: 'AC Milan', country: 'Italy'},
    {name: 'Chelsea', country: 'Britain'},
    {name: 'Arsenal', country: 'Britain'},
    {name: 'FC Barcelona', country: 'Spain'},
    {name: 'Real Madrid', country: 'Spain'},
    {name: 'Lazio FC', country: 'Italy'},
  ];

  const optionsGrouped = dummyTeams.map((option) => {
    const countryName = option.country.toUpperCase();
    return {
      countryName: /[0-9]/.test(countryName) ? '0-9' : countryName,
      ...option,
    };
  });

  const options: IOption[] = [
    {
      label: 'Option 1',
      value: 'option1',
    },
    {
      label: 'Option 2',
      value: 'option2',
    },
    {
      label: 'Option 3',
      value: 'option3',
    },
  ];

  const inputAndDropdown: TDevComponent[] = [
    {
      title: 'Dropdown',
      variants: [
        {
          title: 'Regular',
          component: (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Age</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Age"
                onChange={handleDropdownChange}>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          ),
        },
        {
          title: 'Searchable',
          component: (
            <Autocomplete
              options={options}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type or select option"
                  variant="standard"
                />
              )}
            />
          ),
        },
        {
          title: 'Searchable with grouped items',
          component: (
            <Autocomplete
              options={optionsGrouped.sort(
                (a, b) => -b.countryName.localeCompare(a.countryName),
              )}
              groupBy={(optionGrouped) => optionGrouped.countryName}
              getOptionLabel={(optionGrouped) => optionGrouped.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type or select option"
                  variant="standard"
                />
              )}
            />
          ),
        },
        {
          title: 'Searchable with multiselect',
          component: (
            <Autocomplete
              multiple
              id="tags-standard"
              options={dummyTeams}
              getOptionLabel={(option) => option.name}
              defaultValue={[dummyTeams[4]]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Multiple values"
                  placeholder="Favorite teams"
                />
              )}
            />
          ),
        },
        {
          title: 'Menu',
          component: (
            <>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>
                Open menu
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}>
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem>
              </Menu>
            </>
          ),
        },
      ],
    },
    {
      title: 'Text input',
      variants: [
        {
          title: 'Text field',
          component: (
            <TextField
              id="standard-basic"
              label="Standard"
              variant="standard"
            />
          ),
        },
        {
          title: 'Text area',
          component: (
            <TextField
              id="outlined-multiline-static"
              label="Multiline"
              multiline
              rows={4}
              defaultValue="Default Value"
            />
          ),
        },
      ],
    },
    {
      title: 'Others',
      variants: [
        {
          title: 'Checkbox',
          component: (
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Label"
              />
              <FormControlLabel
                disabled
                control={<Checkbox />}
                label="Disabled"
              />
            </FormGroup>
          ),
        },
        {
          title: 'Radio group',
          component: (
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Select option
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleRadioChange}>
                <FormControlLabel
                  value="option 1"
                  control={<Radio />}
                  label="Option 1"
                />
                <FormControlLabel
                  value="option 2"
                  control={<Radio />}
                  label="Option 2"
                />
              </RadioGroup>
            </FormControl>
          ),
        },
      ],
    },
  ];

  return (
    <DevSection
      title="Input + dropdowns"
      showItemTitle={true}
      componentList={inputAndDropdown}
    />
  );
};
