import React, {PropsWithChildren} from 'react';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppAPI {}

const context = createCustomContext<AppAPI>();
export const useApp = createContextHook(context);

export const AppContextProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  return createProvider(context, props, {});
};
