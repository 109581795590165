import React from 'react';

import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import {useTranslation} from 'react-i18next';
import {IFileMetadata} from '@my-game-plan/types';
import {SxProps} from '@mui/material';
import {Theme, alpha} from '@mui/system';
import {useSnackbar} from 'notistack';

interface IFileUploadInputProps {
  onChange: (file: File) => void;
  error?: boolean;
  value?: IFileMetadata | File;
  uploadProgress?: number;
}

export const SUPPORTED_FILE_TYPES = [
  'application/pdf',
  'video/mp4',
  'video/quicktime',
];

function FileUploadInput(props: IFileUploadInputProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _snackbar = useSnackbar();

  /*
   * Handlers
   */
  function _validateFile(file: File): boolean {
    let _valid = true;

    if (!SUPPORTED_FILE_TYPES.includes(file.type)) {
      _valid = false;
    }
    return _valid;
  }
  function _onFileSelect(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      props.onChange(e.target.files[0]);
    }
  }

  function _onDragOver(event: React.DragEvent<HTMLDivElement>) {
    event.stopPropagation();
    event.preventDefault();
  }

  function _onFileDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();

    if (event.dataTransfer.files.length) {
      const _isValid = _validateFile(event.dataTransfer.files[0]);
      if (_isValid) {
        props.onChange(event.dataTransfer.files[0]);
        return;
      }
    }

    _snackbar.enqueueSnackbar(t('uploads.uploadForm.file.invalid'), {
      variant: 'error',
    });
  }

  /*
   * Render
   */

  const _containerStyle: SxProps<Theme> = {
    // bgcolor: 'secondary.light',
    bgcolor: (theme) => alpha(theme.palette.secondary.main, 0.3),
    // borderColor: 'secondary.main',
    borderColor: 'transparent',
    borderRadius: 2,
    borderWidth: 1,
    borderStyle: 'solid',
    height: 180,
    boxSizing: 'border-box',
  };

  if (props.error) {
    // _containerStyle.bgcolor = (theme) => alpha(theme.palette.error.main, 0.3);
    _containerStyle.borderColor = 'error.main';
  }

  let _Content = (
    <Stack gap={2} alignItems="center" justifyContent="center">
      <CloudUploadIcon fontSize="large" color="secondary" />
      <Typography color="text.secondary" variant="body2">
        {t('uploads.uploadForm.file.dragAndDrop')}
      </Typography>
    </Stack>
  );
  if (props.value) {
    const _Icon = props.value.type.includes('video')
      ? VideoFileIcon
      : InsertDriveFileIcon;
    _Content = (
      <Stack gap={2} alignItems="center" justifyContent="center">
        <_Icon fontSize="large" color="primary" />
        <Typography variant="body2">{props.value.name}</Typography>
      </Stack>
    );
  }
  return (
    <Stack gap={2}>
      <Stack
        gap={2}
        alignItems="center"
        justifyContent="center"
        p={4}
        position="relative"
        sx={_containerStyle}
        onDrop={_onFileDrop}
        onDragOver={_onDragOver}>
        {_Content}
        <Button
          component="label"
          role={undefined}
          variant="outlined"
          color="primary"
          tabIndex={-1}
          size="small">
          {t('uploads.uploadForm.file.browse')}
          <input
            accept={SUPPORTED_FILE_TYPES.join(',')}
            type="file"
            hidden
            onChange={_onFileSelect}
            onDrop={_onFileDrop}
          />
        </Button>
        {/* Progress overlay */}
        {Boolean(props.uploadProgress) && (
          <Stack
            borderRadius={2}
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            alignItems="center"
            justifyContent="center"
            bgcolor={(theme) => alpha(theme.palette.background.default, 0.9)}
            gap={2}>
            <Typography variant="caption" color="text.secondary">
              {t('uploads.uploadForm.uploading')}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={props.uploadProgress}
              sx={{borderRadius: 2, width: 240}}
            />
            <Typography variant="caption" color="text.secondary">
              {props.uploadProgress}%
            </Typography>
          </Stack>
        )}
      </Stack>
      <Typography variant="caption" color="text.disabled" textAlign="center">
        {t('uploads.uploadForm.file.allowedFiles')}
      </Typography>
    </Stack>
  );
}

export default FileUploadInput;
