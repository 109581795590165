const NAVIGATION_TRANSLATIONS = {
  dashboards: 'Dashboards',
  playlists: 'Video playlists',
  trackers: 'Performance trackers',
  'single-sends': 'Single sends',
  'opponent-automations': 'Opponent automations',
  shots: 'Shot analysis',
  shots_created: 'Created shots',
  shots_conceded: 'Conceded shots',
};
export default NAVIGATION_TRANSLATIONS;
