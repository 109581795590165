import React, {useState} from 'react';
import Box from '@mui/material/Box';
import preferencesStyles from '@/components/preferences/preferences.module.scss';
import Stack from '@mui/material/Stack';
import {InputAdornment} from '@mui/material';
import TextField from '@mui/material/TextField';
import {useTranslation} from 'react-i18next';
import Button from '@mui/material/Button';
import {useTeams} from '@/context/team.context';
import {updateRunPreferences} from '@/controllers/teams.controller';
import {useSnackbar} from 'notistack';
import Typography from '@mui/material/Typography';
import {
  ACTION,
  ActionPreferences,
  defaultPreferenceValues,
  IEventAutomationsUpdateAllQueryParams,
  RunPreference,
  RunPreferenceMinFactor,
} from '@my-game-plan/types';
import {translateAction} from '@/helpers/translation.helper';
import {capitalize} from 'lodash';
import {updateDataForAutomations} from '@/controllers/event-automations.controller';

export function RunThresholdItem(props: {run_type: ACTION}): JSX.Element {
  const {t} = useTranslation();
  const {ownTeam, setOwnTeam} = useTeams();

  const defaultValues = defaultPreferenceValues[props.run_type]!;
  let startValues = getStartValues();

  const [speed, setSpeed] = useState(startValues.speed);
  const [distance, setDistance] = useState(startValues.distance);
  const [duration, setDuration] = useState(startValues.duration);

  const snackbar = useSnackbar();

  const isChanged = () => {
    return (
      speed !== startValues.speed ||
      distance !== startValues.distance ||
      duration !== startValues.duration
    );
  };

  function createRunPreferenceFromInputs() {
    const preferencesToSave: RunPreference = {};

    if (speed !== startValues.speed) preferencesToSave.speed = speed;
    if (distance !== startValues.distance)
      preferencesToSave.distance = distance;
    if (duration !== startValues.duration)
      preferencesToSave.duration = duration;
    return preferencesToSave;
  }

  function createErrorMessage(invalidKeys: (keyof RunPreference)[]) {
    let errorMessage = '';
    for (const invalidField of invalidKeys) {
      errorMessage += `${t(`preferences.run_thresholds.${invalidField}`)} ${t(
        'preferences.run_thresholds.higher_than',
      )} ${(defaultValues[invalidField] * RunPreferenceMinFactor).toFixed(
        2,
      )}\n`;
    }
    snackbar.enqueueSnackbar(errorMessage, {variant: 'error'});
  }

  async function saveThresholds() {
    if (!ownTeam) return;
    const preferencesToSave = createRunPreferenceFromInputs();
    const invalidKeys = getInvalidFields(preferencesToSave);

    if (invalidKeys.length > 0) {
      createErrorMessage(invalidKeys);
      return;
    }

    try {
      await updateRunPreferences(
        ownTeam._id,
        props.run_type,
        preferencesToSave,
      );

      const newPreferences: ActionPreferences = ownTeam.preferences?.action
        ? {...ownTeam.preferences?.action}
        : {};
      newPreferences[props.run_type] = {
        ...newPreferences[props.run_type],
        ...preferencesToSave,
      };

      setOwnTeam({
        ...ownTeam,
        preferences: {
          ...ownTeam.preferences,
          action: newPreferences,
        },
      });

      /* Update data for automations */
      const _params: IEventAutomationsUpdateAllQueryParams = {
        actions: [props.run_type],
      };
      await updateDataForAutomations(
        'opponent-automation',
        ownTeam._id,
        _params,
      );
      await updateDataForAutomations('tracker', ownTeam._id, _params);

      snackbar.enqueueSnackbar(
        `${capitalize(translateAction(props.run_type))} ${t(
          'preferences.run_thresholds.snackbar_success',
        )}`,
        {
          variant: 'success',
        },
      );
      startValues = getStartValues(); // used to disable the button
    } catch (e) {
      snackbar.enqueueSnackbar(t('preferences.run_thresholds.snackbar_fail'), {
        variant: 'error',
      });
    }
  }

  function getStartValues(): RunPreference {
    return {
      speed:
        ownTeam?.preferences?.action?.[props.run_type]?.speed ||
        defaultValues.speed,
      distance:
        ownTeam?.preferences?.action?.[props.run_type]?.distance ||
        defaultValues.distance,
      duration:
        ownTeam?.preferences?.action?.[props.run_type]?.duration ||
        defaultValues.duration,
    };
  }

  function getInvalidFields(preferences: RunPreference) {
    const invalidRunPreferences: (keyof RunPreference)[] = [];
    for (const [preferenceKey, preferenceValue] of Object.entries(
      preferences,
    )) {
      const typed_key = preferenceKey as keyof RunPreference;
      if (preferenceValue < RunPreferenceMinFactor * defaultValues[typed_key])
        invalidRunPreferences.push(typed_key);
    }
    return invalidRunPreferences;
  }

  return (
    <>
      <Box
        className={preferencesStyles.run_thresholds_container}
        sx={{pl: 2, mb: 2, py: 1}}>
        <Typography variant="h6" sx={{pb: 2}}>
          {capitalize(translateAction(props.run_type, 2))}
        </Typography>{' '}
        <Stack direction="row" spacing={2}>
          <TextField
            label={t('preferences.run_thresholds.speed')}
            defaultValue={speed}
            onChange={(event) =>
              setSpeed(parseFloat(event.target.value) || startValues.speed)
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">m/s</InputAdornment>,
            }}
            type={'number'}
            helperText={`${t('preferences.run_thresholds.on_average')} ${
              defaultValues.speed
            }`}
          />

          <TextField
            label={t('preferences.run_thresholds.distance')}
            defaultValue={distance}
            type="number"
            onChange={(event) =>
              setDistance(
                parseFloat(event.target.value) || startValues.distance,
              )
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">m</InputAdornment>,
            }}
            helperText={`${t('preferences.run_thresholds.on_average')} ${
              defaultValues.distance
            }`}
          />

          <TextField
            label={t('preferences.run_thresholds.duration')}
            defaultValue={duration}
            type="number"
            onChange={(event) =>
              setDuration(
                parseFloat(event.target.value) || startValues.duration,
              )
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">s</InputAdornment>,
            }}
            helperText={`${t('preferences.run_thresholds.on_average')} ${
              defaultValues.duration
            }`}
          />
        </Stack>
        <Button
          variant="contained"
          sx={{my: 1}}
          disabled={!isChanged()}
          onClick={saveThresholds}>
          {`${t('preferences.run_thresholds.btn_save')} ${translateAction(
            props.run_type,
            2,
          )} ${t('preferences.run_thresholds.btn_thresholds')}`}
        </Button>
      </Box>
    </>
  );
}
