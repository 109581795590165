import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import {Link} from 'react-router-dom';

import Logo from '../../../common/Logo/Logo';
import styles from './TeamDropdownItem.module.scss';
import {ROUTE} from '@/config/routes.config';

import {useOverviewDashboards} from '@/context/dashboard/overview-dashboards.context';
import {FITeam} from '@my-game-plan/types';

interface TeamDropwndownItemProps {
  team: FITeam;
  onClose: () => void;
}

export const TeamDropwndownItem = ({
  team,
  onClose,
}: TeamDropwndownItemProps) => {
  const {selectedDashboard} = useOverviewDashboards();
  const image = team.image_url;
  const _to = React.useMemo(
    () => `${ROUTE.dashboards}/${team._id.toString()}/${selectedDashboard}`,
    [selectedDashboard],
  );

  return (
    <MenuItem
      component={Link}
      to={_to}
      className="team-list__link"
      divider
      onClick={onClose}>
      <div className={styles.container}>
        <Logo size="small" src={image} />
        <span>{team.name}</span>
      </div>
    </MenuItem>
  );
};
