const TOOLTIP_TRANSLATIONS = {
  previous_clip: 'Previous clip',
  play: 'Play',
  pause: 'Pause',
  play_from_start: 'Play from start',
  next_clip: 'Next clip',
  ten_seconds_backward: 'Show 10 seconds earlier',
  ten_seconds_forward: 'Show 10 seconds later',
  ten_more_seconds: 'Load {{count}} more seconds',
  edit: 'Edit clip',
  autoplay_on: 'Turn autoplay on',
  autoplay_off: 'Turn autoplay off',
  mute: 'Mute',
  unmute: 'Unmute',
  fullscreen: 'Fullscreen',
  sequence_start: 'Start of sequence',
  sequence_end: 'End of sequence',
  save: 'Save clip duration',
};

export default TOOLTIP_TRANSLATIONS;
