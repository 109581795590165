import React, {PropsWithChildren, useState} from 'react';

import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {getMatchesByTeam} from '@/controllers/matches.controller';
import {FIMatch} from '@my-game-plan/types';
import {LOGIN_ERRORS} from '@/config/errors.config';

export interface MatchesAPI {
  all: FIMatch[];
  ownMatches: FIMatch[];
  getMatchesByTeam: (team: string | undefined, isOwnTeam?: boolean) => void;
  error: string | null;
}
const context = createCustomContext<MatchesAPI>();
export const useMatches = createContextHook(context);

export const MatchesProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  const [_matches, _setMatches] = useState<FIMatch[]>([]);
  const [_ownMatches, _setOwnMatches] = useState<FIMatch[]>([]);
  const [_error, _setError] = useState<string | null>(null);

  /* Handlers */
  async function _getMatchesByTeam(
    team: string | undefined,
    isOwnTeam?: boolean,
  ) {
    if (isOwnTeam) {
      _setOwnMatches([]);
    } else {
      _setMatches([]);
    }
    _setError(null);
    const _matchesData = await getMatchesByTeam(team);
    if (!_matchesData.length) {
      _setError(LOGIN_ERRORS.TEAM_NO_MATCHES);
    }
    if (isOwnTeam) {
      _setOwnMatches(_matchesData);
    } else {
      _setMatches(_matchesData);
    }
    return _matchesData;
  }

  return createProvider(context, props, {
    all: _matches,
    ownMatches: _ownMatches,
    getMatchesByTeam: _getMatchesByTeam,
    error: _error,
  });
};
