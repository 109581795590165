import i18n from '../../localization';

import {
  roundAndFormatNumber,
  toPercentage,
} from '@/helpers/general/general.helper';
import {
  ACTION,
  FIAutomationPlayerScores,
  FIEventFilters,
  FIFormattedAutomationPlayerScore,
  FIFormattedAutomationPlayerScores,
  TEventCalculationType,
} from '@my-game-plan/types';

import {PALETTE_ERROR, PALETTE_SUCCESS, PALETTE_TEXT} from '@/theme';
import {translateAction, translateEventProperty} from '../translation.helper';

/* Format scores */
function formatSingleValueOrPercentage(
  value: number,
  isRatioAutomation?: boolean,
  decimals?: number,
): string | number {
  if (isRatioAutomation) {
    return toPercentage(value, decimals);
  }

  return roundAndFormatNumber(value, decimals);
}

export function formatSingleScore<T = FIAutomationPlayerScores>(
  key: keyof T,
  label: string,
  value?: number,
  diff?: number,
  isRatioAutomation?: boolean,
  suffix?: string | number,
  shouldInvert?: boolean,
  decimals?: number,
): FIFormattedAutomationPlayerScore | null {
  let _formattedValue = undefined;

  if (typeof value !== 'undefined') {
    /* Get correct rounding and add "%" suffix if needed */
    _formattedValue = formatSingleValueOrPercentage(
      value,

      isRatioAutomation,
      decimals,
    );
  }

  /* Diff - Color + Format */
  let _formattedDiff = undefined;
  let _diffTooltip = undefined;
  let _diffColor = PALETTE_TEXT.secondary as string;
  if (typeof diff !== 'undefined' && typeof value !== 'undefined') {
    _formattedDiff = formatSingleValueOrPercentage(
      Math.abs(diff),
      isRatioAutomation,
    );
    if (diff < 0) {
      _formattedDiff = `-${_formattedDiff}`;
    } else {
      _formattedDiff = `+${_formattedDiff}`;
    }
    if ((diff < 0 && !shouldInvert) || (diff > 0 && shouldInvert)) {
      _diffColor = PALETTE_ERROR.main;
    } else {
      _diffColor = PALETTE_SUCCESS.main;
    }

    const _tooltipSuffix = i18n.t(
      `eventAutomations.benchmark.${
        diff < 0 ? 'belowBenchmarkProp' : 'aboveBenchmarkProp'
      }`,
      {
        benchmark: i18n.t('eventAutomations.benchmark.benchmark').toLowerCase(),
      },
    );
    _diffTooltip = `${_formattedDiff.substring(1)} ${_tooltipSuffix}`;
  }

  const _suffix = suffix || _formattedDiff;

  const _formattedScore: FIFormattedAutomationPlayerScore = {
    label: label,
    key: key as keyof FIAutomationPlayerScores,
    diff: diff,
    formattedDiff: _formattedDiff,
    diffColor: _diffColor,
    diffTooltip: _diffTooltip,
    value: value,
    formattedValue: _formattedValue,
    valueSuffix: _suffix,
  };

  return _formattedScore;
}

export function formatPlayerScores(
  action: ACTION,
  scores: FIAutomationPlayerScores,
  calculation: TEventCalculationType,
  ruleConditionAction?: ACTION,
  shouldShowMatchSuffix?: boolean,
  isWatchingPer90?: boolean,
  shouldInvert?: boolean,
  metric?: keyof FIEventFilters,
): FIFormattedAutomationPlayerScores {
  /* Average - Benchmark average */
  const _isRatioAutomation = calculation === 'ratio' || calculation === 'rule';
  let _matchSuffix = undefined;
  if (shouldShowMatchSuffix && !_isRatioAutomation) {
    _matchSuffix = i18n.t(
      `eventAutomations.values.${isWatchingPer90 ? 'per90' : 'perMatch'}`,
    );
  }

  const _average = formatSingleScore(
    'average',
    i18n.t('eventAutomations.properties.average'),
    scores.average,
    scores.average_difference,
    _isRatioAutomation,
    undefined,
    shouldInvert,
  );

  /* Benchmark average */
  const _benchmarkAverage = formatSingleScore(
    'benchmark_average',
    i18n.t('eventAutomations.benchmark.benchmark'),
    scores.benchmark_average,
    undefined,
    _isRatioAutomation,
    _matchSuffix,
    shouldInvert,
  );

  /* Last match */
  const _lastMatch = formatSingleScore(
    'last',
    i18n.t('eventAutomations.properties.lastMatch'),
    scores.last,
    scores.last_difference,
    _isRatioAutomation,
    undefined,
    shouldInvert,
  );

  /* Minutes played */
  let _lastMatchMinutesPlayed = undefined;
  if (typeof scores.last_minutes_played !== undefined) {
    _lastMatchMinutesPlayed = `${scores.last_minutes_played}' ${i18n
      .t('eventAutomations.properties.lastMatch')
      .toLowerCase()}`;
  }

  const _minutesPlayed = formatSingleScore(
    'minutes_played',
    i18n.t('eventAutomations.properties.minutesPlayed'),
    scores.minutes_played,
    undefined,
    false,
    _lastMatchMinutesPlayed,
  );

  /* Matches played */
  const _matchesPlayed = formatSingleScore(
    'matches_played',
    i18n.t('eventAutomations.properties.matchesPlayed'),
    scores.matches_played,
    undefined,
    false,
  );

  /* Total (and successful) events */
  const _primaryValue = _isRatioAutomation
    ? scores.total_successful_events
    : scores.total_events;
  const _suffixValue = _isRatioAutomation
    ? `/${scores.total_events}`
    : undefined;

  let _eventsLabel = translateAction(action, 2);
  if (calculation === 'ratio') {
    const _objectiveText = i18n.t('eventAutomations.properties.objective');
    _eventsLabel = `${_objectiveText}/${_eventsLabel}`;
  } else if (calculation === 'rule' && ruleConditionAction) {
    const _ruleCondionActionText = translateAction(ruleConditionAction, 2);
    _eventsLabel = `${_eventsLabel}/${_ruleCondionActionText}`;
  }

  const _totalEvents = formatSingleScore(
    'total_events',
    _eventsLabel,
    _primaryValue,
    undefined,
    false,
    _suffixValue,
  );

  /* Metrics trackers */
  let _totalScoreSum = undefined;
  let _averageScorePerEvent = undefined;
  if (metric) {
    _totalScoreSum = formatSingleScore(
      'total_score_sum',
      i18n.t('eventAutomations.properties.totalSum', {
        metric: translateEventProperty(metric),
      }),
      scores.total_score_sum,
    );
    _averageScorePerEvent = formatSingleScore(
      'average_score_per_event',
      i18n.t('eventAutomations.properties.averagePerEvent'),
      scores.average_score_per_event,
    );
  }

  return {
    average: _average,
    benchmark_average: _benchmarkAverage,
    last: _lastMatch,
    minutes_played: _minutesPlayed,
    matches_played: _matchesPlayed,

    total_events: _totalEvents,

    total_score_sum: _totalScoreSum,
    average_score_per_event: _averageScorePerEvent,
  };
}
