import {ITypedOption} from '@/types/option.types';
import {FICompetition, TCompetitionGroup} from '@my-game-plan/types';

export function competitionsToTypedOptions(
  competitions: FICompetition[],
  domesticCountryCode?: string,
): ITypedOption<number>[] {
  const _competitionDropdownOptions: ITypedOption<number>[] = [];

  competitions.sort((a, b) => {
    let _aGroup = 'other';
    let _bGroup = 'other';

    if (a.country_code === domesticCountryCode) {
      _aGroup = 'domestic';
    } else if (a.type === 'international_cup') {
      _aGroup = 'international';
    }

    if (b.country_code === domesticCountryCode) {
      _bGroup = 'domestic';
    } else if (b.type === 'international_cup') {
      _bGroup = 'international';
    }
    if (_aGroup === 'domestic') {
      return -1;
    }
    if (_bGroup === 'domestic') {
      return 1;
    }

    if (_aGroup === 'international') {
      return -1;
    }
    if (_bGroup === 'international') {
      return 1;
    }

    if (a.country_code < b.country_code) {
      return -1;
    }
    if (a.country_code > b.country_code) {
      return 1;
    }

    if (a.type === 'domestic_league') {
      return -1;
    }
    if (b.type === 'domestic_league') {
      return 1;
    }
    return a.tier - b.tier;
  });

  competitions.forEach((competition) => {
    let _group: TCompetitionGroup = 'other';

    if (competition.country_code === domesticCountryCode) {
      _group = 'domestic';
    } else if (competition.type === 'international_cup') {
      _group = 'international';
    }

    _competitionDropdownOptions.push({
      label: competition.name,
      name: competition.name,
      value: competition._id,
      group: _group,
    });
  });

  return _competitionDropdownOptions;
}

export function formatSeason(seasonId: number): string {
  return `${seasonId}-${seasonId + 1}`;
}
