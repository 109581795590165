import React, {useEffect, useState} from 'react';
import {FICompetition, FMatch} from '@my-game-plan/types';

import Menu from '@mui/material/Menu';

import Checkbox from '@mui/material/Checkbox';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import TuneIcon from '@mui/icons-material/Tune';

import {useTranslation} from 'react-i18next';
import {ISelectedMatchesInfo} from '@/types/event-automations-misc.types';
import {useAutomationDetail} from '@/context/event-automations/automation-detail.context';

interface IMatchesFilterCompetitionSelectorProps {
  seasonId: number;
  competitions: FICompetition[];
  matches: FMatch[];
}

type TSelectedCompetitionInfo = Record<number, ISelectedMatchesInfo>;

function MatchesFilterCompetitionSelector(
  props: IMatchesFilterCompetitionSelectorProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _automationDetailContext = useAutomationDetail();

  const [_menuAnchorEl, _setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const [_selectedMatchesInfo, _setSelectedMatchesInfo] =
    useState<TSelectedCompetitionInfo>({});

  /*
   * Side effects
   */
  useEffect(() => {
    //
    const _newSelectedMatchesInfo: TSelectedCompetitionInfo = {};
    props.competitions.forEach((competition) => {
      const _competitionMatches = props.matches.filter(
        (match) => match.competition_id === competition._id,
      );
      const _totalMatches = _competitionMatches.length;
      const _selectedMatches =
        _automationDetailContext.observingMatchIds.filter((matchId) =>
          _competitionMatches.some((match) => match._id === matchId),
        ).length;

      _newSelectedMatchesInfo[competition._id] = {
        total: _totalMatches,
        selected: _selectedMatches,
      };

      _setSelectedMatchesInfo(_newSelectedMatchesInfo);
    });
  }, [
    _automationDetailContext.observingMatchIds,
    props.competitions,
    props.matches,
  ]);

  /*
   * Handlers
   */
  function _onMenuClick(event: React.MouseEvent<HTMLElement>) {
    _setMenuAnchorEl(event.currentTarget);
  }

  function _onMenuClose() {
    _setMenuAnchorEl(null);
  }

  function _onCompetitionSelect(competitionId: number) {
    // Unselect all matches
    const _competitionMatchIds = props.matches
      .filter((match) => match.competition_id === competitionId)
      .map((match) => match._id);

    const _newMatchIds = _automationDetailContext.observingMatchIds.filter(
      (matchId) => !_competitionMatchIds.includes(matchId),
    );

    if (!_selectedMatchesInfo[competitionId]?.selected) {
      _newMatchIds.push(..._competitionMatchIds);
    }

    _automationDetailContext.onObservingMatchIdChange(_newMatchIds);
  }

  /*
   * Render
   */

  /* Hide selector when only 1 competition is available */
  if (props.competitions.length < 2) {
    return null;
  }

  return (
    <>
      <ListItemButton
        color="secondary"
        divider
        sx={{pl: 2}}
        onClick={_onMenuClick}>
        <ListItemText
          primary={t('eventAutomations.matchFilter.competitionsCount', {
            count: props.competitions.length,
          })}
          primaryTypographyProps={{color: 'text.secondary'}}
        />
        <ListItemIcon sx={{minWidth: 0}}>
          <TuneIcon color="secondary" />
        </ListItemIcon>
      </ListItemButton>
      <Menu
        open={Boolean(_menuAnchorEl)}
        anchorEl={_menuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={_onMenuClose}>
        {props.competitions.map((competition) => {
          const _isChecked =
            _selectedMatchesInfo[competition._id]?.selected ===
            _selectedMatchesInfo[competition._id]?.total;
          const _isIndeterminate =
            Boolean(_selectedMatchesInfo[competition._id]?.selected) &&
            _selectedMatchesInfo[competition._id]?.total !==
              _selectedMatchesInfo[competition._id]?.selected;
          return (
            <ListItemButton
              onClick={() => _onCompetitionSelect(competition._id)}
              dense
              key={`${props.seasonId}-competition-${competition._id}`}>
              <Checkbox
                checked={_isChecked}
                indeterminate={_isIndeterminate}
                onChange={() => _onCompetitionSelect(competition._id)}
              />
              <ListItemText primary={competition.name} />
            </ListItemButton>
          );
        })}
      </Menu>
    </>
  );
}

export default MatchesFilterCompetitionSelector;
