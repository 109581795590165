import {t} from 'i18next';
import React from 'react';
import {Card} from '../../common/card/Card';
import {CenteredSpinner} from '../../common/centered-spinner/CenteredSpinner';
import CustomEmptyState from '../../error-states/CustomEmptyState';
import {TrackerTrend} from '../../trackers/trend/TrackerTrend';
import {TrackerDetailFooter} from './footer/TrackerDetailFooter';

import AutomationPerformance from './performance/automation-performance.view';
import {TrackerDetailPitchVisual} from './pitch-visual/TrackerDetailPitchVisual';
import {TrackerDetailVideos} from './videos/TrackerDetailVideos';

import styles from './automation-detail.module.scss';

import {TEventAutomationType} from '@my-game-plan/types';

import TrackerBenchmarkCard from './benchmark/benchmark-card/benchmark-card.view';
import AutomationWarning from '@/components/automations/automation-warning/automation-warning.view';
import AutomationDetailHeader from '@/components/automations/automation-detail/automation-detail-header.view';

import {
  AutomationDetailProvider,
  useAutomationDetail,
} from '@/context/event-automations/automation-detail.context';
import {translateAutomationType} from '@/helpers/automation/automation-form.helper';
import {useTeams} from '@/context/team.context';

interface IAutomationDetailProps {
  id?: string;
  automationType: TEventAutomationType;
}

function AutomationDetailWithoutProvider(
  props: IAutomationDetailProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const _automationDetailContext = useAutomationDetail();
  const _teamsContext = useTeams();

  /*
   * Handlers
   */

  /*
   * Render
   */

  if (_automationDetailContext.error) {
    let _errorHeaderKey = 'error-states.not-found.header';
    let _showAsError = true;
    if (
      _automationDetailContext.automationType === 'opponent-automation' &&
      !_teamsContext.nextOpponentId
    ) {
      _errorHeaderKey = 'error-states.noNextOpponent';
      _showAsError = false;
    }
    return (
      <CustomEmptyState
        imageType={_showAsError ? 'error' : 'default'}
        header={t(_errorHeaderKey)}
        description={t('error-states.not-found.description')}
      />
    );
  }
  if (
    _automationDetailContext.isFetchingAutomation ||
    !_automationDetailContext.observingTeamId
  )
    return <CenteredSpinner style={{marginTop: 56}} />;

  if (!_automationDetailContext.automation) {
    const _translatedType = translateAutomationType(props.automationType, 1);
    return (
      <div className={styles.container} style={{marginTop: 24}}>
        <p>
          {t('eventAutomations.automationNotFound', {
            type: _translatedType,
          })}
        </p>
      </div>
    );
  }
  const _firstPlayer = _automationDetailContext.automation.players?.[0];
  const _hasHistory = Boolean(
    _firstPlayer?.history && _firstPlayer?.history.length,
  );

  const _isTeamTracker = Boolean(
    !_automationDetailContext.automation.observing_players?.players?.length,
  );

  return (
    <div className={styles.overview}>
      {/* Header */}
      <AutomationWarning
        data={_automationDetailContext.automation}
        type={props.automationType}
      />
      <AutomationDetailHeader />
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* Performance */}
          {_hasHistory && (
            <AutomationPerformance
              events={_automationDetailContext.events}
              actionInfo={{
                action:
                  _automationDetailContext.automation.rule_condition?.action ||
                  _automationDetailContext.automation.action,
                details:
                  _automationDetailContext.automation.success_details ||
                  _automationDetailContext.automation.details,
                metric: _automationDetailContext.automation.metric,
              }}
              scores={_automationDetailContext.scores}
              isTeamTracker={_isTeamTracker}
            />
          )}

          {/* History empty */}

          {!_hasHistory && <CustomEmptyState />}

          {/* History not empty */}
          {_hasHistory && (
            <>
              {/* Trend */}
              <div className={styles.trendWrapper}>
                <div className={styles.trend}>
                  <TrackerTrend
                    tracker={_automationDetailContext.automation}
                    events={_automationDetailContext.events}
                    displayScales={true}
                    displayReferenceLine={true}
                    displayBrush={true}
                    observingMatchIds={
                      _automationDetailContext.observingMatchIds
                    }
                    observingTeamId={
                      _automationDetailContext.observingTeamId || undefined
                    }
                    isOnlyShowingObjectiveEvents={
                      _automationDetailContext.isOnlyShowingObjectiveEvents
                    }
                  />
                </div>
                <TrackerBenchmarkCard />
              </div>

              <div className={styles.grid}>
                {/* Videos */}
                <Card padding={false}>
                  {_automationDetailContext.isFetchingEvents && (
                    <CenteredSpinner style={{padding: 32}} />
                  )}
                  {!_automationDetailContext.isFetchingEvents &&
                    _automationDetailContext.events.length <= 0 && (
                      <p style={{padding: 32}}>
                        {t('eventAutomations.noVideos')}
                      </p>
                    )}
                  {!_automationDetailContext.isFetchingEvents &&
                    _automationDetailContext.events.length > 0 && (
                      <TrackerDetailVideos
                        observingMatchIds={
                          _automationDetailContext.observingMatchIds
                        }
                        tracker={_automationDetailContext.automation}
                        events={_automationDetailContext.events}
                        isOnlyShowingObjectiveEvents={
                          _automationDetailContext.isOnlyShowingObjectiveEvents
                        }
                      />
                    )}
                </Card>

                {/* Pitch visual */}
                <TrackerDetailPitchVisual
                  loading={_automationDetailContext.isFetchingEvents}
                  observingMatchIDs={_automationDetailContext.observingMatchIds}
                  id={_automationDetailContext.automation._id}
                  action={
                    _automationDetailContext.automation.rule_condition
                      ?.action || _automationDetailContext.automation.action
                  }
                  metric={_automationDetailContext.automation.metric}
                  details={
                    _automationDetailContext.automation.success_details ||
                    _automationDetailContext.automation.details
                  }
                  isOnlyShowingObjectiveEvents={
                    _automationDetailContext.isOnlyShowingObjectiveEvents
                  }
                  events={_automationDetailContext.events}
                  zones={_automationDetailContext.zones}
                  observingTeamId={_automationDetailContext.observingTeamId}
                  isObservingOpponent={
                    _automationDetailContext.automation.observing_players
                      ?.team === 'opponent'
                  }
                  automationCalculation={
                    _automationDetailContext.automation.calculation
                  }
                />
              </div>
            </>
          )}

          {/* Footer */}
          <TrackerDetailFooter tracker={_automationDetailContext.automation} />
        </div>
      </div>
    </div>
  );
}

function AutomationDetail(props: IAutomationDetailProps): JSX.Element {
  return (
    <AutomationDetailProvider automationType={props.automationType}>
      <AutomationDetailWithoutProvider {...props} />
    </AutomationDetailProvider>
  );
}
export default AutomationDetail;
