import React, {useEffect, useState} from 'react';
import {ITypedOption} from '@/types/option.types';
import {
  FIPlayerSearchFilters,
  PlayerPosition,
  TPlayerPreferredFoot,
} from '@my-game-plan/types';

import {useCompetitions} from '@/context/competitions.context';
import {getPositionOptions} from '@/helpers/automation/automation-form.helper';
import {PREFERRED_FOOT_POSSIBILITIES} from '@/config/players.config';
import CustomAutocomplete from '../../material-customised/autocomplete/autocomplete.view';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useTeams} from '@/context/team.context';

type ValueType<T = keyof FIPlayerSearchFilters> = T extends 'preferred_foot'
  ? TPlayerPreferredFoot
  : T extends 'position'
  ? PlayerPosition
  : T extends 'competition_id'
  ? number
  : string;

interface ISearchDropdownProps<T extends keyof FIPlayerSearchFilters> {
  label: string;
  property: T;
  value?: ValueType<T>[];
  onChange: (data: Partial<FIPlayerSearchFilters>) => void;
}

function SearchDropdown<T extends keyof FIPlayerSearchFilters>(
  props: ISearchDropdownProps<T>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _competitionsContext = useCompetitions();
  const _teamsContext = useTeams();
  const [_options, _setOptions] = useState<ITypedOption<ValueType<T>>[]>([]);
  const [_selectedOptions, _setSelectedOptions] = useState<
    ITypedOption<ValueType<T>>[]
  >([]);

  /* Set options */
  useEffect(() => {
    let _newOptions: ITypedOption<ValueType<T>>[] = [];
    const _allDomesticCompetitions = _competitionsContext.all
      .filter((competition) => competition.type === 'domestic_league')
      .sort((a, b) => a.country_code.localeCompare(b.country_code));

    switch (props.property) {
      case 'position':
        // Get position options
        _newOptions = getPositionOptions() as ITypedOption<ValueType<T>>[];
        break;
      case 'preferred_foot':
        // Get foot options
        _newOptions = PREFERRED_FOOT_POSSIBILITIES.map((foot) => ({
          value: foot,
          label: foot,
          name: foot,
        })) as ITypedOption<ValueType<T>>[];
        break;
      case 'competition_id':
        // Get competition options - Get domestic competitions only

        _newOptions = _allDomesticCompetitions.map((competition) => ({
          value: competition._id,
          label: competition.name,
          name: competition.name,
        })) as ITypedOption<ValueType<T>>[];
        break;
      case 'country':
        // Get country options
        _newOptions = _teamsContext.allPlayerCountries.map((country) => ({
          value: country,
          label: country,
          name: country,
        })) as ITypedOption<ValueType<T>>[];
        break;

      default:
        break;
    }
    _setOptions(_newOptions);
  }, [
    props.property,
    _competitionsContext.all,
    _teamsContext.allPlayerCountries,
  ]);

  /* Set selected options */
  useEffect(() => {
    if (props.value) {
      _setSelectedOptions(
        _options.filter((option) => props.value?.includes(option.value)),
      );
    } else {
      _setSelectedOptions([]);
    }
  }, [props.value, _options]);

  /*
   * Handlers
   */
  function _onChange(
    value: ITypedOption<ValueType<T>>[] | ITypedOption<ValueType<T>> | null,
  ) {
    if (Array.isArray(value)) {
      props.onChange({
        [props.property]: value.map((option) => option.value),
      });
    }
  }

  /*
   * Render
   */

  const _shouldDisplayGroupHeader = props.property === 'position';
  function _groupHeaderKey(text: string): string {
    if (props.property === 'position') {
      return capitalize(t(`playerPosition.${text}s`));
    }
    return text;
  }
  return (
    <CustomAutocomplete<ValueType<T>, true>
      label={props.label}
      options={_options}
      value={_selectedOptions}
      onChange={_onChange}
      disableCloseOnSelect
      multiple
      groupHeader={_shouldDisplayGroupHeader ? _groupHeaderKey : undefined}
    />
  );
}

export default SearchDropdown;
