import React from 'react';

import Menu from '@mui/material/Menu';

import {lighten, useTheme} from '@mui/material';

import {useAutomationDetail} from '@/context/event-automations/automation-detail.context';
import MatchesFilterSeasonSubList from './matches-filter-season-sublist.view';

interface IMatchesFilterProps {
  anchorElement: HTMLElement | null;
  onClose: () => void;
}

function MatchesFilter(props: IMatchesFilterProps): JSX.Element {
  /*
   * Hooks n State
   */
  const _theme = useTheme();
  const PAPER_BG = lighten(_theme.palette.background.paper, 0.03);
  const _automationDetailContext = useAutomationDetail();

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <Menu
      anchorEl={props.anchorElement}
      open={Boolean(props.anchorElement)}
      onClose={props.onClose}
      PaperProps={{
        sx: {
          maxHeight: 480,
          bgcolor: PAPER_BG,
          width: 280,
        },
      }}>
      {_automationDetailContext.historicalSeasonInfo.map((season, index) => {
        const _isLast =
          index === _automationDetailContext.historicalSeasonInfo.length - 1;
        return (
          <MatchesFilterSeasonSubList
            key={`season-${season.seasonId}`}
            season={season}
            isLast={_isLast}
          />
        );
      })}
    </Menu>
  );
}
export default MatchesFilter;
