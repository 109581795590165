import {hotjar} from 'react-hotjar';
import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/react';
import {FIUserFormatted} from '@my-game-plan/types';

const _isDev = process.env.NODE_ENV === 'development';

export function initAnalytics() {
  /* HOTJAR */
  const _hotjarID = process.env.REACT_APP_HOTJAR_ID;
  if (process.env.REACT_APP_ENV === 'production' && _hotjarID) {
    hotjar.initialize(parseInt(_hotjarID), 6);
  }

  /* MIXPANEL */
  const _mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
  if (_mixpanelToken) {
    mixpanel.init(_mixpanelToken, {debug: false, ignore_dnt: true});
  }
}

export async function identifyAnalyticsUser(
  user: FIUserFormatted,
): Promise<void> {
  const _userData = {
    $name: `${user.firstName} ${user.lastName}`,
    role: user.role,
    team_id: user.team,
  };

  if (hotjar.initialized()) {
    hotjar.identify(user._id, _userData);
  }

  await mixpanel.identify(user._id);
  mixpanel.people.set({
    ..._userData,
    first_name: user.firstName,
    last_name: user.lastName,
  });

  Sentry.setUser({
    ..._userData,
    _id: user._id,
  });
}

export function logoutAnalyticsUser() {
  mixpanel.reset();
  Sentry.setUser(null);
}
