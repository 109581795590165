import React from 'react';
import {
  FIShotAnalysisScore,
  ShotCategory,
  TShotAnalysisTeam,
} from '@my-game-plan/types';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import ShieldIcon from '@mui/icons-material/Shield';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {translateValue} from '@/helpers/translation.helper';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {SxProps} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import {Theme} from '@mui/system';
import {roundAndFormatNumber} from '@/helpers/general/general.helper';
import {useTeams} from '@/context/team.context';
import {useShotAnalysis} from '@/context/shot-analysis.context';
import Logo from '@/components/common/Logo/Logo';

/* Interfaces */
interface IShotAnalysisTableRowProps {
  //   data: FIShotAnalysisCategory;
  shotCategory?: ShotCategory;
  teamScore?: FIShotAnalysisScore;
  benchmarkScore: FIShotAnalysisScore;
  onPlayVideo: (shotCategory?: ShotCategory) => void;
  onViewDetails: (shotCategory?: ShotCategory) => void;
  type?: TShotAnalysisTeam;
}

/* Styles */
const TYPE_CELL_STYLES: SxProps<Theme> = {
  fontWeight: 700,
};
const ROWSPAN_STYLES: SxProps<Theme> = {
  borderBottom: 'none',
};

const TEAM_CELL_STYLES: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
};

/* Component */
function ShotAnalysisTableRow(
  props: IShotAnalysisTableRowProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _shotAnalysisContext = useShotAnalysis();
  const _teamsContext = useTeams();

  /*
   * Handlers
   */
  function _onWatchDetails() {
    props.onViewDetails(props.shotCategory);
  }
  function _onWatchVideo() {
    props.onPlayVideo(props.shotCategory);
  }

  function _onSelect() {
    if (props.shotCategory) {
      _shotAnalysisContext.onPitchVisualShotTypeSelect(
        props.shotCategory,
        props.type,
      );
    }
  }

  /*
   * Render
   */

  if (!props.teamScore || !_teamsContext.ownTeam) {
    return null;
  }

  const _cellStyle: SxProps<Theme> = {
    ...(!props.shotCategory && {
      borderBottom: 'none',
    }),
  };

  let _CategoryCellContent: JSX.Element | string = t('shotAnalysis.total');
  if (props.shotCategory) {
    _CategoryCellContent = (
      <FormControlLabel
        control={<Checkbox />}
        onChange={_onSelect}
        checked={_shotAnalysisContext.pitchVisualShotTypes.includes(
          props.shotCategory,
        )}
        label={
          <Typography sx={{fontSize: '.875rem', fontWeight: '700'}}>
            {capitalize(translateValue(props.shotCategory))}
          </Typography>
        }
      />
    );
  }
  return (
    <React.Fragment>
      <TableRow>
        {/* Category */}
        <TableCell rowSpan={2} sx={{..._cellStyle, ...TYPE_CELL_STYLES}}>
          {_CategoryCellContent}
        </TableCell>

        {/* OWN - Team: */}
        <TableCell
          sx={{...TEAM_CELL_STYLES, ...ROWSPAN_STYLES} as SxProps<Theme>}>
          <Logo src={_teamsContext.ownTeam.image_url} />
          {_teamsContext.ownTeam.name}
        </TableCell>

        {/* OWN - Shots: */}
        <TableCell sx={ROWSPAN_STYLES} align="center">
          {roundAndFormatNumber(props.teamScore.shots, 2)}
        </TableCell>

        {/* OWN - Goals: */}
        <TableCell sx={ROWSPAN_STYLES} align="center">
          {roundAndFormatNumber(props.teamScore.goals, 2)}
        </TableCell>

        {/* OWN - Goal rate: */}
        <TableCell sx={ROWSPAN_STYLES} align="center">
          {roundAndFormatNumber(props.teamScore.goal_rate, 2)}
        </TableCell>

        {/* Btn - Details */}
        <TableCell rowSpan={2} sx={_cellStyle}>
          <Button color="secondary" onClick={_onWatchDetails}>
            {t('shotAnalysis.details.cta')}
          </Button>
        </TableCell>

        {/* Btn - Watch video */}
        <TableCell rowSpan={2} sx={_cellStyle}>
          <IconButton
            disabled={_shotAnalysisContext.isLoadingEvents}
            color="primary"
            title={t('video-player.button.watch')}
            onClick={_onWatchVideo}>
            <PlayArrowIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        {/* BENCHMARK - Team: */}
        <TableCell sx={{..._cellStyle, ...TEAM_CELL_STYLES}}>
          <ShieldIcon sx={{color: 'secondary.main'}} />
          {_shotAnalysisContext.benchmarkLabel}
        </TableCell>

        {/* BENCHMARK - Shots: */}
        <TableCell sx={_cellStyle} align="center">
          {roundAndFormatNumber(props.benchmarkScore.shots, 2)}
        </TableCell>

        {/* BENCHMARK - Goals: */}
        <TableCell sx={_cellStyle} align="center">
          {roundAndFormatNumber(props.benchmarkScore.goals, 2)}
        </TableCell>

        {/* BENCHMARK - Goal rate: */}
        <TableCell sx={_cellStyle} align="center">
          {roundAndFormatNumber(props.benchmarkScore.goal_rate, 2)}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default ShotAnalysisTableRow;
