import React from 'react';

import {TDevComponent} from '@/types/dev.types';
import {Button} from '@mui/material';
import {DevSection} from './dev-section';
import {useSnackbar, VariantType} from 'notistack';

export const DevSectionSnackbarView = () => {
  const {enqueueSnackbar} = useSnackbar();

  const handleClick = () => {
    enqueueSnackbar('This is a message');
  };

  const handleClickVariant = (variant: VariantType) => () => {
    if (variant === 'success') {
      enqueueSnackbar('This is a success message', {variant});
    } else {
      enqueueSnackbar('This is an error message', {variant});
    }
  };

  const snackbar: TDevComponent[] = [
    {
      title: 'Snackbar',
      variants: [
        {
          title: 'Regular',
          component: (
            <div>
              <Button onClick={handleClick}>Open simple snackbar</Button>
            </div>
          ),
        },
        {
          title: 'Success',
          component: (
            <div>
              <Button onClick={handleClickVariant('success')}>
                Open success snackbar
              </Button>
            </div>
          ),
        },
        {
          title: 'Error',
          component: (
            <div>
              <Button onClick={handleClickVariant('error')}>
                Open error snackbar
              </Button>
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <DevSection
      title="Snackbar"
      componentList={snackbar}
      showItemTitle={false}
    />
  );
};
