import {useAPI} from '@/hooks/api.hooks';
import {Resource} from '@/types/api.types';
import {TEAMS_API_CONFIG} from '@/config/api.config';
import {
  ACTION,
  CountTagUsageBody,
  EditTagBody,
  F,
  FIBenchmarkedPhysicalData,
  FIPerformanceOverview,
  FITeam,
  FITeamFormatted,
  ITeamFormations,
  RunPreference,
} from '@my-game-plan/types';
import {DEFAULT_FORMATIONS} from '@/types/team.types';
import {REQUEST_ERRORS} from '@/config/errors.config';

const api = useAPI();

export const getTeams = async (
  userID: string | undefined,
): Promise<FITeamFormatted[]> => {
  try {
    const {data} = await api.get<FITeam[]>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getTeams,
      params: {
        user_id: userID,
      },
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch {
    return new Promise<FITeamFormatted[]>((res) => res([]));
  }
};

export const getTeam = async (teamID: string): Promise<FITeam> => {
  try {
    const {data} = await api.get<FITeam>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getTeam(teamID),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    // return new Promise<FITeamFormatted>((res) => res([]));
    throw new Error((error as any).message);
  }
};

export const getTeamFormations = async (
  teamID: string | undefined,
): Promise<ITeamFormations> => {
  try {
    const {data} = await api.get<ITeamFormations>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getTeamFormations(teamID),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch {
    return new Promise<ITeamFormations>((res) => res(DEFAULT_FORMATIONS));
  }
};

export const updateRunPreferences = async (
  teamID: string,
  action: ACTION,
  preferences: RunPreference,
): Promise<void> => {
  try {
    const {data} = await api.put({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.updateRunPreferences(teamID, action),
      data: preferences,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return;
  } catch (e) {
    console.log(e);
  }
};

export async function getTagCount(teamID: string) {
  try {
    const {data} = await api.get<F<CountTagUsageBody>>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getTagCount(teamID),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (e) {
    console.log(e);
  }
}

export async function deleteTag(teamID: string, tagToDelete: string) {
  try {
    const {data} = await api.del({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.deleteTag(teamID, tagToDelete),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (e) {
    console.log(e);
  }
}

export async function editTag(teamID: string, editTagBody: EditTagBody) {
  try {
    const {data} = await api.put({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.editTag(teamID),
      data: editTagBody,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (e) {
    console.log(e);
  }
}

export const getTeamPerformanceById = async (
  teamId: string,
): Promise<FIPerformanceOverview> => {
  try {
    const {data} = await api.get<FIPerformanceOverview>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getTeamPerformance(teamId),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
};

export const getTeamPhysicalDataById = async (
  teamId: string,
): Promise<FIBenchmarkedPhysicalData | null> => {
  try {
    const {data} = await api.get<FIBenchmarkedPhysicalData | null>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getTeamPhysical(teamId),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
};

export async function searchTeams(query: string): Promise<FITeam[]> {
  try {
    const {data} = await api.get<FITeam[]>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.search(),
      params: {
        q: query,
      },
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}
