import React, {useEffect} from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';

import {ILinkState} from '@/types/routes.types';

import {Link, useLocation} from 'react-router-dom';
import {FIPlayer, FIPlayerWithPerformance} from '@my-game-plan/types';
import {IPagination} from '@/types/api.types';

import CustomEmptyState from '../error-states/CustomEmptyState';
import {useTranslation} from 'react-i18next';
import {TScoutingViewType} from '@/types/scouting.types';

interface ISearchResultsGridProps<T> {
  viewType: TScoutingViewType;
  data: T[];
  pagination: IPagination | null;
  backLink?: ILinkState;
  onSearchModalOpen: () => void;
  renderCard: (player: T) => JSX.Element;
}

function SearchResultsGrid<T extends FIPlayer | FIPlayerWithPerformance>(
  props: ISearchResultsGridProps<T>,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  // const _scoutingContext = useScouting();
  const {t} = useTranslation();
  const _location = useLocation();

  /*
   * Handlers
   */
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [_location.search]);

  /*
   * Render
   */
  // if (!_scoutingContext?.players) {
  //   // TODO: Loading/Empty state
  //   return null;
  // }
  if (!props.data.length) {
    return (
      <CustomEmptyState
        header={t(`scouting.empty.${props.viewType}.header`)}
        description={t(`scouting.empty.${props.viewType}.description`)}
        primaryAction={
          <Button
            variant="contained"
            color="primary"
            onClick={props.onSearchModalOpen}>
            {t(`scouting.empty.${props.viewType}.cta`)}
          </Button>
        }
      />
    );
  }

  return (
    <Stack gap={5}>
      <Grid container spacing={2}>
        {props.data.map((player) => {
          return (
            <Grid item key={player._id} xs={12} sm={6} md={4}>
              {props.renderCard(player)}
            </Grid>
          );
        })}
      </Grid>
      {props.pagination && props.pagination.total_pages > 1 && (
        <Pagination
          page={props.pagination.current_page}
          count={props.pagination.total_pages}
          sx={{display: 'flex', justifyContent: 'center', width: '100%'}}
          renderItem={(item) => {
            const _searchParams = new URLSearchParams(_location.search);
            let _to = `${_location.pathname}?${_searchParams.toString()}`;
            if (item.page) {
              _searchParams.set('page', item.page.toString());
            }

            _to = `${_location.pathname}?${_searchParams.toString()}`;
            return <PaginationItem component={Link} to={_to} {...item} />;
          }}
        />
      )}
    </Stack>
  );
}
export default SearchResultsGrid;
