import React from 'react';

import {TDevComponent} from '@/types/dev.types';
import {CircularProgress} from '@mui/material';
import {DevSection} from './dev-section';

export const DevSectionSpinnersView = () => {
  const spinners: TDevComponent[] = [
    {
      title: 'Spinner',
      variants: [
        {
          title: 'Regular',
          component: <CircularProgress />,
        },
      ],
    },
  ];

  return (
    <DevSection
      title="Spinners"
      componentList={spinners}
      showItemTitle={false}
    />
  );
};
