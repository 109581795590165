import {FIPlayer} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import {Link, useLocation} from 'react-router-dom';
import {ROUTE, SCOUTING_ROUTES} from '@/config/routes.config';
import {ILinkState} from '@/types/routes.types';
import {useTranslation} from 'react-i18next';
import Logo from '@/components/common/Logo/Logo';

interface ISearchAsyncAutocompleteOptionProps {
  player: FIPlayer;
}

function SearchAsyncAutocompleteOption(
  props: ISearchAsyncAutocompleteOptionProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _location = useLocation();
  const [_linkState, _setLinkState] = useState<ILinkState | null>(null);

  /*
   * Side effects
   */
  // Set back link state
  useEffect(() => {
    _setLinkState({
      label: t('scouting.title'),
      route: _location.pathname,
    });
  }, [props.player, _location.pathname]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  let _Badge = null;
  if (props.player.country_code) {
    _Badge = (
      <Box
        position="absolute"
        right={0}
        bottom={0}
        width={12}
        overflow="hidden"
        boxShadow={1}>
        <img
          style={{maxWidth: '100%', display: 'block'}}
          src={`${
            process.env.REACT_APP_ASSETS_URL
          }/flags/${props.player.country_code.toLowerCase()}.png`}
        />
      </Box>
    );
  }
  return (
    <ListItem
      divider
      dense
      disablePadding
      secondaryAction={<ArrowForwardIcon color="primary" />}>
      <ListItemButton
        // dense
        // divider

        component={Link}
        state={_linkState}
        to={`/${ROUTE.scouting}/${SCOUTING_ROUTES.player}/${props.player._id}`}>
        <ListItemAvatar sx={{minWidth: 0, mr: 2}}>
          <Box position="relative" alignSelf="flex-start">
            <Avatar
              src={props.player.image_url}
              sx={{
                bgcolor: 'secondary.dark',
                color: 'secondary.main',
              }}
            />
            {_Badge}
          </Box>
        </ListItemAvatar>
        <ListItemText
          primary={props.player.name}
          secondaryTypographyProps={{
            component: 'span',
          }}
          secondary={
            props.player.team ? (
              <Stack direction="row" alignItems="center" gap={1}>
                <Logo src={props.player.team.image_url} size="xsmall" />
                <Typography variant="body2" color="text.secondary">
                  {props.player.team.name}
                </Typography>
              </Stack>
            ) : (
              <Typography
                variant="body2"
                color="text.disabled"
                fontStyle="italic">
                {t('players.info.noClub')}
              </Typography>
            )
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

export default SearchAsyncAutocompleteOption;
