import {
  FIEventAutomationSubject,
  FIPlayer,
  IBenchmarkOptions,
} from '@my-game-plan/types';

import {
  PALETTE_ERROR,
  PALETTE_SUCCESS,
  PALETTE_TEXT,
  PALETTE_WARNING,
} from '@/theme';

/*
 * Get the position filter for the benchmark based on observing players' position
 */
export function getPositionFilterByObservingPlayers(
  ownPlayers: FIPlayer[],
  observingPlayers?: FIEventAutomationSubject,
): Partial<IBenchmarkOptions> {
  const _filters: Partial<IBenchmarkOptions> = {
    position: undefined,
  };

  if (observingPlayers?.players?.length) {
    const _player = ownPlayers.find(
      (player) => player._id === observingPlayers?.players?.[0],
    );
    if (_player) {
      _filters.position = _player.position;
    }
  }

  return _filters;
}

/*
 * Get indexes to show in ranking
 */
export function getRankingIndicesToDisplay(
  currentIndex: number,
  totalEntries: number,
) {
  const displayCount = 5;
  const halfDisplayCount = Math.floor(displayCount / 2);

  // Calculate the start and end indices for the display
  let startIndex = Math.max(0, currentIndex - halfDisplayCount);
  let endIndex = Math.min(totalEntries - 1, startIndex + displayCount - 1);

  // Adjust the start index if needed
  startIndex = Math.max(0, endIndex - displayCount + 1);

  // Adjust the end index if needed
  endIndex = Math.min(totalEntries - 1, startIndex + displayCount - 1);

  // Create an array of display indices
  const displayIndices = [];
  for (let i = startIndex; i <= endIndex; i++) {
    displayIndices.push(i);
  }

  return displayIndices;
}

/*
 * Get color based on score 0-10
 */
export function getColorForScore(score?: number): string {
  if (typeof score === 'undefined') return PALETTE_TEXT.primary as string;

  let _scoreColor = PALETTE_SUCCESS.main;
  if (score <= 3.33) {
    _scoreColor = PALETTE_ERROR.main;
  } else if (score <= 6.67) {
    _scoreColor = PALETTE_WARNING.main;
  }

  return _scoreColor;
}
