import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import axios, {AxiosError} from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';

import {ForbiddenState} from './components/error-states/ForbiddenState';
import {NotFoundState} from './components/error-states/NotFoundState';
import {DragAndDropProvider} from './context/drag-and-drop.context';
import './index.css';
import './localization';
import MyGamePlan from './MyGamePlan';
import reportWebVitals from './reportWebVitals';
import {TeamsProvider} from '@/context/team.context';
import {VideoContextProvider} from '@/context/video/video.context';
import {VideoPlaylistProvider} from '@/context/video/video-playlist.context';
import {VideoEditProvider} from '@/context/video/video-edit.context';
import {TrackerProvider} from '@/context/event-automations/tracker.context';
import {OverviewDashboardsProvider} from '@/context/dashboard/overview-dashboards.context';

import {MatchesProvider} from '@/context/matches.context';
import {AuthProvider} from '@/context/auth.context';
import {AppContextProvider} from '@/context/app.context';
import {SnackbarProvider} from 'notistack';
import {ConfirmProvider} from 'material-ui-confirm';
import {ThemeProvider} from '@mui/material';
import {darkTheme} from './theme';
import {closeSnackbar} from 'notistack';
import './localization';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import SingleSendsProvider from './context/single-sends.context';
import OpponentAutomationsProvider from './context/event-automations/opponent-automations.context';
import {ZonesProvider} from './context/zones.context';
import {ShotAnalysisProvider} from './context/shot-analysis.context';
import CompetitionsProvider from './context/competitions.context';
import ScoutingProvider from './context/scouting.context';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {defaultLocale} from './localization/localization.config';
import moment from 'moment';
import AnalyticsProvider from './context/analytics.context';
import {REQUEST_ERRORS} from './config/errors.config';
import {Resource} from './types/api.types';
import {LOCAL_STORAGE_USER_ID} from './config/api.config';

import {FIUserFormatted, PLATFORM} from '@my-game-plan/types';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.3,
});

moment.locale(defaultLocale);
const SERVER_URL = process.env.REACT_APP_MY_GAME_PLAN_SERVER_URL;

axios.interceptors.response.use(
  (response) => response.data,
  async (error: AxiosError) => {
    if (error.response) {
      const status = error.response.status;

      const _localStorageUserId = localStorage.getItem(LOCAL_STORAGE_USER_ID);
      if (
        _localStorageUserId &&
        status === 401 &&
        error.response?.data?.message === REQUEST_ERRORS.NO_SESSION
      ) {
        const _initalRequest = error.config;

        const _user = await axios.get<FIUserFormatted>(
          `${SERVER_URL}${Resource.users}/${_localStorageUserId}`,
          {
            params: {
              initial: true,
              source: PLATFORM.WEB,
            },
            withCredentials: true,
          },
        );
        if (_user) {
          return axios(_initalRequest);
        }
        return;
      }

      if (
        (status === 401 || status === 403) &&
        !error.request.responseURL.includes('/auth')
      ) {
        ReactDOM.render(
          <ThemeProvider theme={darkTheme}>
            <AuthProvider>
              <ForbiddenState />
            </AuthProvider>
          </ThemeProvider>,
          document.getElementById('root'),
        );
      } else {
        throw new Error(error.response?.data?.message || '');
      }

      return;
    }

    ReactDOM.render(
      <ThemeProvider theme={darkTheme}>
        <NotFoundState />
      </ThemeProvider>,
      document.getElementById('root'),
    );
  },
);

ReactDOM.render(
  <ThemeProvider theme={darkTheme}>
    <ConfirmProvider
      defaultOptions={{
        cancellationButtonProps: {
          color: 'secondary',
        },
        confirmationButtonProps: {
          variant: 'contained',
        },
      }}>
      <SnackbarProvider
        action={(snackbarId) => (
          <IconButton onClick={() => closeSnackbar(snackbarId)}>
            <Close />
          </IconButton>
        )}>
        <BrowserRouter>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={defaultLocale}>
            <AuthProvider>
              <AnalyticsProvider>
                <TeamsProvider>
                  <CompetitionsProvider>
                    <MatchesProvider>
                      <ZonesProvider>
                        <ShotAnalysisProvider>
                          <VideoContextProvider>
                            <VideoPlaylistProvider>
                              <VideoEditProvider>
                                <TrackerProvider>
                                  <SingleSendsProvider>
                                    <OpponentAutomationsProvider>
                                      <ScoutingProvider>
                                        <AppContextProvider>
                                          <DragAndDropProvider>
                                            <OverviewDashboardsProvider>
                                              <MyGamePlan />
                                            </OverviewDashboardsProvider>
                                          </DragAndDropProvider>
                                        </AppContextProvider>
                                      </ScoutingProvider>
                                    </OpponentAutomationsProvider>
                                  </SingleSendsProvider>
                                </TrackerProvider>
                              </VideoEditProvider>
                            </VideoPlaylistProvider>
                          </VideoContextProvider>
                        </ShotAnalysisProvider>
                      </ZonesProvider>
                    </MatchesProvider>
                  </CompetitionsProvider>
                </TeamsProvider>
              </AnalyticsProvider>
            </AuthProvider>
          </LocalizationProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </ConfirmProvider>
  </ThemeProvider>,

  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
