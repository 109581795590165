import i18n from '@/localization';
import {
  IEventAutomationExampleInfo,
  ITrackerCalculationTypeInfo,
} from '@/types/event-automations-misc.types';
import {translateAction} from '@/helpers/translation.helper';
import {capitalize} from 'lodash';

export const TRACKER_TYPE_INFO_EXAMPLES: ITrackerCalculationTypeInfo[] = [
  {
    calculation: 'occurrences',
    description: i18n.t(
      'eventAutomations.calculationType.description.occurrences',
    ),
    examples: [
      {
        _id: '1',
        action: 'cross',
        calculation: 'occurrences',
        observing_players: {players: ['0']},
        details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'result',
              values: ['successful'],
            },
          ],
        },
      },
      {
        _id: '2',
        action: 'pressure_action',
        // target: 5,
        calculation: 'occurrences',
        details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'action_result',
              values: ['shot_ending'],
            },
          ],
        },
      },
      {
        _id: '3',
        action: 'shot',
        calculation: 'occurrences',
        details: {
          operator: 'and',
          filters: [
            {
              operator: 'or',
              property: 'secondary_event_types',
              values: ['long'],
            },
            {
              operator: 'or',
              property: 'result',
              values: ['goal'],
            },
          ],
        },
      },
      {
        _id: '4',
        action: 'pass',
        calculation: 'occurrences',
        observing_players: {players: ['0']},
        details: {
          operator: 'and',
          filters: [
            {
              operator: 'or',
              property: 'pass.length_group',
              values: ['short'],
            },
            {
              operator: 'or',
              property: 'action_result',
              values: ['goal_ending'],
            },
          ],
        },
      },
    ],
  },
  {
    calculation: 'ratio',
    description: i18n.t('eventAutomations.calculationType.description.ratio'),
    examples: [
      {
        _id: '1',
        action: 'cross',
        observing_players: {players: ['0']},
        calculation: 'ratio',
        success_details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'result',
              values: ['successful'],
            },
          ],
        },
      },
      {
        _id: '2',
        action: 'pressure_action',
        // target: 0.8,
        calculation: 'ratio',
        success_details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'action_result',
              values: ['shot_ending'],
            },
          ],
        },
      },
      {
        _id: '3',
        action: 'shot',
        calculation: 'ratio',
        details: {
          operator: 'and',
          filters: [
            {
              operator: 'or',
              property: 'secondary_event_types',
              values: ['long'],
            },
          ],
        },
        success_details: {
          operator: 'and',
          filters: [
            {
              operator: 'or',
              property: 'result',
              values: ['goal'],
            },
          ],
        },
      },
      {
        _id: '4',
        action: 'pass',
        calculation: 'ratio',
        observing_players: {players: ['0']},
        details: {
          operator: 'and',
          filters: [
            {
              operator: 'or',
              property: 'pass.length_group',
              values: ['short'],
            },
          ],
        },
        success_details: {
          operator: 'and',
          filters: [
            {
              operator: 'or',
              property: 'action_result',
              values: ['goal_ending'],
            },
          ],
        },
      },
    ],
  },
  {
    calculation: 'rule',
    description: i18n.t('eventAutomations.calculationType.description.rule'),
    examples: [
      {
        _id: '5',
        action: 'possession_gain',
        calculation: 'rule',
        rule_condition: {
          action: 'possession_loss',
          type: 'rule',
          seconds: 5,
        },
      },
    ],
  },
];

export const TRACKER_EVENT_EXAMPLES: IEventAutomationExampleInfo[] = [
  {
    section: i18n.t('dashboard.categories.attacking.subcategories.build-up'),
    examples: [
      {
        _id: '1',
        action: 'goal_kick',
        calculation: 'ratio',
        observing_players: {players: ['1']},
        target: 70,
        success_details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'result',
              values: ['successful'],
            },
          ],
        },
        details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'pass.length_group',
              values: ['long'],
            },
          ],
        },
      },
      {
        _id: '2',
        action: 'pass',
        calculation: 'ratio',
        observing_players: {players: ['2']},
        target: 60,
        success_details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'result',
              values: ['successful'],
            },
          ],
        },
      },
      {
        _id: '3',
        action: 'pass',
        calculation: 'ratio',
        observing_players: {players: ['3']},
        target: 60,
        success_details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'pass.direction_group',
              values: ['forward'],
            },
          ],
        },
      },
      {
        _id: '5',
        action: 'corner_kick',
        calculation: 'occurrences',
        details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'action_result',
              values: ['goal_ending', 'shot_ending'],
            },
          ],
        },
      },
    ],
  },
  {
    section: i18n.t(
      'dashboard.categories.attacking.subcategories.chance-creation',
    ),
    examples: [
      {
        _id: '4',
        action: 'cross',
        calculation: 'occurrences',
        observing_players: {players: ['4']},
        details: {
          operator: 'and',
          filters: [
            {
              operator: 'or',
              property: 'result',
              values: ['successful'],
            },
          ],
        },
      },
      {
        _id: '10',
        action: 'final_third_pass',
        calculation: 'ratio',
        success_details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'action_result',
              values: ['goal_ending', 'shot_ending'],
            },
          ],
        },
      },
      {
        _id: '11',
        action: 'shot',
        calculation: 'ratio',
        observing_players: {players: ['4']},
        success_details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'result',
              values: ['saved', 'goal'],
            },
          ],
        },
      },
      {
        _id: '6',
        action: 'cross',
        calculation: 'occurrences',
        details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'result',
              values: ['successful'],
            },
          ],
        },
      },
    ],
  },
  {
    section: i18n.t('dashboard.categories.defending.label'),
    examples: [
      {
        _id: '7',
        action: 'interception',
        calculation: 'occurrences',
        observing_players: {players: ['4']},
        details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'secondary_event_types',
              values: ['recovery'],
            },
          ],
        },
      },
      {
        _id: '8',
        action: 'aerial_duel',
        calculation: 'ratio',
        observing_players: {players: ['2']},
        success_details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'result',
              values: ['successful'],
            },
          ],
        },
      },
      {
        _id: '9',
        action: 'duel',
        calculation: 'ratio',
        success_details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'result',
              values: ['successful'],
            },
          ],
        },
      },
      {
        _id: '12',
        action: 'recovery',
        calculation: 'occurrences',
        observing_players: {players: ['2']},
      },
    ],
  },
  {
    section: i18n.t('dashboard.categories.attacking.subcategories.runs'),
    examples: [
      {
        _id: '15',
        action: 'deep_run',
        calculation: 'occurrences',
        observing_players: {players: ['6']},
      },
      // {
      //   _id: '16',
      //   action: 'on_the_ball_acceleration',
      //   calculation: 'ratio',
      //   observing_players: {players: ['5']},
      //   success_details: {
      //     operator: 'or',
      //     filters: [
      //       {
      //         operator: 'or',
      //         property: 'action_result',
      //         values: ['goal_ending', 'shot_ending'],
      //       },
      //     ],
      //   },
      // },
      {
        _id: '17',
        action: 'run',
        calculation: 'ratio',
        observing_players: {players: ['3']},
        success_details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'secondary_event_types',
              values: ['deep_run'],
            },
          ],
        },
      },
      {
        _id: '18',
        action: 'run',
        calculation: 'occurrences',
        observing_players: {players: ['5']},
        details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'secondary_event_types',
              values: ['diagonal_run'],
            },
          ],
        },
      },
    ],
  },
];

export const TRACKER_TRACKING_EXAMPLES: IEventAutomationExampleInfo = {
  section: capitalize(translateAction('zone_visit')),
  examples: [
    {
      _id: '19',
      action: 'zone_visit',
      calculation: 'occurrences',
      observing_players: {players: ['7']},
      pitch_zone: '64b900a1005a96b81e099c92',
    },
    {
      _id: '20',
      action: 'zone_visit',
      calculation: 'occurrences',
      observing_players: {players: ['8']},
      pitch_zone: '64b900a1005a96b81e099c93',
    },
  ],
};
