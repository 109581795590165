import React, {ReactNode, useEffect, useState} from 'react';
import {
  FIEventAutomation,
  FIFormattedAutomationPlayerScore,
  TEventAutomationType,
  shouldInvertScore,
} from '@my-game-plan/types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';
import {formattedAutomationToPostData} from '@/helpers/event-automations.helper';
import InlineFiltersSentence from '@/components/automations/sentence/builder-sentence.view';
import {PLAYLIST_ROUTES, ROUTE, SCOUTING_ROUTES} from '@/config/routes.config';
import {Link} from 'react-router-dom';
import {ILinkState} from '@/types/routes.types';
import {formatSingleScore} from '@/helpers/automation/automation-detail-history.helper';
import Stat from '@/components/common/stat/stat.view';

interface IOverviewSimpleCardProps {
  automation: FIEventAutomation;
  automationType: TEventAutomationType;
  linkState?: ILinkState;
  teamId?: string;
}

function OverviewSimpleCard(props: IOverviewSimpleCardProps): JSX.Element {
  /*
   * Hooks n State
   */
  const [_title, _setTitle] = useState<string | ReactNode | null>(null);
  const [_formattedAverage, _setFormattedAverage] =
    useState<FIFormattedAutomationPlayerScore | null>(null);
  const {t} = useTranslation();
  const [_to, _setTo] = useState<string>('');

  useEffect(() => {
    if ('title' in props.automation) {
      _setTitle(props.automation.title);
    } else {
      const _formattedData = formattedAutomationToPostData(props.automation);

      const _TitleNode = (
        <InlineFiltersSentence
          automationType={props.automationType}
          size="small"
          data={_formattedData}
          readonly
          hideSubject
          withTarget={Boolean(typeof props.automation.target !== 'undefined')}
        />
      );
      _setTitle(_TitleNode);
    }

    const _player = props.automation.players?.[0];

    if (_player) {
      const _newFormattedAvarage = formatSingleScore(
        'average',
        t('eventAutomations.properties.average'),
        _player.scores.average,
        _player.scores.average_difference,
        props.automation.calculation === 'ratio' ||
          props.automation.calculation === 'rule',
        undefined,
        shouldInvertScore(props.automation),
      );

      _setFormattedAverage(_newFormattedAvarage);
    }
  }, [props.automation]);

  // Set link to automation detail
  useEffect(() => {
    let _newToPrefix = '/';
    switch (props.automationType) {
      case 'opponent-automation':
        _newToPrefix += PLAYLIST_ROUTES.opponent_automations;
        break;
      case 'shadow-tracker':
        _newToPrefix += `${ROUTE.scouting}/${SCOUTING_ROUTES.shadow_trackers}`;
        break;
      default:
        _newToPrefix += ROUTE.trackers;
        break;
    }

    let _newTo = `${_newToPrefix}/${props.automation._id}`;
    if (props.teamId) {
      _newTo += `?team._id=${props.teamId}`;
    }
    _setTo(_newTo);
  }, [props.automationType, props.automation, props.teamId]);

  /*
   * Render
   */
  const _linkLabel = t('eventAutomations.viewCta', {
    subject: t(
      `eventAutomations.${
        props.automationType === 'opponent-automation'
          ? 'opponentAutomations'
          : 'trackers'
      }Short`,
      {
        count: 1,
      },
    ),
  });

  let _Title = null;
  if (typeof _title === 'string') {
    _Title = (
      <Typography color="text.primary" fontSize={16} fontWeight={600}>
        {_title}
      </Typography>
    );
  } else if (_title) {
    _Title = _title;
  }

  return (
    <Card>
      <CardContent sx={{minHeight: 64}}>{_Title}</CardContent>
      <CardActions
        sx={{justifyContent: 'space-between', alignItems: 'flex-end'}}>
        <Box>
          {_formattedAverage && (
            <Stat
              label={_formattedAverage.label}
              value={
                _formattedAverage.formattedValue ||
                t('eventAutomations.values.notAvailable')
              }
              valueSuffix={_formattedAverage.valueSuffix}
              valueSuffixColor={_formattedAverage.diffColor}
            />
          )}
        </Box>
        <Button size="small" component={Link} to={_to} state={props.linkState}>
          {_linkLabel}
        </Button>
      </CardActions>
    </Card>
  );
}

export default OverviewSimpleCard;
