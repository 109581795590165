import {FITeam} from '@my-game-plan/types';
import React from 'react';

import Stack from '@mui/material/Stack';
import Logo from '@/components/common/Logo/Logo';
import Typography from '@mui/material/Typography';
import {SxProps} from '@mui/material';
import {Theme} from '@emotion/react';
import {useTranslation} from 'react-i18next';

interface INextOpponentProps {
  nextOpponent: FITeam | null;
}

const LABEL_STYLE: SxProps<Theme> = {
  whiteSpace: 'pre',
  textTransform: 'none',
  fontWeight: '300',
  lineHeight: 1,
  fontSize: 14,
  color: 'text.secondary',
};
const LABEL_VALUE_STYLE: SxProps<Theme> = {
  fontWeight: '600',
};

function NextOpponent(props: INextOpponentProps): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Handlers
   */

  /*
   * Render
   */
  if (!props.nextOpponent) {
    return (
      <Typography sx={{...LABEL_STYLE, fontStyle: 'italic'}}>
        {t('eventAutomations.noUpcomingOpponent')}
      </Typography>
    );
  }
  return (
    <Stack direction="row" gap={0.5} alignItems="center">
      <Typography sx={LABEL_STYLE}>{`${t(
        'eventAutomations.nextOpponent',
      )}:`}</Typography>
      <Typography sx={{...LABEL_STYLE, ...LABEL_VALUE_STYLE} as SxProps<Theme>}>
        {props.nextOpponent.name}
      </Typography>
      <Logo src={props.nextOpponent.image_url} size="small" />
    </Stack>
  );
}

export default NextOpponent;
