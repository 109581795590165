import React from 'react';

import styles from './Loader.module.scss';

import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';

interface LoaderProps {
  isVisible: boolean;
  size?: number;
}

function Loader(props: LoaderProps): JSX.Element {
  const _size = props.size || 24;
  return (
    <Fade in={props.isVisible} unmountOnExit appear={false}>
      <div className={styles.container}>
        <CircularProgress size={_size} />
      </div>
    </Fade>
  );
}

export default Loader;
