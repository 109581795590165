import React from 'react';

import {DevSectionButtonsView} from '@/components/dev/dev-section-buttons.view';
import {DevSectionTabsView} from '@/components/dev/dev-section-tabs.view';
import {DevSectionSnackbarView} from '@/components/dev/dev-section-snackbar.view';
import {DevSectionInputDropdownView} from '@/components/dev/dev-section-input-dropdown.view';
import {DevSectionModalView} from '@/components/dev/dev-section-modal.view';

import {DevSectionAvatarsView} from '@/components/dev/dev-section-avatars.view';
import {DevSectionPopoversView} from '@/components/dev/dev-section-popovers.view';
import {DevSectionSpinnersView} from '@/components/dev/dev-section-spinner.view';
import {DevSectionChipsView} from '@/components/dev/dev-section-chips.view';
import {DevSectionEmptyStateView} from '@/components/dev/dev-section-empty-state.view';
import {DevSectionTablesView} from '@/components/dev/dev-section-tables.view';

export const ComponentsRoutes = () => {
  return (
    <div style={{padding: 32}}>
      <h1>Components</h1>
      <DevSectionButtonsView />
      <DevSectionTabsView />
      <DevSectionSnackbarView />
      <DevSectionModalView />
      <DevSectionPopoversView />
      <DevSectionInputDropdownView />

      <DevSectionAvatarsView />
      <DevSectionSpinnersView />
      <DevSectionChipsView />
      <DevSectionEmptyStateView />
      <DevSectionTablesView />
    </div>
  );
};
