import React, {useEffect} from 'react';
import {CustomDashboardView} from './custom_dashboard.view';
import {NormalDashboardView} from './normal_dashboard.view';
import {useParams} from 'react-router-dom';
import {useOverviewDashboards} from '@/context/dashboard/overview-dashboards.context';
import {FiltersProvider} from '@/context/filters.context';
import {DashboardProvider} from '@/context/dashboard/dashboard.context';
import {DashboardScoresProvider} from '@/context/dashboard/dashboard-scores.context';

export const DashboardView = () => {
  const _params = useParams();
  const {isCustomDashboard, setSelectedDashboard, selectedDashboard} =
    useOverviewDashboards();

  // TODO should be in a better location. Something like a param Manager
  useEffect(() => {
    if (_params.dashboard) setSelectedDashboard(_params.dashboard);
  }, [_params.dashboard]);

  let _DashboardView = null;
  if (selectedDashboard && isCustomDashboard(selectedDashboard)) {
    _DashboardView = <CustomDashboardView />;
  } else if (selectedDashboard) {
    _DashboardView = <NormalDashboardView />;
  }

  return (
    <DashboardProvider>
      <FiltersProvider>
        <DashboardScoresProvider>{_DashboardView}</DashboardScoresProvider>
      </FiltersProvider>
    </DashboardProvider>
  );
};
