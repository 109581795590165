import React from 'react';
import IMAGE from '../../assets/images/logo/mygameplan-logo.png';
import ICON from '../../assets/images/logo/logomark.png';
import {useMediaQuery, useTheme} from '@mui/material';

const _alt = 'MyGamePlan';

export const ProductHomeContainer = () => {
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      <img
        style={{height: '30px', marginRight: '10px'}}
        src={isScreenSmall ? ICON : IMAGE}
        alt={_alt}
      />
    </>
  );
};
