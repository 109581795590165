import {useAPI} from '@/hooks/api.hooks';
import {HTTPResponse, Resource} from '@/types/api.types';
import {PLAYERS_API_CONFIG} from '@/config/api.config';
import {
  FIBenchmarkedPhysicalData,
  FIPerformanceOverview,
  FIPlayer,
  FIPlayerSearchFilters,
  FIPlayerWithData,
  FIPlayerWithPerformance,
  FIShadowPlayerPostData,
  IBackgroundJobStatus,
} from '@my-game-plan/types';
import {REQUEST_ERRORS} from '@/config/errors.config';

const api = useAPI();

export const getPlayersByTeam = async (
  _team: string | undefined,
): Promise<FIPlayer[]> => {
  try {
    const {data} = await api.get<FIPlayer[]>({
      resource: Resource.players,
      url: PLAYERS_API_CONFIG.getPlayersByTeam(_team),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch {
    return new Promise<FIPlayer[]>((res) => res([]));
  }
};

export const getPlayerById = async (
  teamId: string,
  playerId: string,
): Promise<FIPlayerWithData> => {
  try {
    const {data} = await api.get<FIPlayerWithData>({
      resource: Resource.players,
      url: PLAYERS_API_CONFIG.getPlayer(teamId, playerId),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
};

export const getPlayerPerformanceById = async (
  teamId: string,
  playerId: string,
): Promise<FIPerformanceOverview> => {
  try {
    const {data} = await api.get<FIPerformanceOverview>({
      resource: Resource.players,
      url: PLAYERS_API_CONFIG.getPlayerPerformance(teamId, playerId),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
};

export const getPlayerPhysicalDataById = async (
  teamId: string,
  playerId: string,
): Promise<FIBenchmarkedPhysicalData | null> => {
  try {
    const {data} = await api.get<FIBenchmarkedPhysicalData | null>({
      resource: Resource.players,
      url: PLAYERS_API_CONFIG.getPlayerPhysical(teamId, playerId),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
};

/*
 * Search
 */
export async function searchPlayers(
  teamID: string,
  filters: FIPlayerSearchFilters,
): Promise<HTTPResponse<FIPlayer[]>> {
  try {
    const _response = await api.get<FIPlayer[]>({
      resource: Resource.players,
      url: PLAYERS_API_CONFIG.search(teamID),
      params: filters,
    });
    // if (!data) {
    //   throw new Error(REQUEST_ERRORS.NO_DATA);
    // }
    return _response;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

/*
 * Search - Get recent searches
 */
export async function getScoutedPlayers(
  teamID: string,
  filters?: FIPlayerSearchFilters,
): Promise<HTTPResponse<FIPlayerWithPerformance[]>> {
  try {
    const _response = await api.get<FIPlayerWithPerformance[]>({
      resource: Resource.players,
      url: PLAYERS_API_CONFIG.scoutedPlayers(teamID),
      params: filters,
    });

    return _response;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

/*
 * Scouting: scout player
 */
export async function startScoutingPlayer(
  postData: Partial<FIShadowPlayerPostData>,
): Promise<FIPlayerWithData> {
  try {
    const {data} = await api.post<FIPlayerWithData>({
      resource: Resource.players,
      url: PLAYERS_API_CONFIG.startScouting(),
      data: postData,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}
/*
 * Scouting: get performance info
 */
export async function getScoutedPlayerPerfornance(
  postData: FIShadowPlayerPostData,
): Promise<IBackgroundJobStatus> {
  try {
    const {data} = await api.post<IBackgroundJobStatus>({
      resource: Resource.players,
      url: PLAYERS_API_CONFIG.scoutPerformance(),
      data: postData,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function stopScoutingPlayer(
  teamId: string,
  playerId: string,
): Promise<boolean> {
  try {
    const {data} = await api.del<boolean>({
      resource: Resource.players,
      url: PLAYERS_API_CONFIG.stopScouting(teamId, playerId),
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

/*
 * Countries
 */
export async function getPlayerCountries(): Promise<string[]> {
  try {
    const {data} = await api.get<string[]>({
      resource: Resource.players,
      url: PLAYERS_API_CONFIG.getCountries(),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}
