import React, {useState} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';

import TuneIcon from '@mui/icons-material/Tune';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import {FIEventAutomation, TEventAutomationType} from '@my-game-plan/types';
import {useTranslation} from 'react-i18next';
import {ITypedOption} from '@/types/option.types';
import {
  IAutomationFilters,
  TFilterOptions,
} from '@/types/event-automations-misc.types';

import AutomationsDrawerFilter from './automations-drawer-filter.view';
import Stack from '@mui/material/Stack';
import ListSubheader from '@mui/material/ListSubheader';
import {TOverviewScreenViewType} from '@/types/screen.types';

export interface IFilterOptions<T extends keyof IAutomationFilters> {
  [key: string]: ITypedOption<IAutomationFilters[T]>[];
}

interface IAutomationsDrawerProps {
  automationType: TEventAutomationType;
  automations: FIEventAutomation[];
  filters: IAutomationFilters;
  availableFilterOptions: TFilterOptions;
  onFiltersChange: (filters: IAutomationFilters) => void;

  activeViewType?: TOverviewScreenViewType;
  views?: TOverviewScreenViewType[];
  onViewChange?: (viewType: TOverviewScreenViewType) => void;
}

const DRAWER_WIDTH = 280;

function AutomationsDrawer(props: IAutomationsDrawerProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  const [_isDrawerOpen, _setIsDrawerOpen] = useState<boolean>(false);
  const [_activeFilterAccordion, _setActiveFilterAccordion] = useState<
    keyof IAutomationFilters | null
  >(null);

  /*
   * Handlers
   */
  function _onDrawerOpen() {
    _setIsDrawerOpen(true);
  }

  function _ondrawerClose() {
    _setIsDrawerOpen(false);
  }

  function _onAccordionToggle(filter: keyof IAutomationFilters | null) {
    _setActiveFilterAccordion(filter);
  }

  // function _onValueChange(key: keyof IAutomationFilters, values: string[]) {
  //   //
  //   _setFilterOptions({
  //     ..._filterOptions,
  //   });
  // }
  function _onViewChange(
    event: React.MouseEvent<HTMLElement>,
    viewType: TOverviewScreenViewType,
  ) {
    if (props.onViewChange) {
      props.onViewChange(viewType);
    }
  }

  /*
   * Render
   */
  const _buttonLabel = t(
    `eventAutomations.filter.${
      props.automationType === 'opponent-automation'
        ? 'automations'
        : 'trackers'
    }Filter`,
  );
  return (
    <>
      <Button
        color="secondary"
        variant="text"
        onClick={_onDrawerOpen}
        startIcon={<TuneIcon />}>
        {_buttonLabel}
      </Button>
      <Drawer
        anchor="right"
        open={_isDrawerOpen}
        onClose={_ondrawerClose}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        PaperProps={{sx: {width: DRAWER_WIDTH}}}>
        <Stack pt={4} gap={4}>
          {props.views && props.activeViewType && props.views.length > 1 && (
            <>
              <Stack>
                <ListSubheader>
                  {t('eventAutomations.filter.view')}
                </ListSubheader>
                <Box px={2}>
                  <ToggleButtonGroup
                    fullWidth
                    exclusive
                    onChange={_onViewChange}
                    value={props.activeViewType}>
                    {props.views.map((viewType) => {
                      return (
                        <ToggleButton key={viewType} value={viewType}>
                          {t(`eventAutomations.filter.viewType.${viewType}`)}
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </Box>
              </Stack>
              <Divider orientation="horizontal" />
            </>
          )}

          <Stack>
            <ListSubheader>{t('eventAutomations.filter.filter')}</ListSubheader>
            {Object.keys(props.availableFilterOptions).map((key) => {
              const _key = key as keyof TFilterOptions;
              const _options =
                props.availableFilterOptions[key as keyof TFilterOptions];

              if (_options.length < 2) return null;

              return (
                <AutomationsDrawerFilter
                  key={key}
                  filterKey={_key}
                  options={_options}
                  selectedOptions={props.filters[_key] || []}
                  onAccordionToggle={_onAccordionToggle}
                  isOpened={_activeFilterAccordion === _key}
                  onFiltersChange={props.onFiltersChange}
                />
              );
            })}
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
}

export default AutomationsDrawer;
