import {IconButton} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import Button from '@mui/material/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {useVideo} from '@/context/video/video.context';
import {
  ACTION,
  FIConditionalEventFilters,
  FIEventFilters,
  FIMatchEvent,
} from '@my-game-plan/types';
import {fetchEventsForAutomation} from '@/controllers/event-automations.controller';

interface PlayTrackerVideosButtonProps {
  id: string;
  relative: boolean;
  amountOfVideos: number;
  type?: 'large' | 'small' | 'text';
  isDisabled?: boolean;
  action?: ACTION;
  details?: FIConditionalEventFilters;
  onClick?: (e: React.MouseEvent) => void;
  events?: FIMatchEvent[];
  metric?: keyof FIEventFilters;
}

export const PlayTrackerVideosButton = ({
  id,
  relative,
  amountOfVideos,
  type = 'large',
  isDisabled,
  action,
  details,
  onClick,
  events,
  metric,
}: PlayTrackerVideosButtonProps) => {
  const {t} = useTranslation();
  const _video = useVideo();

  const openVideoPlayer = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    try {
      let _events = events;
      if (!_events) {
        _events = await fetchEventsForAutomation('tracker', id);
      }

      _video.openVideoPlayer(_events, relative, undefined, {
        action: action,
        details: details,
        metric: metric,
      });
    } catch (error) {
      // TODO: handle video error
    }
  };

  const _shouldBeDisabled = amountOfVideos <= 0 || isDisabled;

  if (type === 'large' || type === 'text') {
    let _videosCount = '';
    if (type === 'large') {
      _videosCount = ` (${amountOfVideos})`;
    }
    return (
      <Button
        startIcon={type === 'text' ? undefined : <PlayArrowIcon />}
        size={type === 'text' ? 'small' : undefined}
        onClick={onClick ? onClick : openVideoPlayer}
        variant={type == 'text' ? 'text' : 'contained'}
        disabled={_shouldBeDisabled}>
        {`${t('video-player.button.play')}${_videosCount}`}
      </Button>
    );
  }

  if (type === 'small') {
    return (
      <IconButton
        title={t('video-player.button.play')}
        color="primary"
        size="large"
        onClick={onClick ? onClick : openVideoPlayer}>
        <PlayArrowIcon />
      </IconButton>
    );
  }

  return null;
};
