import {BACKGROUND_JOBS_API_CONFIG} from '@/config/api.config';
import {REQUEST_ERRORS} from '@/config/errors.config';
import {useAPI} from '@/hooks/api.hooks';
import {Resource} from '@/types/api.types';
import {IBackgroundJobStatus} from '@my-game-plan/types';
import {JobId} from 'bull';

const api = useAPI();
export async function getBackgroundJobStatus<T = any>(
  worker: string,
  jobId: JobId,
): Promise<IBackgroundJobStatus<T>> {
  try {
    const {data} = await api.get<IBackgroundJobStatus<T>>({
      resource: Resource.background_jobs,
      url: BACKGROUND_JOBS_API_CONFIG.getJobStatus(worker, jobId),
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}
