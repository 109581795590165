import {TDevComponent} from '@/types/dev.types';
import {Button, Popover, Typography} from '@mui/material';
import React from 'react';
import {DevSection} from './dev-section';

export const DevSectionPopoversView = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const popovers: TDevComponent[] = [
    {
      title: 'Popover',
      variants: [
        {
          title: 'Regular',
          component: (
            <div>
              <Button
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}>
                Open Popover
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}>
                <Typography sx={{p: 2}}>The content of the Popover.</Typography>
              </Popover>
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <DevSection
      title="Popover"
      componentList={popovers}
      showItemTitle={false}
    />
  );
};
