import React, {useEffect, useState} from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Textfield from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import LoadingButton from '@mui/lab/LoadingButton';
import {useTranslation} from 'react-i18next';
import {FITeam} from '@my-game-plan/types';
import {LOADING_STATE} from '@/types/screen.types';
import {debounce} from 'lodash';
import {useAuth} from '@/context/auth.context';
import {searchTeams} from '@/controllers/teams.controller';

import {useSnackbar} from 'notistack';
import {switchTeam} from '@/controllers/admin.controller';
import Logo from '@/components/common/Logo/Logo';
import {useNavigate} from 'react-router-dom';

interface ISwitchTeamDialogViewProps {
  open: boolean;
  available_teams: FITeam[];
  onClose: () => void;
}
function SwitchTeamDialog(props: ISwitchTeamDialogViewProps): JSX.Element {
  /*
   * Hooks n State
   */
  const _authContext = useAuth();
  const _snackbar = useSnackbar();
  const _navigate = useNavigate();
  const {t} = useTranslation();
  const [_options, _setOptions] = useState<FITeam[]>([]);
  const [_searchLoadingState, _setSearchLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );
  const [_submitLoadingState, _setSubmitLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );
  const [_selectedTeam, _setSelectedTeam] = useState<FITeam | null>(null);
  const [_inputValue, _setInputValue] = useState<string>('');

  const _debouncedSearchHandler = debounce(_searchHandler, 500);

  /*
   * Side effects
   */
  useEffect(() => {
    return () => {
      _debouncedSearchHandler.cancel();
    };
  }, []);

  useEffect(() => {
    _setOptions(props.available_teams);
  }, [props.available_teams]);

  /*
   * Handlers
   */
  function _onInputChange(event: React.SyntheticEvent, newValue: string) {
    _debouncedSearchHandler(newValue);
    // _searchHandler(newValue);
  }

  async function _searchHandler(query: string) {
    if (props.available_teams.length) {
      _setOptions(props.available_teams);
    } else {
      _setInputValue(query);
      if (!query) {
        _setOptions([]);
        return;
      }

      try {
        _setSearchLoadingState(LOADING_STATE.LOADING);

        const _fetchedTeams = await searchTeams(query);
        _setOptions(_fetchedTeams || []);
        _setSearchLoadingState(LOADING_STATE.SUCCESS);
      } catch (error) {
        _setSearchLoadingState(LOADING_STATE.ERROR);
      }
    }
  }

  function _onTeamSelect(event: React.SyntheticEvent, value: FITeam | null) {
    if (value && !_options.some((option) => option._id === value._id)) {
      _setOptions([value, ..._options]);
    }
    // _setOptions(value ? [value, ..._options] : _options);
    _setSelectedTeam(value);
  }

  async function _onSubmit() {
    if (!_authContext.user || !_selectedTeam) {
      return;
    }

    try {
      _setSubmitLoadingState(LOADING_STATE.LOADING);
      await switchTeam({
        user_id: _authContext.user._id,
        team_id: _selectedTeam._id,
      });

      _navigate(0);
    } catch (error) {
      _snackbar.enqueueSnackbar(t('error-states.default'));
    } finally {
      _setSubmitLoadingState(LOADING_STATE.SUCCESS);
      props.onClose();
    }
  }

  /*
   * Render
   */
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{t('preferences.switchTeam.title')}</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Typography color="text.secondary">
            {t('preferences.switchTeam.text')}
          </Typography>

          <Autocomplete
            value={_selectedTeam}
            clearOnBlur={false}
            options={_options}
            getOptionLabel={(option) => option.name}
            onChange={_onTeamSelect}
            onInputChange={_onInputChange}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            renderInput={(textFieldProps) => (
              <Textfield
                {...textFieldProps}
                // value={_inputValue}
                placeholder={t('preferences.switchTeam.search')}
              />
            )}
            noOptionsText={_inputValue ? t('preferences.switchTeam.empty') : ''}
            loading={_searchLoadingState === LOADING_STATE.LOADING}
            componentsProps={{
              popper: {
                sx: {boxShadow: 4},
              },
              paper: {
                sx: {bgcolor: 'background.default'},
              },
            }}
            renderOption={(optionProps, option) => {
              return (
                <Stack
                  component="li"
                  direction="row"
                  alignItems="center"
                  gap={2}
                  {...optionProps}>
                  <Logo src={option.image_url} size="xsmall" />
                  {`${option.name}`}
                </Stack>
              );
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          disabled={!_selectedTeam}
          onClick={_onSubmit}
          variant="contained"
          loading={_submitLoadingState === LOADING_STATE.LOADING}>
          {t('preferences.switchTeam.submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default SwitchTeamDialog;
