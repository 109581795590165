import {PropsWithChildren, useEffect, useRef} from 'react';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {
  FIPlayerWithData,
  FIPerformanceOverview,
  FIBenchmarkedPhysicalData,
  FITeam,
  FIShadowPlayerPostData,
  BACKGROUND_WORKER,
  FIScoutedPlayerData,
  IBackgroundJobStatus,
  FIPlayerObjective,
} from '@my-game-plan/types';
import {useState} from 'react';

import {
  getPlayerById,
  getPlayerPerformanceById,
  getPlayerPhysicalDataById,
  getScoutedPlayerPerfornance,
  // startScoutingPlayer,
  stopScoutingPlayer,
} from '@/controllers/players.controller';

import {LOADING_STATE} from '@/types/screen.types';

import {useCompetitions} from './competitions.context';
import {useTeams} from './team.context';

import {
  getTeamPerformanceById,
  getTeamPhysicalDataById,
} from '@/controllers/teams.controller';
import {JobId} from 'bull';
import {getBackgroundJobStatus} from '@/controllers/background-jobs.controller';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {ROUTE} from '@/config/routes.config';
import {getPlayerObjectives} from '@/controllers/player-objectives.controller';
import {useAnalytics} from './analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

export interface PlayerDetailAPI {
  player: FIPlayerWithData | null;
  playerLoadingState: LOADING_STATE;
  performanceStats: FIPerformanceOverview | null;
  performanceLoadingState: LOADING_STATE;
  isSettingMirrorInfo: boolean;
  mirrorringLoadingState: LOADING_STATE;
  mirrorringProgress: IBackgroundJobStatus | null;
  stopScouting: (playerId?: string) => Promise<void>;

  // Defines whether the (player's) current team has a physical data provider in its last competition
  hasPhysicalData: boolean;
  physicalData: FIBenchmarkedPhysicalData | null;
  physicalLoadingState: LOADING_STATE;

  objectives: FIPlayerObjective[];
  objectivesLoadingState: LOADING_STATE;
  fetchObjectives: () => Promise<void>;

  isTeamPage: boolean;
  team: FITeam | null;

  mirrorPlayer: (mirroringPlayerId?: string) => Promise<void>;
}

interface IProviderProps {
  playerId?: string;
}

const context = createCustomContext<PlayerDetailAPI>();
export const usePlayerDetail = createContextHook(context);

export function PlayerDetailProvider(props: PropsWithChildren<IProviderProps>) {
  /*
   * Init
   */
  const _analyticsContext = useAnalytics();

  const [_player, _setPlayer] = useState<FIPlayerWithData | null>(null);
  const [_playerLoadingState, _setPlayerLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.LOADING,
  );
  const [_team, _setTeam] = useState<FITeam | null>(null);
  const [_isTeamPage, _setIsTeamPage] = useState<boolean>(false);

  const [_performanceStats, _setPerformanceStats] =
    useState<FIPerformanceOverview | null>(null);
  const [_performanceLoadingState, _setPerformanceLoadingState] =
    useState<LOADING_STATE>(LOADING_STATE.INITING);
  const [_isSettingMirrorInfo, _setIsSettingMirrorInfo] =
    useState<boolean>(false);
  const [_mirroringLoadingState, _setMirroringLoadingState] =
    useState<LOADING_STATE>(LOADING_STATE.INITING);
  const [_mirroringProgress, _setMirroringProgress] =
    useState<IBackgroundJobStatus | null>(null);

  const [_hasPhysicalData, _setHasPhysicalData] = useState<boolean>(false);
  const [_physicalLoadingState, _setPhysicalLoadingState] =
    useState<LOADING_STATE>(LOADING_STATE.INITING);
  const [_physicalData, _setPhysicalData] =
    useState<FIBenchmarkedPhysicalData | null>(null);

  const [_objectives, _setObjectives] = useState<FIPlayerObjective[]>([]);
  const [_objectivesLoadingState, _setObjectivesLoadingState] =
    useState<LOADING_STATE>(LOADING_STATE.INITING);

  //   const [_error, _setError] = useState<string>('');
  const {t} = useTranslation();
  const _teamContext = useTeams();
  const _navigate = useNavigate();
  const _competitionsContext = useCompetitions();
  const _backgroundJobsInterval = useRef<number | null>(null);
  const _snackbar = useSnackbar();

  /* Side effects */
  /* Fetch player info on page load */
  useEffect(() => {
    async function _fetch() {
      if (!_teamContext.ownTeam) {
        _setPlayerLoadingState(LOADING_STATE.ERROR);
        return;
      }

      _setIsTeamPage(!props.playerId);

      if (props.playerId) {
        try {
          /* Fetch player info */
          const _fetchedPlayer = await getPlayerById(
            _teamContext.ownTeam._id,
            props.playerId,
          );
          _setPlayer(_fetchedPlayer);
          _setPlayerLoadingState(LOADING_STATE.SUCCESS);
        } catch (error) {
          _setPlayerLoadingState(LOADING_STATE.ERROR);
        }
      } else {
        /* Set own team for now. FUTURE: Use params team id to fetch and set team */
        _setTeam(_teamContext.ownTeam);
        _setPlayerLoadingState(LOADING_STATE.SUCCESS);
      }
    }
    _fetch();
  }, [props.playerId, _teamContext.ownTeam]);

  /* PHYSICAL - PLAYER: Fetch player physical on page load - check domestic competition first */
  useEffect(() => {
    async function _fetchPhysicalData() {
      if (
        !_teamContext.ownTeam ||
        !_competitionsContext.domesticCompetition ||
        !_player
      ) {
        _setHasPhysicalData(false);
        return;
      }

      const _playerCompetition = _competitionsContext.getCompetitionForTeam(
        _player.team._id,
      );

      const _physicalProvider = _player.is_own_player
        ? _competitionsContext.domesticCompetition.physical_provider
        : _playerCompetition?.physical_provider;

      const _physicalDataCheck = !!_physicalProvider;
      _setHasPhysicalData(_physicalDataCheck);
      if (!_physicalDataCheck) {
        return;
      }

      try {
        _setPhysicalLoadingState(LOADING_STATE.LOADING);
        let _fetchedPhysicalData: FIBenchmarkedPhysicalData | null = null;
        // if (props.playerId) {
        _fetchedPhysicalData = await getPlayerPhysicalDataById(
          _player.team._id,
          _player._id,
        );

        if (_fetchedPhysicalData) {
          _setPhysicalData(_fetchedPhysicalData);
          _setPhysicalLoadingState(LOADING_STATE.SUCCESS);
        } else {
          _setPhysicalLoadingState(LOADING_STATE.ERROR);
        }
      } catch (error) {
        _setPhysicalLoadingState(LOADING_STATE.ERROR);
      }
    }

    _fetchPhysicalData();
    _fetchObjectives();
  }, [_player, _teamContext.ownTeam, _competitionsContext.domesticCompetition]);

  /* PHYSICAL - TEAM: Fetch team physical on page load - check domestic competition first */
  useEffect(() => {
    async function _fetchPhysicalData() {
      if (
        !_teamContext.ownTeam ||
        !_competitionsContext.domesticCompetition ||
        !_team
      ) {
        _setHasPhysicalData(false);
        return;
      }

      const _physicalDataCheck =
        !!_competitionsContext.domesticCompetition.physical_provider;
      _setHasPhysicalData(_physicalDataCheck);

      if (!_physicalDataCheck) {
        return;
      }

      try {
        _setPhysicalLoadingState(LOADING_STATE.LOADING);
        let _fetchedPhysicalData: FIBenchmarkedPhysicalData | null = null;

        _fetchedPhysicalData = await getTeamPhysicalDataById(
          _teamContext.ownTeam._id,
        );

        if (_fetchedPhysicalData) {
          _setPhysicalData(_fetchedPhysicalData);
          _setPhysicalLoadingState(LOADING_STATE.SUCCESS);
        } else {
          _setPhysicalLoadingState(LOADING_STATE.ERROR);
        }
      } catch (error) {
        _setPhysicalLoadingState(LOADING_STATE.ERROR);
      }
    }

    _fetchPhysicalData();
  }, [_team, _teamContext.ownTeam, _competitionsContext.domesticCompetition]);

  //PERFORMANCE - PLAYER: Fetch performance info when player is loaded
  useEffect(() => {
    async function _fetchPerformance() {
      if (!_player || !_teamContext.ownTeam) {
        return;
      }

      const _jobStatus = _player.scouting?.background_job_status;
      try {
        _setPerformanceLoadingState(LOADING_STATE.LOADING);
        let _fetchedPerformanceStats: FIPerformanceOverview | null = null;

        if (_player.is_own_player || _jobStatus === 'completed') {
          // Performance data is available
          _fetchedPerformanceStats = await getPlayerPerformanceById(
            _teamContext.ownTeam._id,
            _player._id,
          );
        } else if (
          !_player.is_own_player &&
          !_player.scouting?.is_mirroring_performance
        ) {
          // No mirrored performance data. Show mirror builder

          _setIsSettingMirrorInfo(true);
          if (_player.scouting?.background_job_id) {
            _startPollingJob(_player.scouting.background_job_id);
          }
        } else {
          // Performance data is not available. Resolve in UI
          _setPerformanceLoadingState(LOADING_STATE.SUCCESS);
        }

        /* Fetch player performance stats */
        _setPerformanceStats(_fetchedPerformanceStats);
        _setPerformanceLoadingState(LOADING_STATE.SUCCESS);
      } catch (error) {
        _setPerformanceLoadingState(LOADING_STATE.ERROR);
      }
    }

    _fetchPerformance();
  }, [_player, _teamContext.ownTeam]);

  // PERFORMANCE - TEAM: Fetch performance info when observing team
  useEffect(() => {
    async function _fetchPerformance() {
      if (!_team || !_teamContext.ownTeam) {
        return;
      }
      try {
        let _fetchedPerformanceStats: FIPerformanceOverview | null = null;

        _setPerformanceLoadingState(LOADING_STATE.LOADING);
        _fetchedPerformanceStats = await getTeamPerformanceById(_team._id);

        /* Fetch player performance stats */
        _setPerformanceStats(_fetchedPerformanceStats);
        _setPerformanceLoadingState(LOADING_STATE.SUCCESS);
      } catch (error) {
        _setPerformanceLoadingState(LOADING_STATE.ERROR);
      }
    }

    _fetchPerformance();
  }, [_team, _teamContext.ownTeam]);

  // Clear interval on unmount
  useEffect(() => {
    return () => _clearBackgroundJobsInterval();
  }, []);

  /*
   * Handlers
   */
  async function _mirrorPlayer(mirroringPlayerId?: string) {
    if (!_teamContext.ownTeam || !_player) {
      return;
    }
    if (mirroringPlayerId) {
      _setMirroringLoadingState(LOADING_STATE.LOADING);
    }
    _setIsSettingMirrorInfo(true);

    if (!mirroringPlayerId) {
      return;
    }

    try {
      _setIsSettingMirrorInfo(true);
      const _postData: FIShadowPlayerPostData = {
        team_id: _teamContext.ownTeam._id,
        observing_player_id: _player._id,
        mirroring_player_id: mirroringPlayerId,
        benchmarkOptions: {
          position: _player.position,
        },
      };

      _analyticsContext.trackEvent(
        ANALYTICS_EVENT.PLAYER_PAGE_MIRRORED_PERFORMANCE,
        {
          team_id: _teamContext.ownTeam._id,
          player_id: _player._id,
          mirroring_player_id: mirroringPlayerId,
        },
      );

      const _jobStatus = await getScoutedPlayerPerfornance(_postData);
      _setMirroringProgress(_jobStatus);
      _startPollingJob(_jobStatus.id);
      _setPerformanceStats(null);
    } catch (error) {
      _setMirroringLoadingState(LOADING_STATE.INITING);
      // _setMirroringLoadingState(LOADING_STATE.ERROR);
      _snackbar.enqueueSnackbar(
        t('players.performance.mirrorring.builder.error.header'),
        {variant: 'error'},
      );
    }
  }
  async function _startPollingJob(jobId: JobId) {
    _setMirroringLoadingState(LOADING_STATE.LOADING);
    _backgroundJobsInterval.current = window.setInterval(() => {
      _getJobProgress(jobId);
    }, 250);
  }
  async function _getJobProgress(jobId: JobId) {
    const _jobStatus = await getBackgroundJobStatus<FIShadowPlayerPostData>(
      BACKGROUND_WORKER.SHADOW_PLAYERS,
      jobId,
    );

    if (_jobStatus.status === 'completed') {
      // Fetch performance data
      _setMirroringProgress({
        ..._jobStatus,
        progress: 100,
      });
      _clearBackgroundJobsInterval();

      if (!_teamContext.ownTeam || !_player) {
        return;
      }

      const _mirrorringPlayerId =
        _jobStatus.data?.mirroring_player_id ||
        _player.scouting?.mirroring_player_id;
      const _scouting: FIScoutedPlayerData = {
        ...(_player.scouting || {}),
        mirroring_player_id: _mirrorringPlayerId,
        is_mirroring_performance: true,
        background_job_status: _jobStatus.status,
        background_job_id: jobId,
        last_synced_at: new Date(),
        scouting_since: _player.scouting?.scouting_since || new Date(),
      };

      _setPlayer({
        ..._player,
        scouting: _scouting,
      });

      _setIsSettingMirrorInfo(false);
      _setMirroringLoadingState(LOADING_STATE.INITING);
    } else if (_jobStatus.status === 'failed') {
      _setMirroringProgress(_jobStatus);
      _snackbar.enqueueSnackbar(
        t('players.performance.mirrorring.builder.error.header'),
        {variant: 'error'},
      );
      _setIsSettingMirrorInfo(true);
      _setMirroringLoadingState(LOADING_STATE.INITING);
      // Handle error
      _clearBackgroundJobsInterval();
    } else if (_jobStatus.progress) {
      _setMirroringProgress(_jobStatus);
    }
  }

  function _clearBackgroundJobsInterval() {
    if (_backgroundJobsInterval.current) {
      window.clearInterval(_backgroundJobsInterval.current);
    }
  }

  async function _stopScouting(playerId?: string) {
    if (!_player || !_teamContext.ownTeam) {
      return;
    }

    await stopScoutingPlayer(_teamContext.ownTeam._id, playerId || _player._id);
    _navigate(`/${ROUTE.scouting}`);
  }

  async function _fetchObjectives() {
    if (!_teamContext.ownTeam || !_player) {
      return;
    }

    if (!_player.is_own_player) {
      _setObjectivesLoadingState(LOADING_STATE.SUCCESS);
      return;
    }

    try {
      _setObjectivesLoadingState(LOADING_STATE.LOADING);
      const _fetchedObjectives = await getPlayerObjectives(
        _teamContext.ownTeam._id,
        _player._id,
      );

      _setObjectives(_fetchedObjectives);
      _setObjectivesLoadingState(LOADING_STATE.SUCCESS);
    } catch (error) {
      _setObjectivesLoadingState(LOADING_STATE.ERROR);
    }
  }

  /*
   * Return context
   */
  const _apiValue: PlayerDetailAPI = {
    player: _player,
    playerLoadingState: _playerLoadingState,
    performanceStats: _performanceStats,
    performanceLoadingState: _performanceLoadingState,
    isSettingMirrorInfo: _isSettingMirrorInfo,
    mirrorPlayer: _mirrorPlayer,
    mirrorringLoadingState: _mirroringLoadingState,
    mirrorringProgress: _mirroringProgress,
    stopScouting: _stopScouting,

    hasPhysicalData: _hasPhysicalData,
    physicalData: _physicalData,
    physicalLoadingState: _physicalLoadingState,

    objectives: _objectives,
    objectivesLoadingState: _objectivesLoadingState,
    fetchObjectives: _fetchObjectives,

    isTeamPage: _isTeamPage,
    team: _team,
  };

  return createProvider<PlayerDetailAPI>(context, props, _apiValue);
}
