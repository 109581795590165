import {HTTPResponse, RequestOptions} from '@/types/api.types';
import axios from 'axios';
import QueryString from 'qs';

const SERVER_URL = process.env.REACT_APP_MY_GAME_PLAN_SERVER_URL;

export async function makeRequest<T>(
  options: RequestOptions,
): Promise<HTTPResponse<T>> {
  const _response: HTTPResponse<T> = await axios({
    url: `${SERVER_URL}${options.resource}/${options.url}`,
    method: options.method,
    data: options.data,
    params: options.params,
    withCredentials: true,
    paramsSerializer: options.params
      ? (params) => {
          return QueryString.stringify(params, {arrayFormat: 'repeat'});
        }
      : undefined,
    ...options.options,
  });

  return _response;
}
