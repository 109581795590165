import PlayerView from '@/views/player.view';
import React from 'react';
import {Route, Routes} from 'react-router-dom';

function PlayersRoutes() {
  return (
    <Routes>
      <Route path=":id" element={<PlayerView />} />
    </Routes>
  );
}

export default PlayersRoutes;
