import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ICommonSentenceInputProps} from '@/types/sentence-form.types';

import Segment from '..';
import {ITypedOption} from '@/types/option.types';
import Autocomplete from '@/components/material-customised/autocomplete/autocomplete.view';
import {generateZoneOptions} from '@/helpers/pitch-zones.helper';
import {useTeams} from '@/context/team.context';
import {useZones} from '@/context/zones.context';
import {capitalize} from 'lodash';

function ZonesDropdown(props: ICommonSentenceInputProps<string>): JSX.Element {
  /*
   * Hooks n State
   */
  const [_options, _setOptions] = useState<ITypedOption<string>[]>([]);
  const [_selectedOption, _setSelectedOption] =
    useState<ITypedOption<string> | null>(null);
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const _zones = useZones();

  useEffect(() => {
    const _isTracker = props.automationType === 'tracker';

    let _imageURL = '';
    if (props.automationType === 'opponent-automation') {
      const _nextOpponent = _teamsContext.all.find(
        (team) => team._id === _teamsContext.nextOpponentId,
      );

      if (_nextOpponent) {
        _imageURL = _nextOpponent.image_url;
      }
    }

    const _formattedZones = generateZoneOptions(
      _zones.all,
      props.data.action,
      _isTracker,
      _imageURL,
      props.data.observing_players?.team === 'opponent',
    );

    _setOptions(_formattedZones);
  }, [props.automationType, props.data, _teamsContext.all, _zones.all]);

  useEffect(() => {
    const _matchingOption = _options.find((option) => {
      return option.value === props.value;
    });

    _setSelectedOption(_matchingOption || null);
  }, [props.value, _options]);

  /*
   * Handlers
   */
  function _onChange(
    value: ITypedOption<string> | ITypedOption<string>[] | null,
  ) {
    if (props.onChange) {
      if (value && Array.isArray(value)) {
        //
      } else if (value) {
        props.onChange({
          [props.property]: value.value,
        });
      }
    }
  }
  /*
   * Render
   */
  function _groupHeaderKey(text: string): string {
    return capitalize(t(`zones.groups.${text}`));
  }

  const _translatedPlaceholder = `${t('sentenceForm.tracking.zone', {
    count: 1,
  })}*`;
  if (props.readonly) {
    const _readonlyText = _selectedOption?.label || _translatedPlaceholder;

    return (
      <Segment.Text
        size={props.size}
        data={props.data}
        text={_readonlyText}
        type="value"
      />
    );
  }
  return (
    <Autocomplete
      // open
      error={props.error}
      isInlineInput
      blurOnSelect
      property={props.property}
      options={_options}
      onChange={_onChange}
      placeholder={_translatedPlaceholder}
      required={props.required}
      value={_selectedOption}
      groupHeader={_groupHeaderKey}
    />
  );
}

export default ZonesDropdown;
