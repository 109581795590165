export const PREFERENCES_TRANSLATIONS = {
  title: 'Team preferences',
  run_thresholds: {
    title: 'Run thresholds',
    speed: 'Min Average speed',
    distance: 'Min Distance',
    duration: 'Min Duration',
    deep_run: 'Deep run',
    lateral_run: 'Lateral run',
    diagonal_run: 'Diagonal run',
    btn_save: 'Save',
    btn_thresholds: 'thresholds',
    higher_than: 'should be higher than',
    on_average: 'On average, teams set this to',
    snackbar_success: 'preferences have been updated',
    snackbar_fail: 'Something went wrong, please try again later',
  },
  pitch_zones: 'Pitch zones',
  tags: {
    create: {
      helperText: 'Press Enter to create a new category',
    },
    title: 'Automation categories',
    performance_trackers: 'performance trackers',
    opponent_automations: 'opponent automations',
    delete: {
      title: 'Delete categories',
      text: 'Deleting this category will also remove it from automations.',
    },
    deleteSuccess: 'Category deleted successfully',
    edit: 'Rename category',
    editSuccess: 'Category edited successfully',
    cancel: 'Cancel',
    save: 'Save',
    noTags:
      'You do not have any categories yet. Create or edit a tracker/opponent automation to add categories',
  },
  switchTeam: {
    title: 'Change team',
    text: 'Search for a team to link to your account',
    search: 'Start typing a team name',
    submit: 'Change team',
    empty: 'No teams found',
  },
};
